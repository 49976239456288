import { Component, OnInit , OnDestroy } from '@angular/core';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import { ProductIngredients1Service } from '../product-ingredients1.service';

@Component({
  selector: 'app-product-ingredients1-formpage',
  templateUrl: './product-ingredients1-formpage.component.html',
  styleUrls: ['./product-ingredients1-formpage.component.scss']
})
export class ProductIngredients1FormpageComponent implements OnInit, OnDestroy {
  config: ICMSConfig

  constructor(private _ProductIngredients1Service: ProductIngredients1Service) {
    this.config = this._ProductIngredients1Service.config;
   }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {}

}
