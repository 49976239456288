<div class="auth-wrapper auth-v2">
  <div class="auth-inner row m-0">
    <!-- Left Text-->
    <div class="d-none d-lg-flex col-lg-8 align-items-center p-5">
      <div
        class="w-100 d-lg-flex align-items-center justify-content-center px-5"
      >
        <img
          class="img-fluid"
          style="width: 75%; max-width: 400px;"
          [src]="coreConfig.app.appLogoImage"
          alt="Login V2"
        />
      </div>
    </div>
    <!-- /Left Text-->
    <!-- Forgot password-->
    <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
      <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
        <h2 class="card-title font-weight-bold mb-1">Forgot Password? 🔒</h2>
        <p *ngIf="!isMailSended" class="card-text mb-2">
          Enter your email and we'll send you instructions to reset your
          password
        </p>
        <form
          *ngIf="!isMailSended"
          class="auth-forgot-password-form mt-2"
          [formGroup]="forgotPasswordForm"
          (ngSubmit)="onSubmit()"
        >
          <div class="form-group">
            <label class="form-label" for="forgot-password-email">Email</label>
            <input
              class="form-control"
              type="text"
              formControlName="email"
              placeholder="john@example.com"
              aria-describedby="forgot-password-email"
              autofocus=""
              tabindex="1"
              [ngClass]="{ 'is-invalid': f.email.errors && f.email.touched }"
            />
            <div *ngIf="f.email.errors && f.email.touched" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
              <div *ngIf="f.email.errors.email">
                Email must be a valid email address
              </div>
            </div>
          </div>
          <button
            [disabled]="isLoading"
            class="btn btn-primary btn-block"
            tabindex="2"
            rippleEffect
          >
            <span
              *ngIf="isLoading"
              class="spinner-border spinner-border-sm mr-1"
            ></span
            >Send Mail
          </button>
        </form>

        <p *ngIf="isMailSended" class="card-text mb-2">
          Reset Password Email was sended to: {{ f.email.value }}
        </p>

        <div *ngIf="isMailSended" class="text-center">
          <a
            [disabled]="isLoading"
            class="btn btn-primary btn-block"
            tabindex="2"
            rippleEffect
            (click)="onSubmit()"
          >
            <span
              *ngIf="isLoading"
              class="spinner-border spinner-border-sm mr-1"
            ></span
            >Resend Mail
          </a>
        </div>
        <p class="text-center mt-2">
          <a routerLink="/pages/login"
            ><i data-feather="chevron-left" class="mb-25"></i> Back to login</a
          >
        </p>
      </div>
    </div>
    <!-- /Forgot password-->
  </div>
</div>
