import { Component, OnInit } from '@angular/core';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import Home from 'app/main/model/Home';
import formConfig from '../formsConfig/productItemFormConfig';

@Component({
  selector: 'app-product-item2',
  templateUrl: './product-item2.component.html',
  styleUrls: ['./product-item2.component.scss']
})
export class ProductItem2Component implements OnInit {

  config: ICMSConfig = {
    apiPath: 'productitemtwo',
    pathUrl: '/cms/product/lotion/detail',
    tableName: 'Content',
    model: new Home(),
    isList: false,
    componentName: 'General.Product',
    formConfig: formConfig,
  }

  constructor() { }

  ngOnInit(): void {
  }

}
