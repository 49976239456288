import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import Home from 'app/main/model/Home';

@Injectable({
  providedIn: 'root'
})
export class BannerSwiperService {

  config: ICMSConfig = {
    apiPath: 'home',
    pathUrl: '/cms/homepage/banner-swiper',
    tableName: 'Content',
    model: new Home(),
    isList: true,
    componentName: 'General.BannerSwiper',
    formConfig: {
      bannerImg: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Banner Image',
        description: 'Resolution 1920px*1080px',
        labelTranslate: 'BreadCrumbs.CMS.HomePage.BannerImage.Label',
        descriptionTranslate: 'BreadCrumbs.CMS.HomePage.BannerImage.Description'
      },
      isActive: {
        type: FormType.IsActive,
        label: 'Active',
        labelTranslate: 'Status.Active'
      }
    },
  };
  
  componentName: string = this.config.componentName;
  apiPath: string = this.config.apiPath;
  pathUrl: string = this.config.pathUrl;
  keyName: string = 'title';

  constructor() { }
}
