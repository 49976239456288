import { Component, OnInit } from '@angular/core';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import Home from 'app/main/model/Home';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {

  config:ICMSConfig = {
    apiPath: 'home',
    pathUrl: '/cms/homepage/detail',
    tableName: 'Content',
    model: new Home(),
    isList: false,
    componentName: 'BreadCrumbs.CMS.home',
    formConfig: {
      bannerImg:{
        type: FormType.MultipleImage,
        validator: [Validators.required],
        label: 'Banner Image',
        description: 'Resolution 1920px*1080px',
        labelTranslate: 'BreadCrumbs.CMS.HomePage.BannerImage.Label',
        descriptionTranslate: 'BreadCrumbs.CMS.HomePage.BannerImage.Description',
        limitDisabled: true,
      },
      homeLogo: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Home Logo',
        description: 'Resolution 395px*255px',
        labelTranslate: 'BreadCrumbs.CMS.HomePage.HomeLogo.Label',
        descriptionTranslate: 'BreadCrumbs.CMS.HomePage.HomeLogo.Description'
      },
      objective: {
        type: FormType.Textarea,
        validator: [Validators.required],
        label: 'Message Under Logo',
        labelTranslate: 'BreadCrumbs.CMS.HomePage.MessageLogo.Label'
      },
      dividerSection1: {
        type: FormType.Divider
      },

      extractFromUsTitle: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'Extraction Title',
        labelTranslate: 'BreadCrumbs.CMS.HomePage.TitleExtraction.Label'
      },
      extractFromUsDescription: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'Secondary Extraction Title',
        labelTranslate: 'BreadCrumbs.CMS.HomePage.SubTitleExtraction.Label'
      },
      imageExtraction1: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Extraction Image 1 (Desktop Size)',
        description: 'Resolution 1625px*1165px',
        labelTranslate: 'BreadCrumbs.CMS.HomePage.ImageExtractionDesktop.Label',
        descriptionTranslate: 'BreadCrumbs.CMS.HomePage.ImageExtractionDesktop.Description'
      },
      imageExtraction2: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Extraction Image 2 (Tablet Size)',
        description: 'Resolution 730px*520px',
        labelTranslate: 'BreadCrumbs.CMS.HomePage.ImageExtractionTablet.Label',
        descriptionTranslate: 'BreadCrumbs.CMS.HomePage.ImageExtractionTablet.Description'
      },
      imageExtraction3: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Extraction Image 3 (Mobile Size)',
        description: 'Resolution 725px*750px',
        labelTranslate: 'BreadCrumbs.CMS.HomePage.ImageExtractionMobile.Label',
        descriptionTranslate: 'BreadCrumbs.CMS.HomePage.ImageExtractionMobile.Description'
      },
      dividerSection2: {
        type: FormType.Divider
      },

      ourProductsTitle: {
        type: FormType.RichText,
        validator: [Validators.required],
        label: 'Product Card Title',
        labelTranslate: 'BreadCrumbs.CMS.HomePage.ProductCardTitle.Label'
      },
      productCardTitleImage1: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Product Card Title Image 1 (Desktop & Tablet)',
        description: 'Resolution 650px*900px',
        labelTranslate: 'BreadCrumbs.CMS.HomePage.ProductCardTitleImageDesktop.Label',
        descriptionTranslate: 'BreadCrumbs.CMS.HomePage.ProductCardTitleImageDesktop.Description'
      },
      productCardTitleImage2: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Product Card Title Image 1 (Mobile)',
        description: 'Resolution 255px*390px',
        labelTranslate: 'BreadCrumbs.CMS.HomePage.ProductCardTitleImageMobile.Label',
        descriptionTranslate: 'BreadCrumbs.CMS.HomePage.ProductCardTitleImageMobile.Description'
      },
      productCardImage1: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Product Card Image 1 Icon',
        description: 'Resolution 185px*185px',
        labelTranslate: 'BreadCrumbs.CMS.HomePage.ProductCardImageOne.Label',
        descriptionTranslate: 'BreadCrumbs.CMS.HomePage.ProductCardImageOne.Description'
      },
      iconTitle1: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'Product Card Message 1',
        labelTranslate: 'BreadCrumbs.CMS.HomePage.ProductCardMessageOne.Label'
      },
      productCardImage2: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Product Card Image 2 Icon',
        description: 'Resolution 185px*185px',
        labelTranslate: 'BreadCrumbs.CMS.HomePage.ProductCardImageTwo.Label',
        descriptionTranslate: 'BreadCrumbs.CMS.HomePage.ProductCardImageTwo.Description'
      },
      iconTitle2: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'Product Card Message 2',
        labelTranslate: 'BreadCrumbs.CMS.HomePage.ProductCardMessageTwo.Label'
      },
      productCardImage3: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Product Card Image 3 Icon',
        description: 'Resolution 185px*185px',
        labelTranslate: 'BreadCrumbs.CMS.HomePage.ProductCardImageThree.Label',
        descriptionTranslate: 'BreadCrumbs.CMS.HomePage.ProductCardImageThree.Description'
      },
      iconTitle3: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'Product Card Message 3',
        labelTranslate: 'BreadCrumbs.CMS.HomePage.ProductCardMessageThree.Label'
      },
      productCardImage4: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Product Card Image 4 Icon',
        description: 'Resolution 185px*185px',
        labelTranslate: 'BreadCrumbs.CMS.HomePage.ProductCardImageFour.Label',
        descriptionTranslate: 'BreadCrumbs.CMS.HomePage.ProductCardImageFour.Description'
      },
      iconTitle4: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'Product Card Message 4',
        labelTranslate: 'BreadCrumbs.CMS.HomePage.ProductCardMessageFour.Label'
      },
      dividerSection3: {
        type: FormType.Divider
      },
      
      promotion1: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Promotion Image 1',
        description: 'Resolution 358px*358px',
        labelTranslate: 'BreadCrumbs.CMS.HomePage.PromotionOne.Label',
        descriptionTranslate: 'BreadCrumbs.CMS.HomePage.PromotionOne.Description'
      },
      promotion2: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Promotion Image 2',
        description: 'Resolution 358px*358px',
        labelTranslate: 'BreadCrumbs.CMS.HomePage.PromotionTwo.Label',
        descriptionTranslate: 'BreadCrumbs.CMS.HomePage.PromotionTwo.Description'
      },
      promotion3: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Promotion Image 3',
        description: 'Resolution 358px*358px',
        labelTranslate: 'BreadCrumbs.CMS.HomePage.PromotionThree.Label',
        descriptionTranslate: 'BreadCrumbs.CMS.HomePage.PromotionThree.Description'
      },
      promotion4: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Promotion Image 4',
        description: 'Resolution 358px*358px',
        labelTranslate: 'BreadCrumbs.CMS.HomePage.PromotionFour.Label',
        descriptionTranslate: 'BreadCrumbs.CMS.HomePage.PromotionFour.Description'
      },
    },
  }

  constructor() { }

  ngOnInit(): void {
  }

}
