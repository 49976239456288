import { Component, OnInit } from '@angular/core';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import { BannerSwiperService } from './banner-swiper.service';


@Component({
  selector: 'app-banner-swiper',
  templateUrl: './banner-swiper.component.html',
  styleUrls: ['./banner-swiper.component.scss']
})
export class BannerSwiperComponent implements OnInit {

  config!: ICMSConfig;
  keyName: string;

  constructor(private _bannerSwiperService: BannerSwiperService) {
    this.config = this._bannerSwiperService.config;
    this.keyName = this._bannerSwiperService.keyName
  }

  ngOnInit(): void {
  }

}
