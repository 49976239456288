import { FormType } from 'app/main/config/CMSInterface';
import { Validators } from '@angular/forms';

export default {
    ingredientsImage: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Ingredients Image',
        description: 'Resolution 265px*272px',
        labelTranslate: 'CmsSection.Product.IngredientsImage.Label',
        descriptionTranslate: 'CmsSection.Product.IngredientsImage.Description'
      },
      title: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'Extracts Name',
        labelTranslate: 'CmsSection.Product.ExtractsName.Label'
      },
      detail: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'Extracts Name Detail',
        labelTranslate: 'CmsSection.Product.ExtractsNameDetail.Label'
      },
      isActive: {
        type: FormType.IsActive,
        label: 'Active',
        labelTranslate: 'Status.Active'
      }
}