import { Component, OnInit } from '@angular/core';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import { Compound2Service } from './compound2.service';

@Component({
  selector: 'app-compound2',
  templateUrl: './compound2.component.html',
  styleUrls: ['./compound2.component.scss']
})
export class Compound2Component implements OnInit {

  config!: ICMSConfig;
  keyName: string;

  constructor(private _Compound2Service : Compound2Service ) {
    this.config = this._Compound2Service.config;
    this.keyName = this._Compound2Service.keyName
   }

  ngOnInit(): void {
  }

}
