<!-- <div class="" *ngFor="let file of files; let i = index">
  <div class="">
    <span>{{ i }}. </span>
    <span>{{ file.name }}</span>
    <span>{{ file.type }}</span>
  </div>
</div>

<hr /> -->

<div class="custo-btn" [ngClass]="{ 'handle-box ng-invalid': handleBox && !imageLoading }">
  <div class="custo-input">
    <div class="custo-drop" (drop)="onDrop($event)" (dragover)="onDragOver($event)">
      <div class="custo-dropzone-container" [dragula]="uniqueName" [ngClass]="{ 'over-color': overDrag }"
        [(dragulaModel)]="files" (click)="openFileInput($event)">
        <div class="custo-title" *ngIf="files.length === 0 && !imageLoading">
          Drop files into here | click to select
        </div>

        <div class="col" *ngIf="imageLoading">
          <div class="d-flex justify-content-center">
            <div class="spinner-grow" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>

        <div class="custo-file-preview shadow" *ngFor="let file of files; let i = index"
          (ngModelChange)="onFilesOrderChange(files)">
          <button class="custo-remove-button" (click)="removeFile(i)">
            <i data-feather="x"></i>
          </button>
          <div class="custo-drop-body">
            <div class="custo-detail-file" *ngIf="!file.type.startsWith('image/')">
              <span>{{ file.type | fileTypeSplit }}</span>
              <span>{{ bytesToSizes(file.size) }}</span>
            </div>
            <!-- <span>{{ file.name }}</span> -->
            <div class="custo-file-preview-scope">
              <ng-container>
                <img [attr.src]="file.url | safeUrl" alt="Image Preview" />
              </ng-container>
            </div>
          </div>
          <div class="handle"></div>
        </div>
      </div>
    </div>

    <input type="file" #fileInputDropzone (change)="onFileChange($event)"
      [accept]="allowedFileTypes[typeFiles]?.join(', ')" multiple />
  </div>

  <div class="custo-file-upload">
    <button *ngIf="!(files.length >= countMedia) || countMedia == null" class="btn-sm btn-dark btn-icon"
      (click)="fileInputDropzone.click()">
      {{ "Alert.ChooseFiles" | translate }}
    </button>

    <div *ngIf="!(files.length >= countMedia)">
      <span *ngIf="minimumFiles != null " class="mr-2">
        {{ "Alert.minimum" | translate }} {{ minimumFiles }}
        {{ "Alert.files" | translate }}
      </span>
      <span>
        {{ "Alert.max" | translate }} ({{ countMedia - originalFiles.length }})
        {{ "Alert.files" | translate }}
      </span>
    </div>
    <span *ngIf="countMedia == null">
      ({{files.length}}) {{ "Alert.files" | translate }}
    </span>
    <span *ngIf="AlertFiles" class="text-danger">
      {{ "Alert.max" | translate }} {{ countMedia }}
      {{ "Alert.files" | translate }}
    </span>
    <span *ngIf="(files.length >= countMedia) && countMedia != null" class="text-success">
      {{ "Alert.maximize" | translate }}
    </span>
  </div>
</div>

<div *ngIf="handleBox && !imageLoading && isNotFile" class="text-danger"
  style="font-size: 0.857rem; margin-top: -1.25rem">
  {{ "Handle.MutipleFile" | translate }}
</div>
<div *ngIf="handleBox && isFileMinimum && !imageLoading && !isNotFile" class="text-danger"
  style="font-size: 0.857rem; margin-top: -1.25rem">
  {{"Handle.AtLeast" | translate}} {{ minimumFiles }} {{ "Alert.files" | translate }}
</div>