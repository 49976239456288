import { FormType } from 'app/main/config/CMSInterface';
import { Validators } from '@angular/forms';

export default {
    compoundImg: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Compound Image',
        description: 'Resolution 207px*214px',
        labelTranslate: 'CmsSection.Product.CompoundImg.Label',
        descriptionTranslate: 'CmsSection.Product.CompoundImg.Description'
      },
      isActive: {
        type: FormType.IsActive,
        label: 'Active',
        labelTranslate: 'Status.Active'
      }
}