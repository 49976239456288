import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers';
import { CmsFormpageModule } from '../cms-formpage/cms-formpage.module';
import { CmsListModule } from '../cms-list/cms-list.module';
import { HomepageComponent } from './homepage.component';
import { DetailComponent } from './detail/detail.component';
import { BannerSwiperComponent } from './banner-swiper/banner-swiper.component';
import { NewsSwiperComponent } from './news-swiper/news-swiper.component';
import { NewsSwiperFormpageComponent } from './news-swiper/news-swiper-formpage/news-swiper-formpage.component';
import { BannerSwiperFormpageComponent } from './banner-swiper/banner-swiper-formpage/banner-swiper-formpage.component';

const path: string = 'cms/homepage';

const routes: Routes = [
  {
    path: path,
    component: HomepageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-homepage' },
  },
  {
    path: `${path}/detail`,
    component: DetailComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-homepage-detail' },
  },
  {
    path: `${path}/news-swiper`,
    component: NewsSwiperComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-homepage-news' },
  },
  {
    path: `${path}/banner-swiper`,
    component: BannerSwiperComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-homepage-banner' },
  },

  //News Swiper ==================================
  {
    path: `${path}/news-swiper/create`,
    component: NewsSwiperFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'news-swiper-create' },
  },
  {
    path: `${path}/news-swiper/edit/:id`,
    component: NewsSwiperFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'news-swiper-edit' },
  },
  //Banner Swiper ==================================
  {
    path: `${path}/banner-swiper/create`,
    component: BannerSwiperFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'news-swiper-create' },
  },
  {
    path: `${path}/banner-swiper/edit/:id`,
    component: BannerSwiperFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'news-swiper-edit' },
  },

];

@NgModule({
  declarations: [
    HomepageComponent,
    DetailComponent,
    BannerSwiperComponent,
    NewsSwiperComponent,
    NewsSwiperFormpageComponent,
    BannerSwiperFormpageComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CmsFormpageModule,
    CmsListModule
  ]
})
export class HomepageModule { }
