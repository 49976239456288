import { Injectable } from '@angular/core';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import Home from 'app/main/model/Home';
import formConfig from '../formsConfig/compoundFormConfig'

@Injectable({
  providedIn: 'root'
})
export class Compound1Service {

  config: ICMSConfig = {
    apiPath: 'productitemone',
    pathUrl: '/cms/product/gentle-bath/compound',
    tableName: 'Content',
    model: new Home(),
    isList: true,
    componentName: 'General.compound',
    formConfig: formConfig,
  };
  
  componentName: string = this.config.componentName;
  apiPath: string = this.config.apiPath;
  pathUrl: string = this.config.pathUrl;
  keyName: string = 'title';

  constructor() { }
}
