import { User } from 'app/auth/models';

export const locale = {
  lang: 'th',
  data: {
    autoForm: {
      typeAnyKey: 'ป้อนตัวอักษร......',
    },
    Alert: {
      lang: 'ฟิลด์นี้ยังไม่ได้ระบุเป็นภาษา : ',
      Success: 'เสร็จสิ้น',
      Confirm: 'ตกลง',
      Error: 'ผิดพลาด',
      SomethingWentWrong: 'เกิดข้อผิดพลาดบางอย่าง',
      Invalid: 'กรุณากรอกข้อมูลที่จำเป็นให้ครบถ้วน',
      Approved: 'อนุมัติ',
      ApproveFail: 'อนุมัติล้มเหลว',
      PleaseCancelVoid: 'กรุณายกเลิกเอกสารที่เกี่ยวข้องก่อน',
      GetReceiptSuccess: 'รับใบเสร็จสำเร็จ',
      VoidGotReceiptSuccess: 'ยกเลิกรับใบเสร็จสำเร็จ',
      CancelDocumentSuccess: 'ยกเลิกเอกสารสำเร็จ',
      VoidDocumentSuccess: 'ยกเลิกเอกสารสำเร็จ',
      SignUpComplete: 'สมัครสมาชิกสำเร็จ',
      VerifyEmailSendSuccessfully: 'ส่งอีเมลยืนยันสำเร็จ',
      EmailNotExist: 'ไม่มีอีเมลนี้',
      PleaseTryAgain: 'โปรดลองอีกครั้ง',
      FormValidationFailed: 'ตรวจสอบข้อมูลแบบฟอร์มล้มเหลว',
      ImageUploadSuccessfully: 'อัปโหลดรูปภาพสำเร็จ',
      EmailResetPasswordHasSent: 'อีเมลสำหรับรีเซ็ตรหัสผ่านถูกส่งแล้ว',
      PasswordHasBeenReset: 'รหัสผ่านถูกรีเซ็ตแล้ว',
      minimum: 'ขั้นต่ำ',
      max: 'สูงสุด',
      files: 'ไฟล์',
      maximize: 'ครบจำนวนที่สามารถเพิ่มได้แล้ว',
      minimumimize: 'ต้องมีอย่างน้อย',
      ChooseFiles: 'เลือกไฟล์',
      Cancel: 'ยกเลิก',
      CodeQO: 'ใบเสนอราคาเลขที่ : {{docCode}}',
      GoToSO: 'ไปยังเอกสารใบสั่งขาย',
      CodeSO: 'ใบสั่งขายเลขที่ : {{docCode}}',
      GoToIV: 'ไปยังเอกสารใบแจ้งหนี้',
      CodeIV: 'ใบแจ้งหนี้เลขที่ : {{docCode}}',
      GoToRC: 'ไปยังเอกสารใบเสร็จรับเงิน',
      CodeRC: 'ใบเสร็จรับเงินเลขที่ : {{docCode}}',
      GoToTX: 'ไปยังเอกสารใบกำกับภาษี',
      CodeTX: 'ใบกำกับภาษีเลขที่ : {{docCode}}',
      CodePR: 'ใบขอสั่งซื้อเลขที่ : {{docCode}}',
      GoToPO: 'ไปยังเอกสารใบสั่งซื้อ',
      CodePO: 'ใบสั่งซื้อเลขที่ : {{docCode}}',
      GoToGR: 'ไปยังเอกสารใบรับสินค้า',
      CodeGR: 'ใบรับสินค้าเลขที่ : {{docCode}}',
    },
    Modal: {
      ConfirmTheCancellation: 'ยืนยันการยกเลิกเอกสาร',
      AreYouSureToCancellation:
        'คุณยืนยันที่จะยกเลิก{{title}} เลขที่ {{docCode}} ?',
      ConfirmTheCreateTaxInvoice: 'ยืนยันการสร้างใบกำกับภาษี',
      AreYouSureToCreateTaxInvoice:
        'คุณยืนยันที่จะสร้างใบกำกับภาษีจากใบแจ้งหนี้หรือไม่ ?',
      CancelCreateSeparateIV: 'ยกเลิกการสร้างเอกสารใบแจ้งหนี้แยก',
      AreYouSureToCancelCreateSeparateIV:
        'คุณยืนยันที่จะยกเลิกการสร้างใบแจ้งหนี้แยกหรือไม่ ?',
      CancelCreatePR: 'ยกเลิกการสร้างเอกสารใบขอสั่งซื้อ',
      AreYouSureToCancelCreatePR:
        'คุณยืนยันที่จะยกเลิกการสร้างใบขอสั่งซื้อหรือไม่ ?',
      CancelCreateSeparateGR: 'ยกเลิกการสร้างเอกสารใบรับสินค้าแยก',
      AreYouSureToCancelCreateSeparateGR:
        'คุณยืนยันที่จะยกเลิกการสร้างใบรับสินค้าแยกหรือไม่ ?',
      CancelEditPR: 'ยกเลิกการแก้ไขใบขอสั่งซื้อ',
      AreYouSureToCancelEditPR: 'คุณแน่ใจไหมที่จะยกเลิกการแก้ไขใบขอสั่งซื้อนี้',
      SaveDraftPR: 'บันทึกร่างใบขอสั่งซื้อ',
      AreYouSureToSaveDraftPR: 'คุณแน่ใจไหมที่จะบันทึกร่างใบขอสั่งซื้อนี้',
      CreateSeparateIV: 'สร้างใบแจ้งหนี้แยก',
      AreYouSureToCreateSeparateIV: 'คุณแน่ใจไหมที่จะสร้างใบแจ้งหนี้แยกนี้',
      CreateSeparateGR: 'สร้างใบรับสินค้าแยก',
      AreYouSureToCreateSeparateGR: 'คุณแน่ใจไหมที่จะสร้างใบรับสินค้าแยกนี้',
      CreatePR: 'สร้างใบขอสั่งซื้อ',
      AreYouSureToCreatePR: 'คุณแน่ใจไหมที่จะสร้างใบขอสั่งซื้อ',
      ConfirmGetReceipt: 'ยืนยันการรับใบเสร็จ',
      AreYouSureToGetReceipt: 'คุณยืนยันที่จะรับใบเสร็จหรือไม่ ?',
      ConfirmVoidGotReceipt: 'ยืนยันการยกเลิกรับใบเสร็จ',
      AreYouSureToVoidGotReceipt: 'คุณยืนยันที่จะยกเลิกการรับใบเสร็จหรือไม่ ?',
      ApprovePO: 'ยืนยันการอนุมัติใบสั่งซื้อ',
      AreYouConfirmToApprovePO: 'คุณยืนยันที่จะอนุมัติใบสั่งซื้อนี้หรือไม่ ?',
      CancelManagePage: 'ยกเลิกการแก้ไขข้อมูล',
      CancelManage: 'ยกเลิกการจัดการ',
      PressSubmitToCancel: 'คุณแน่ใจที่จะยกเลิกการบันทึกข้อมูลเหล่านี้หรือไม่?',
      ConfirmEditPage: 'ยืนยันการแก้ไขหน้า',
      ConfirmEdit: 'ยืนยันการแก้ไข',
      AreYouSureToEditPage: 'คุณยืนยันที่จะแก้ไขหน้า',
      AreYouSureToEdit: 'คุณยืนยันที่จะแก้ไข',
      ConfirmCreate: 'ยืนยันการบันทึกข้อมูล',
      AreYouSureToCreate: 'คุณยืนยันที่จะบันทึกข้อมูลเหล่านี้หรือไม่?',
      ConfirmEditNewsLandingPage: 'ยืนยันการแก้ไขข่าวสาร Landing Page',
      AreYouSureToEditNewsLandingPage:
        'คุณยืนยันที่จะแก้ไขหน้าข่าวสาร Landing Page',
      CancelManageNewsLandingPage: 'ยกเลิกการจัดการหน้าข่าวสาร Landing Page',
    },
    ErrorMessage: {
      QuantityMustNotBeNegative: '*จำนวนสินค้า/บริการ ต้องไม่เป็นค่าติดลบ',
      QuantityMustNotBeNegativeOrExceedTheRemaining:
        '*จำนวนสินค้า/บริการ ต้องไม่มากกว่ายอดจำนวนคงเหลือหรือไม่เป็นค่าติดลบ',
      ProductPriceMustNotBeNegative: '*ราคาสินค้าต้องไม่เป็นค่าติดลบ',
      ProductPriceMustNotBeNegativeOrExceedTheOriginalAmount:
        '*ราคาสินค้าต้องไม่มากกว่ายอดเดิมหรือต้องไม่เป็นค่าติดลบ',
      DiscountMustNotBeNegativeOrExceedTheProductPrice:
        '*ราคาส่วนลดต้องไม่มากกว่าราคาสินค้าหรือต้องไม่เป็นค่าติดลบ',
      TotalDiscountMustNotBeNegative: '**ยอดส่วนลดรวมต้องไม่เป็นค่าติดลบ',
      TotalDiscountMustNotExceed: '**ยอดส่วนลดรวมต้องไม่เกิน',
      AndMustNotBeNegative: 'และไม่เป็นค่าติดลบ',
      GrandTotalAmountMustNotBe0Baht:
        '**จำนวนเงินที่ต้องชำระต้องไม่น้อยกว่า 0 บาท',
      QuantityGreaterThanRemaining: '*จำนวนสินค้า/บริการ มีมากกว่าค่าคงเหลือ',
      DescNotFirstRow:
        'แถวคำอธิบายไม่สามารถอยู่ก่อนหน้าแถวรายการสินค้าแรกในรายการ',
      PleaseSelectItem: '*โปรดเลือกรายการสินค้า',
    },
    Handle: {
      Name: 'กรุณาระบุชื่อ',
      Exceed: 'ไม่สามารถเกิน',
      Char: 'ตัวอักษร',
      Group: 'กรุณาระบุกลุ่ม',
      Title: 'กรุณาระบุหัวข้อ',
      SubTitle: 'กรุณาระบุหัวข้อย่อย',
      Category: 'กรุณาระบุหมวดหมู่',
      Class: 'กรุณาระบุคลาสย่อย',
      classId: 'กรุณาระบุคลาส',
      Detail: 'กรุณาระบุเนื้อหา',
      ExceedChar: 'ชื่อต้องมีความยาวไม่เกิน 50 ตัวอักษร',
      UserName: 'กรุณาระบุชื่อผู้ใช้งาน',
      ExceedCharUserName: 'ชื่อผู้ใช้งานต้องมีความยาวไม่เกิน 100 ตัวอักษร',
      Email: 'กรุณาระบุอีเมล',
      EmailSyntax: 'กรุณาระบุอีเมลให้ถูกต้อง',
      LastName: 'กรุณาระบุนามสกุล',
      ExceedCharLastName: 'นามสกุลต้องมีความยาวไม่เกิน 100 ตัวอักษร',
      ExceedCharName: 'ชื่อจริงต้องมีความยาวไม่เกิน 100 ตัวอักษร',
      DescriptionAllLanguage: 'กรุณาระบุคำอธิบายให้ครบทุกภาษา',
      DetailAllLanguage: 'กรุณาระบุรายละเอียดให้ครบทุกภาษา',
      SingleImage: 'กรุณาเลือกรูปภาพ',
      MutipleFile: 'กรุณาเลือกไฟล์',
      AtLeast:'กรุณาเลือกอย่างน้อย',
      FirstLetterInvalid: 'ตัวอักษรตัวแรกห้ามเป็นตัวอักษรพิเศษ',
      CodeName: 'กรุณาระบุชื่อโปรโมชั่น',
      RedeemCode: 'กรุณาระบุโค้ดโปรโมชั่น',
      Amount: 'กรุณาระบุจำนวน',
      Discount: 'กรุณาระบุส่วนลด',
      AmountPerCustomer: 'กรุณาระบุจำนวนโค้ดที่ลูกค้าสามารถใช้ซ้ำได้',
      StartDate: 'กรุณาระบุวันที่เริ่มต้น',
      EndDate: 'กรุณาระบุวันที่สิ้นสุด',
      noWhitespaceValidator: 'ห้ามมีช่องว่าง',
    },
    General: {
      Save: 'บันทึก',
      PressToCancel: 'กดปุ่มยืนยันเพื่อยกเลิก',
      CancelAction: 'ยกเลิกการกระทำที่เกี่ยวกับ',
      CancelEdit: 'ยกเลิกการแก้ไข',
      CancelCreate: 'ยกเลิกการสร้าง',
      Cancel: 'ยกเลิกการจัดการ',
      General: 'ทั่วไป',
      Default: 'ค่าเริ่มต้น',
      Deprecated: 'ยกเลิก',
      Gender: 'เพศ',
      Detail: 'รายละเอียด',
      IngredientsAndExtracts: 'วัตถุดิบและสารสกัด',
      compound: 'สารประกอบ',
      Date: 'วันที่',
      Total: 'มูลค่า',
      Buy: 'ซื้อ',
      Baht: 'บาท',
      Sell: 'ขาย',
      OrderHistory: 'ประวัติการซื้อ-ขาย',
      Check: 'ตรวจสอบ',
      Copy: 'คัดลอก',
      Quotation: 'ใบเสนอราคา',
      Confirm: 'ยืนยันการ',
      ConfirmCreate: 'ยืนยันการสร้าง ',
      AreYouConfirm: 'คุณแน่ใจไหมที่จะ',
      toCreate: 'ที่จะสร้าง',
      toEdit: 'ที่จะแก้ไข',
      Home: 'หน้าหลัก',
      Admin: 'ผู้ดูแลระบบ',
      Profile: 'โปรไฟล์',
      ACLManage: 'จัดการ ACL',
      UserManage: 'จัดการผู้ใช้งาน',
      RolesManage: 'จัดการระดับผู้ใช้งาน',
      Banner: 'แบนเนอร์',
      BannerManage: 'จัดการแบนเนอร์',
      BrandManage: 'จัดการแบรนด์',
      Brand: 'แบรนด์',
      News: 'ข่าวสาร',
      NewsManage: 'จัดการข่าวสาร',
      Item: 'สินค้า',
      ContentManage: 'จัดการเนื้อหา',
      RelatedDocument: 'เอกสารที่เกี่ยวข้อง',
      ItemManage: 'จัดการสินค้า',
      ItemSetManage: 'จัดการชุดสินค้า',
      GroupManage: 'จัดการกลุ่ม',
      CategoryManage: 'จัดการหมวดหมู่',
      SubCategoryManage: 'จัดการหมวดหมู่ย่อย',
      ClassManage: 'จัดการคลาส',
      SubClassManage: 'จัดการคลาสย่อย',
      Purchasing: 'ระบบจัดซื้อ',
      PurchaseOrder: 'ใบสั่งซื้อ',
      ControlPanel: 'แผงควบคุม',
      Create: 'สร้าง',
      EditUp: 'แก้ไข',
      Edit: 'แก้ไข',
      Export: 'พิมพ์ทั้งหมด',
      Delete: 'ลบ',
      Add: 'เพิ่ม',
      Manage: 'จัดการ',
      PDPA: 'PDPA',
      Event: 'อีเวนต์',
      Search: 'ค้นหา',
      SortBy: 'จัดเรียงโดย',
      Status: 'สถานะ',
      All: 'ทั้งหมด',
      Draft: 'ร่าง',
      Approve: 'อนุมัติ',
      Bin: 'ถังขยะ',
      Previous: 'ก่อนหน้า',
      Next: 'ถัดไป',
      Change: 'เปลี่ยน',
      Settings: 'การตั้งค่า',
      Group: 'กลุ่ม',
      Category: 'หมวดหมู่',
      SubCategory: 'หมวดหมู่ย่อย',
      Class: 'คลาส',
      SubClass: 'คลาสย่อย',
      ItemManagement: 'จัดการสินค้า',
      Customer: 'ผู้ติดต่อ',
      Sale: 'เอกสารซื้อขาย',
      Account: 'จัดการบัญชี',
      Trash: 'ถังขยะ',
      Document: 'เอกสาร',
      RevenueDocument: 'เอกสารรายรับ',
      ExpensesDocument: 'เอกสารรายจ่าย',
      Revenue: 'รายรับ',
      Exoenses: 'รายจ่าย',
      ViewAll: 'ดูทั้งหมด',
      Stock: 'คลังสินค้า',
      StockManage: 'จัดการคลังสินค้า',
      Reset: 'รีเซ็ต',
      Back: 'กลับ',
      Expired: '(หมดอายุ)',
      DocumentInfo: 'ข้อมูลเอกสาร',
      SlipInfo: 'ข้อมูลสลิป',
      PaymentInfo: 'ข้อมูลการชำระ',
      HistoryInfo: 'ข้อมูลประวัติ',
      Percent: 'เปอร์เซ็นต์',
      Remain: 'เหลือ',
      InStock: 'ในคลัง',
      Original: 'ยอดเดิม',
      Product: 'สินค้า',
      OurTechnology: 'เทคโนโลยีของเรา',
      About: 'เกี่ยวกับ',
      NewsEvent: 'ข่าวสารและกิจกรรม',
      LandingPage: 'รวมข่าวสาร',
      ConsumerReview: 'Consumer Review',
      CompanyVideo: 'วีดีโอของบริษัท',
      Contact: 'ติดต่อ',
      PrivacyPolicy: 'นโยบายความเป็นส่วนตัว',
      CookiePolicy: 'ข้อตกลงการใช้คุกกี้',
      TermsCondition: 'เงื่อนไขและข้อตกลง',
      SellerManage: 'Seller Management Regulation',
      ShoppingCart: 'ตะกร้าสินค้า',
      SignIn: 'ลงชื่อเข้าใช้',
      RoleSet: 'ระดับผู้ใช้งาน',
      ValueWaitToBePaid: 'มูลค่าที่รอรับชำระอีก',
      ValueWaitToPaid: 'มูลค่าที่รอชำระอีก',
      ReceivePayment: 'รับชำระ',
      Payment: 'ชำระ',
      SystemSettings: 'ตั้งค่าระบบ',
      PrintShippingLabel: 'พิมพ์ใบปะหน้าพัสดุ',
      DeliveryProvider: 'บริษัทขนส่ง',
      Cod: 'เก็บเงินปลายทาง',
      TransferPayment: 'โอนชำระ',
      ManageCode: 'จัดการโค้ด',
      PromotionCode: 'โค้ดโปรโมชั่น',
      PromotionCodeManage: 'จัดการโค้ดโปรโมชั่น',
      ConfirmReceivePayment: 'ยืนยันการรับเงินแบบปลายทาง',
      AreYouSureToReleaseCOD: 'คุณแน่ใจที่จะปล่อยสินค้าที่มีการชำระเงินแบบ COD หรือไม่? \nหากยกเลิกจะเป็นการยกเลิกรายการซื้อขายนี้ทันที',
      ConfirmCancelOrdertitle: 'ยืนยันการยกเลิกรายการซื้อขาย',
      ConfirmCancelOrdertitleDetail: 'คุณยืนยันที่จะยกเลิกรายการซื้อขายนี้หรือไม่',
      Success: 'สำเร็จ',
      CancelOrderSuccess: 'ยกเลิกรายการซื้อขายสำเร็จ',
      PaymentType: 'ช่องทางการชำระ',
      CashOnDelivery: 'เก็บเงินปลายทาง',
      BankTransfer: 'โอนเงินผ่านธนาคาร',
      ManageReview: 'จัดการรีวิว',
      Review: 'รีวิว',
      ItemName: 'ชื่อสินค้า',
      FromDate: 'จากวันที่',
      ToDate: 'ถึงวันที่',
      BannerSwiper: 'จัดการ แบนเนอร์',
      NewsSwiper: 'จัดการ ข่าวสาร'



    },
    Form: {
      Name: 'ชื่อ',
      Barcode: 'บาร์โค้ด',
      Description: 'คำอธิบาย',
      Detail: 'รายละเอียด',
      Status: 'สถานะ',
      Quantity: 'จำนวน',
      Price: 'ราคา',
      beforeDiscountPrice: 'ราคาก่อนลด',
      purchasePrice: 'ราคาซื้อ',
      SellingPrice: 'ราคาขาย',
      Group: 'กลุ่ม',
      Images: 'รูปภาพ',
      Cancel: 'ยกเลิก',
      Confirm: 'ตกลง',
      Submit: 'ยืนยัน',
      RefText1: 'ข้อความอ้างอิง 1',
      Title: 'หัวข้อ',
      Subtitle: 'คำบรรยายย่อย',
      Details: 'รายละเอียด',
      ButtonCaption: 'คำอธิบายปุ่ม',
      ButtonURL: 'ลิงก์ปุ่ม',
      Files: 'ไฟล์',
      FieldRequired: 'จำเป็นต้องกรอกข้อมูลในฟิลด์นี้!',
      Attract: 'แนบไฟล์',
      Cover: 'ปก',
      SaveDraft: 'บันทึกร่าง',
      ApproveDoc: 'อนุมัติรายการ',
      CreateGRFormPO: 'สร้างใบรับสินค้าจากใบสั่งซื้อ',
      IsShowing: 'โชว์ที่เว็บไซต์',
      IsBestSeller: 'สินค้าขายดี',
      IsNewProduct: 'สินค้าใหม่',
      EnterDescription: 'กรอกคำอธิบาย...',
      EnterName: 'กรอกชื่อ...',
      BeginTypeHere: 'เริ่มพิมพ์ได้ที่นี่...',
    },
    Status: {
      Active: 'เปิดใช้งาน',
      Inactive: 'ปิดใช้งาน',
      Published: 'เผยแพร่แล้ว',
      Unpublished: 'ยังไม่เผยแพร่',
      Publish: 'เผยแพร่',
      Unpublish: 'ไม่เผยแพร่',
      Draft: 'ร่าง',
      AwaitApproval: 'รออนุมัติ',
      Accepted: 'อนุมัติ',
      AwaitPayment: 'รอชำระเงิน',
      Paid: 'ชำระแล้ว',
      Expired: 'หมดอายุ',
      Overdue: 'พ้นกำหนด',
      Voided: 'ยกเลิก',
      Cancel: 'ยกเลิก',
      GotReceipt: 'รับใบเสร็จแล้ว',
      Pending: 'รอดำเนินการ',
      Complete: 'เสร็จสิ้น',
      WaitingAdjust: 'รอจัดการสินค้า',
    },
    Sort: {
      AscChar: 'A-Z',
      DescChar: 'Z-A',
      Newest: 'ใหม่ที่สุด',
      Oldest: 'เก่าที่สุด',
    },
    Menu: {
      Document: {
        QO: 'ใบเสนอราคา (QO)',
        SO: 'ใบสั่งขาย (SO)',
        IV: 'ใบแจ้งหนี้ (IV)',
        TX: 'ใบกำกับภาษี (TX)',
        RC: 'ใบเสร็จรับเงิน (RC)',
        PR: 'ใบขอสั่งซื้อ (PR)',
        PO: 'ใบสั่งซื้อ (PO)',
        GR: 'ใบรับสินค้า (GR)',
        CNT: 'ใบลดหนี้ (CNT)',
        DBN: 'ใบเพิ่มหนี้ (DBN)',
      },
      Profile: 'โปรไฟล์',
      Logout: 'ออกจากระบบ',
      Settings: 'การตั้งค่า',
      FrontendControlPanel: 'แผงควบคุมสำหรับเว็บหน้าบ้าน',
    },
    Settings: {
      General: 'ตั้งค่าทั่วไป',
      BankAccount: 'ตั้งค่าบัญชีธนาคาร',
      DocumentInfo: 'รายละเอียดเอกสาร',
      Copied: 'คัดลอกสำเร็จ',
      CopyBankAccountToClipboard: 'คัดลอกหมายเลขบัญชีธนาคารเรียบร้อยแล้ว',
      Modal: {
        GeneralConfirmTitle: 'ยืนยันการบันทึกรายละเอียดเอกสาร',
        GeneralConfirmDetail:
          'คุณแน่ใจที่จะบันทึกรายละเอียดเอกสารด้วยข้อมูลเหล่านี้หรือไม่?',
        SetDefaultBankAccountTitle: 'ยืนยันการตั้งค่าบัญชีธนาคารเริ่มต้น',
        SetDefaultBankAccountDetail:
          'คุณแน่ใจที่จะยืนยันการตั้งค่าบัญชีธนาคารเริ่มต้นด้วยบัญชีนี้หรือไม่?',
        RemoveDefaultBankAccountTitle: 'ยืนยันการยกเลิกบัญชีธนาคารเริ่มต้น',
        RemoveDefaultBankAccountDetail:
          'คุณแน่ใจที่จะยกเลิกการตั้งค่าบัญชีธนาคารเริ่มต้นบัญชีนี้หรือไม่?',
      },
      DefaultBankAccount: 'บัญชีธนาคารเริ่มต้น',
      AllBankAccount: 'บัญชีธนาคารทั้งหมด',
      Toast: {
        Success: 'สำเร็จ',
        Error: 'ผิดพลาด',
        SuccessDefaultBankAccountDetail:
          'ตั้งเป็นค่าเริ่มต้นสำหรับบัญชีธนาคารสำเร็จ',
        SuccessRemoveDefaultBankAccountDetail:
          'ถอนค่าเริ่มต้นสำหรับบัญชีธนาคารสำเร็จ',
      },
      Branch: 'สาขา'
    },
    User: {
      Username: 'ชื่อผู้ใช้งาน',
      Name: 'ชื่อ',
      Prefix: 'คำนำหน้าชื่อ',
      FirstName: 'ชื่อจริง',
      LastName: 'นามสกุล',
      Gender: 'เพศ',
      Email: 'อีเมล',
      Phone: 'เบอร์โทรศัพท์',
      Role: 'ระดับผู้ใช้งาน',
      Company: 'บริษัท',
      Organization: 'องค์กร',
      Branch: 'สาขา',
      Department: 'แผนก',
      Position: 'ตำแหน่ง',
      CreateOn: 'เข้าร่วมเมื่อวันที่',
      General: 'ทั่วไป',
      Security: 'ความปลอดภัย',
      AccountSettings: 'ตั้งค่าผู้ใช้งาน',
      OldPassword: 'รหัสผ่านเดิม',
      NewPassword: 'รหัสผ่านใหม่',
      ReNewPassword: 'รหัสผ่านใหม่อีกครั้ง',
      IsActive: 'การเปิดใช้งาน',
      IsVerify: 'การยืนยัน',
      UserStatus: 'สถานะผู้ใช้งาน',
      UserDetail: 'รายละเอียดผู้ใช้งาน',
      Active: 'เปิดใช้งาน',
      InActive: 'ปิดใช้งาน',
      SelectRole: 'กรุณาเลือกระดับผู้ใช้งาน',
      SelectPrefix: 'กรุณาเลือกคำนำหน้าชื่อ',
      SelectGender: 'กรุณาเลือกเพศ',
      Cover: 'ภาพปก',
    },
    // {{"Customer.branch" | translate}}
    Customer: {
      businessName: 'ชื่อธุรกิจ',
      businessNameError: 'จำเป็นต้องระบุชื่อธุรกิจ',
      contactInfo: 'ข้อมูลผู้ติดต่อ',
      accountsReceivableRecord: 'บันทึกบัญชีลูกหนี้',
      accountsPayableRecord: 'บันทึกบัญชีเจ้าหนี้',
      thaiName: 'ชื่อ',
      contactName: 'ชื่อผู้ติดต่อ',
      individualOrLegalEntity: 'บุคคลหรือนิติ',
      JuristicPerson: 'นิติบุคคล',
      OrdinaryPerson: 'บุคคลธรรมดา',
      requiredError: 'จำเป็นต้องระบุชื่อ',
      selectCountry: 'เลือกประเทศ',
      country: 'ไทย',
      countryOther: 'ประเทศอื่น ๆ',
      idNumber: 'เลขประจำตัวผู้เสียภาษี/เลขประจำตัว',
      contactType: 'ประเภทผู้ติดต่อ',
      officeType: 'ประเภทสำนักงาน',
      noOffice: 'สำหรับผู้ที่ไม่มีสำนักงาน',
      headOffice: 'สำนักงานใหญ่',
      office: 'สำนักงาน',
      noCountry: 'ไม่มี',
      branch: 'สาขา',
      search: 'ค้นหา',
      branchCodeLabel: 'รหัสสาขา',
      branchCodePlaceholder: 'กรอกรหัสสาขา',
      branchNameLabel: 'ชื่อสาขา',
      branchNamePlaceholder: 'กรอกชื่อสาขา',
      officeTypeLabel: 'เลือกประเภทขององค์กร',
      pleaseSpecify: 'กรุณาระบุ',
      nameLabel: 'ชื่อ',
      contactNameLabel: 'ชื่อผู้ติดต่อ',
      debtorLabel: 'บันทึกบัญชีลูกหนี้',
      creditorLabel: 'บันทึกบัญชีเจ้าหนี้',
      personalInfo: 'ข้อมูลบุคคล',
      primaryContact: 'ผู้ติดต่อหลัก',
      specifyContactInfo: 'ระบุข้อมูลติดต่อเบื้องต้นของคุณ',
      firstName: 'ชื่อจริง',
      lastName: 'นามสกุล',
      nickname: 'ชื่อเล่น',
      ContactCode: 'รหัสผู้ติดต่อ',
      UplineCode: 'รหัส Upline',
      FullName: 'ชื่อ - นามสกุล',
      position: 'ตำแหน่ง',
      nameRequiredError: 'จำเป็นต้องระบุชื่อ',
      lastNameRequiredError: 'จำเป็นต้องระบุนามสกุล',
      nicknameRequiredError: 'จำเป็นต้องระบุชื่อเล่น',
      positionRequiredError: 'จำเป็นต้องระบุตำแหน่ง',
      contactChannelInfo: 'ข้อมูลช่องทางติดต่อ',
      centralContactInfo: 'ข้อมูลติดต่อกลาง',
      indirectContact: 'ในกรณีที่ติดต่อคุณไม่ได้โดยตรง',
      centralPhoneNumber: 'หมายเลขโทรศัพท์กลาง',
      faxNumber: 'หมายเลขแฟกซ์',
      centralEmail: 'อีเมลกลาง',
      syntaxError: 'ผิดพลาดททางอักขระ',
      website: 'เว็บไซต์',
      gbPhoneRequiredError: 'จำเป็นต้องระบุหมายเลขโทรศัพท์กลาง',
      gbFaxRequiredError: 'จำเป็นต้องระบุหมายเลขแฟกซ์',
      gbEmailRequiredError: 'จำเป็นต้องระบุอีเมลกลาง',
      gbSiteRequiredError: 'จำเป็นต้องระบุเว็บไซต์',
      office_type: 'จำเป็นต้องเลือกประเภทของสำนักงาน',
      debtorRq: 'จำเป็นต้องเลือกประเภทบันทึกบัญชีลูกหนี้',
      creditorRq: 'จำเป็นต้องเลือกประเภทบันทึกบัญชีเจ้าหนี้',
      phone: 'เบอร์โทรศัพท์',
      email: 'อีเมล',
      emailRequiredError: 'จำเป็นต้องระบุอีเมล',
      phoneRequiredError: 'จำเป็นต้องระบุหมายเลขโทรศัพท์',
      fillOutCompletely: 'กรุณากรอกข้อมูลให้ครบ',
      invalidAllZeroBranchNumber: 'ไม่สามารถใช้รหัสสาขา 00000 ได้',
      branchNameRequiredError: 'จำเป็นต้องระบุชื่อสาขา',
      duplicateTaxID:
        'หมายเลขนี้ได้ถูกใช้ในที่อยู่สำหรับออกใบกับกำภาษีอื่นแล้ว',
      BuyHistory: 'ประวัติการซื้อ',
      SellHistory: 'ประวัติการขาย',
    },
    Gender: {
      Male: 'ชาย',
      Female: 'หญิง',
      NotSpecified: 'ไม่ระบุ',
    },
    Address: {
      AlertAddr: 'กรุณากรอกให้ครบและตรวจสอบความถูกต้อง',
      addrInfo: 'ข้อมูลที่อยู่',
      registeredAddress: 'ที่อยู่ออกใบกำกับภาษี',
      mailingAddress: 'ที่อยู่จัดส่ง',
      contactPerson: 'ผู้ติดต่อ',
      address: 'ที่อยู่',
      addressDetails: 'เลขที่ - ตรอก/ซอย ถนน',
      addressDetailsNote: 'กรุณาระบุ',
      country: 'ประเทศ',
      copy: 'ใช้ข้อมูลที่อยู่ออกใบกำกับภาษี',
      thailand: 'ไทย',
      india: 'อินเดีย',
      thailand2: 'ไทย(2)',
      province: 'จังหวัด',
      bangkok: 'กรุงเทพมหานคร',
      startingPoint: 'มุมต้น',
      district: 'เขต/อำเภอ',
      subdistrict: 'แขวง/ตำบล',
      postalCode: 'รหัสไปรษณีย์',
      cNameRequiredError: 'จำเป็นต้องระบุผู้ติดต่อ',
      cAddressRequiredError: 'จำเป็นต้องระบุที่อยู่',
      cLocalRequiredError: 'จำเป็นต้องระบุพื้นที่ท้องถิ่น',
      cCityRequiredError: 'จำเป็นต้องระบุจังหวัด',
      cDistrictRequiredError: 'จำเป็นต้องระบุเขต/อำเภอ',
      cSubDistrictRequiredError: 'จำเป็นต้องระบุแขวง/ตำบล',
      cCountyRequiredError: 'จำเป็นต้องระบุประเทศ',
      cPostcodeRequiredError: 'จำเป็นต้องระบุรหัสไปรษณีย์',
      cPhoneRequiredError: 'จำเป็นต้องระบุเบอร์ติดต่อ',
      selectCountry: 'เลือกประเทศ',
      selectProvince: 'เลือกจังหวัด',
      selectDistrict: 'เลือกเขต/อำเภอ',
      selectSubDistrict: 'เลือกแขวง/ตำบล',
      isDefault: 'ค่าเริ่มต้น',
      setAsDefault: 'ตั้งเป็นค่าเริ่มต้น',
      contactPhone: 'เบอร์ติดต่อ',
      addNewAddress: 'เพิ่มที่อยู่',
      editSendShippingAddress: 'แก้ไขที่อยู่ในการจัดส่งสินค้า',
      editReceiveShippingAddress: 'แก้ไขที่อยู่การรับสินค้า',
      cancelEditAddress: 'ยกเลิกการแก้ไข',
    },
    Bank: {
      reftext: 'ข้อมูลธนาคาร',
      bankName: 'ธนาคาร',
      bankGroupRequired: 'ต้องระบุ bank_group',
      branch: 'สาขา',
      bankAddressRequired: 'ต้องระบุ bank_addr',
      accountNumber: 'เลขที่บัญชี',
      bankIdRequired: 'ต้องระบุ bank_id',
      accountName: 'ชื่อบัญชี',
      bankNameRequired: 'ต้องระบุ bank_name',
      accountType: 'ประเภทบัญชี',
      savings: 'ออมทรัพย์',
      current: 'กระแสรายวัน',
      bankTypeRequired: 'ต้องระบุ bank_type',
    },
    Invoice: {
      item: 'การตั้งค่าใบแจ้งหนี้',
      sellPaymentSettingLabel: 'กำหนดการชำระเงินตามใบแจ้งหนี้ที่ออก (ขายไป)',
      sellPaymentSettingOptions: {
        settingByCompany: 'ตั้งค่าตามการตั้งค่าของบริษัท',
        afterInvoiceXDays: 'X วันหลังออกใบแจ้งหนี้',
        endOfMonthInvoiceDate: 'สิ้นเดือนของวันที่ออกใบแจ้งหนี้',
        endOfMonthNextMonth: 'สิ้นเดือนของเดือนถัดไป',
      },
      buyPaymentSettingLabel:
        'กำหนดการชำระเงินตามรายจ่ายที่บันทึก/ใบแจ้งหนี้ที่ได้รับ (ซื้อมา)',
      cancelButton: 'ยกเลิก',
      saveButton: 'บันทึก',
      sellreq: 'กรุณากรอก sell.',
      buyreq: 'กรุณากรอก buy',
    },
    iTem: {
      Group: 'กลุ่มของหมวดหมู่',
      Validate: ' * จำเป็นต้องเลือก',
      Cate: 'หมวดหมู่ของกลุ่ม',
      subCate: 'หมวดหมู่ย่อย',
      Number: ' * ตัวเลขเท่านั้น',
      Media: 'จำกัดจำนวน',
      MediaLimit: ' * สามารถเพิ่มรูปภาพได้ไม่เกิน 5 รูป',
    },
    Document: {
      QuotationDraft: 'ร่างใบเสนอราคา',
      Quotation: 'ใบเสนอราคา',
      SaleOrder: 'ใบสั่งขาย',
      Invoice: 'ใบแจ้งหนี้',
      Invoice_Tax: 'ใบแจ้งหนี้/ใบกำกับภาษี',
      Tax: 'ใบกำกับภาษี',
      DebitNote: 'ใบเพิ่มหนี้',
      CreditNote: 'ใบลดหนี้',
      ReturnSlip: 'ใบรับคืนสินค้า',
      Receipt: 'ใบเสร็จรับเงิน',
      IV_TX_RE: 'ใบแจ้งหนี้/ใบกำกับภาษี/ใบเสร็จรับเงิน',
      PurchaseRequest: 'ใบขอสั่งซื้อ',
      PurchaseOrder: 'ใบสั่งซื้อ',
      SeparateGR: 'สร้างใบรับสินค้าแยก',
      SeparateIV: 'สร้างใบแจ้งหนี้แยก',
      GoodsReceive: 'ใบรับสินค้า',
      goodRecieve: 'ใบรับสินค้า',
      fullValue: 'มูลค่าเต็ม',
      writtenOff: 'จำนวนเงินที่ตัดชำระออก',
    },
    BreadCrumbs: {
      Manage: {
        Brand: 'แบรนด์',
        Group: 'กลุ่ม',
        Category: 'หมวดหมู่',
        SubCategory: 'หมวดหมู่ย่อย',
        Class: 'คลาส',
        SubClass: 'คลาสย่อย',
        Item: 'สินค้า',
        ItemSet: 'ชุดสินค้า',
        Customer: 'จัดการบัญชี',
        User: 'จัดการผู้ใช้งาน',
        Role: 'จัดการระดับผู้ใช้งาน',
        Trash: 'ถังขยะ',
        BankAccount: 'บัญชีธนาคาร',
      },
      CMS: {
        Banner: 'จัดการแบนเนอร์',
        banner: 'แบนเนอร์',
        News: 'จัดการข่าวสารและกิจกรรม',
        news: 'ข่าวสารและกิจกรรม',
        PDPA: 'จัดการหน้า Privacy Policy',
        PrivacyPolicy: ' Privacy Policy',
        pdpa: ' PDPA',
        Event: 'จัดการอีเวนต์',
        event: 'อีเวนต์',
        cookiePolicy: 'จัดการหน้า ข้อตกลงการใช้คุกกี้',
        CookiePolicy: 'ข้อตกลงการใช้คุุกกี้',
        termsCondition: 'จัดการหน้า เงื่อนไขและข้อตกลง',
        TermsCondition: 'เงื่อนไขและข้อตกลง',
        shoppingCart: 'จัดการหน้าตะกร้าสินค้า',
        ShoppingCart: ' ตะกร้าสินค้า',
        signIn: 'จัดการหน้าเข้าสู่ระบบ',
        SignIn: ' เข้าสู่ระบบ',
        Contact: 'จัดการหน้า การติดต่อ',
        contact: 'การติดต่อ',
        About: 'จัดการหน้าเกี่ยวกับ',
        about: 'เกี่ยวกับ',
        Home: 'จัดการหน้าแรก',
        home: 'หน้าแรก',
        Product: 'จัดการหน้ารวมผลิตภัณท์',
        ProductLanding: 'รวมผลิตภัณท์',
        LandingPage: 'จัดการหน้ารวมข่าวสาร',
        landingPage: 'ข่าวสาร',
        CreateNews: 'สร้างข่าวสารและอีเวนต์',
        SellerManageRegulation: 'Seller Management Regulation',
        SellerManagementRegulation: 'จัดการหน้า Seller Management Regulation',

        newsEvent: 'จัดการหน้า News and Event',
        NewsEvent: ' News and Event',
        NewsEventLandingPage: 'จัดการหน้ารวม News and Event',

        CustomersReview: 'จัดการหน้าความคิดเห็นจากผู้ใช้งาน',
        customerReview: 'ความคิดเห็นจากผู้ใช้งาน',
        CustomerReviewLandingPage: 'จัดการหน้ารวมความคิดเห็นจากผู้ใช้งาน',

        howToBuy: 'วิธีการสั่งซื้อ',
        HowToBuy: 'จัดการหน้าวิธีการซื้อ',
        HowToBuyLandingPage: 'จัดการหน้ารวมวิธีการสั่งซื้อ',

        ingredients: 'วัตถุดิบ',
        Ingredients: 'จัดการหน้าวัตถุดิบ',
        IngredientsLandingPage: 'จัดการหน้ารวมวัตถุดิบ',

        suitableFor: 'เหมาะกับใคร',
        SuitableFor: 'จัดการหน้าเหมาะกับใคร',

        faq: 'FAQ',
        Faq: 'จัดการหน้า FAQ',

        howToUse: 'วิธีการใช้',
        HowToUse: 'จัดการหน้าวิธีการใช้',
        HowToUseLandingPage: 'จัดการหน้ารวมวิธีการใช้',

        NewsReview: 'จัดการหน้า Consumer Review',
        newsReview: ' Consumer Review',
        NewsReviewLandingPage: 'จัดการหน้ารวม Consumer Review',

        companyVideo: 'จัดการหน้า Company Video',
        CompanyVideo: ' Company Video',
        CompanyVideoLandingPage: 'จัดการหน้ารวม Company Video',

        newsVideo: 'จัดการหน้า Company Video',
        NewsVideo: ' Company Video',
        NewsVideoLandingPage: 'จัดการหน้ารวม Company Video',

        Pdpa: {
          PdpaMenu: 'นโยบายความเป็นส่วนส่วนตัว',
          privacyPolicy: 'ข้อความ Privacy Policy',
        },
        ContactPage: {
          ContactMenu: 'ติดต่อ',
          PhoneNumber: {
            Label: 'เบอร์โทรศัพท์',
          },
          Email: {
            Label: 'อีเมล',
          },
          Line: {
            Label: 'ไลน์',
          },
          ImageForm: {
            Label: 'รูปภาพฟอร์ม',
            Description: 'ความละเอียด 760px*526px',
          },
          ContactInfoDescription: {
            Label: 'รายละเอียดข้อมูลการติดต่อ',
          },
          facebookLink: {
            Label: 'ลิงค์ Facebook (URL)',
          },
          lineLink: {
            Label: 'ลิงค์ line (URL)',
          },
          shopeeLink: {
            Label: 'ลิงค์ Shopee (URL)',
          },
          lazadaLink: {
            Label: 'ลิงค์ Lazada (URL)',
          },
          qrcode: {
            Label: 'รูปภาพ คิวอาร์โค๊ด',
            Description: ' ความละเอียด 193px*193px',
          },
        },
        HomePage: {
          DetailMenu: 'รายละเอียด',
          BannerMenu: 'แบนเนอร์',
          NewsMenu: 'ข่าวสาร',

          HomeLogo: {
            Label: 'โลโก้',
            Description: 'ความละเอียด 395px*255px',
          },
          MessageLogo: {
            Label: 'ข้อความ ใต้โลโก้',
          },
          TitleExtraction: {
            Label: 'หัวเรื่องสารสกัด',
          },
          SubTitleExtraction: {
            Label: 'หัวเรื่องรอง สารสกัด',
          },
          ImageExtractionDesktop: {
            Label: 'รูปภาพ สารสกัด 1 (Desktop Size)',
            Description: 'ความละเอียด 1625px*1165px',
          },
          ImageExtractionTablet: {
            Label: 'รูปภาพ สารสกัด 2 (Tablet Size)',
            Description: 'ความละเอียด 730px*520px',
          },
          ImageExtractionMobile: {
            Label: 'รูปภาพ สารสกัด 3 (Mobile Size)',
            Description: 'ความละเอียด 725px*750px',
          },
          ProductCardTitle: {
            Label: 'หัวเรื่อง การ์ดสินค้า',
          },
          ProductCardTitleImageDesktop: {
            Label: 'รูปภาพ หัวเรื่องการ์ดสินค้า (Desktop & Tablet)',
            Description: 'ความละเอียด 650px*900px',
          },
          ProductCardTitleImageMobile: {
            Label: 'รูปภาพ หัวเรื่องการ์ดสินค้า (mobile)',
            Description: 'ความละเอียด 255px*390px',
          },
          ProductCardMessageOne: {
            Label: 'ข้อความการ์ดสินค้า 1',
          },
          ProductCardImageOne: {
            Label: 'รูปภาพไอค่อน การ์ดสินค้า 1',
            Description: 'ความละเอียด 185px*185px',
          },
          ProductCardMessageTwo: {
            Label: 'ข้อความการ์ดสินค้า 2',
          },
          ProductCardImageTwo: {
            Label: 'รูปภาพไอค่อน การ์ดสินค้า 2',
            Description: 'ความละเอียด 185px*185px',
          },
          ProductCardMessageThree: {
            Label: 'ข้อความการ์ดสินค้า 3',
          },
          ProductCardImageThree: {
            Label: 'รูปภาพไอค่อน การ์ดสินค้า 3',
            Description: 'ความละเอียด 185px*185px',
          },
          ProductCardMessageFour: {
            Label: 'ข้อความการ์ดสินค้า 4',
          },
          ProductCardImageFour: {
            Label: 'รูปภาพไอค่อน การ์ดสินค้า 4',
            Description: 'ความละเอียด 185px*185px',
          },
          PromotionOne: {
            Label: 'รูปภาพโปรโมชั่น 1',
            Description: 'ความละเอียด 358px*358px',
          },
          PromotionTwo: {
            Label: 'รูปภาพโปรโมชั่น 2',
            Description: 'ความละเอียด 358px*358px',
          },
          PromotionThree: {
            Label: 'รูปภาพโปรโมชั่น 3',
            Description: 'ความละเอียด 358px*358px',
          },
          PromotionFour: {
            Label: 'รูปภาพโปรโมชั่น 4',
            Description: 'ความละเอียด 358px*358px',
          },
          NewsImage: {
            Label: 'รูปภาพ ข่าวสาร',
            Description: 'ความละเอียด 780px*500',
          },
          NewsTitle: {
            Label: 'หัวเรื่อง ข่าวสาร',
          },
          NewsDetail: {
            Label: 'รายละเอียด ข่าวสาร',
          },
          NewsLink: {
            Label: 'ลิงค์ ข่าวสาร (URL)',
          },
          BannerImage: {
            Label: 'รูปภาพ แบนเนอร์',
            Description: 'Resolution 1920px*1080px',
          },
        },
        

      },

    },
    FormQuotation: {
      SelectCustomer: 'กรุณาเลือกผู้ติดต่อ',
      SelectStockType: 'กรุณาเลือกประเภทคลังสินค้า',
      SelectItem: 'กรุณาเลือกสินค้า/บริการ',
      SelectShippingAddress: 'กรุณาเลือกที่อยู่ในการจัดส่งสินค้า',
      SelectReceiveAddress: 'กรุณาเลือกที่อยู่ในการรับสินค้า',
      SelectBillingAddress: 'กรุณาเลือกที่อยู่ในการจัดส่งใบกำกับภาษี',
      SelectIssueBillingAddress: 'กรุณาเลือกที่อยู่ในการออกใบกำกับภาษี',
      SelectPaymentMethod: 'กรุณาเลือกช่องทางการชำระเงิน',
      CreateDate: 'วันที่ออก',
      ExprieDate: 'ใช้ได้ถึง',
      DocCode: 'เอกสารเลขที่',
      DocRef: 'เอกสารอ้างอิง',
      SaleRef: 'ผู้ขาย',
      Price: {
        Title: 'ราคา',
        Select: 'เลือกประเภทภาษี',
        Exclude: 'แยกภาษี',
        Include: 'รวมภาษี',
      },
      AddressTax: 'ที่อยู่ออกใบกำกับภาษี',
      AddressShipping: 'ที่อยู่ในการจัดส่งสินค้า',
      AddressReceive: 'ที่อยู่ในการรับสินค้า',
      AddressBilling: 'ที่อยู่ในการจัดส่งใบกำกับภาษี',
      AddressIssueBilling: 'ที่อยู่ในการออกใบกำกับภาษี',
      Tel: 'เบอร์โทร',
      RecieveMoreProduct: {
        Label: 'มีการรับคืนสินค้าเพิ่มหรือไม่',
        Option1: 'ไม่รับคืนสินค้า',
        Option2: 'รับคืนสินค้า',
        Description1: 'จำนวนสต๊อกของสินค้าจะไม่มีการเปลี่ยนแปลง',
        Description2: 'จำนวนสต๊อกของสินค้าจะเพิ่มขึ้น',
        Reason: 'สาเหตุการลดหนี้',
      },
      SendMoreProduct: {
        Label: 'มีการส่งสินค้าเพิ่มหรือไม่',
        Option1: 'ไม่ส่งสินค้าเพิ่ม',
        Option2: 'ส่งสินค้าเพิ่ม',
        Description1: 'จำนวนสต๊อกของสินค้าจะไม่มีการเปลี่ยนแปลง',
        Description2: 'จำนวนสต๊อกของสินค้าจะลดลง',
        Reason: 'สาเหตุการเพิ่มหนี้',
      },
      DescriptionOfReason: 'คำอธิบายอย่างย่อ',
    },
    PromotionForm: {
      PromotionName: 'ชื่อโปรโมชั่น',
      RedeemCode: 'โค้ดโปรโมชั่น',
      Amount: 'จำนวนโค้ด',
      UnlimitedCode: 'ไม่กัดจำนวนโค้ด',
      Discount: 'ส่วนลด',
      PercentDiscount: 'ส่วนลดเป็นเปอร์เซ็นต์',
      NumberCanReuse: 'จำนวนโค้ดที่ลูกค้าสามารถใช้ซ้ำได้',
      UnlimitedReusable: 'ส่วนลดใช้ซ้ำได้ไม่จำกัดจำนวน',
      StartDate: 'วันที่เริ่มต้น',
      EndDate: 'วันที่สิ้นสุด',
      DiscountNoExpire: 'ส่วนลดไม่มีวันหมดอายุ',



    },
    SearchPanel: {
      General: {},
      Document: {
        CustomerName: 'ผู้ติดต่อ',
        DocCode: 'เลขที่เอกสาร',
        CreateDateFrom: 'จากวันที่ออกเอกสาร',
        CreateDateTo: 'ถึงวันที่ออกเอกสาร',
        PriceFrom: 'จากมูลค่า',
        PriceTo: 'ถึงมูลค่า',
      },
    },
    ControlPanel: {
      AreYouSureToCancel: 'คุณแน่ใจไหมที่จะยกเลิกการ',
      AreYouSureTo: 'คุณแน่ใจไหมที่จะ',
      Confirm: 'ยืนยันการ',
      Cancel: 'ยกเลิกการ',
      Create: 'สร้าง',
      Edit: 'แก้ไข',
    },
    Stock: {
      AreYouSureToCancel: 'คุณแน่ใจไหมที่จะยกเลิกการ',
      AreYouSureTo: 'คุณแน่ใจไหมที่จะ',
      Confirm: 'ยืนยันการ',
      Cancel: 'ยกเลิกการ',
      Create: 'สร้าง',
      Edit: 'แก้ไข',
      FG: 'FG',
      FGReserve: 'FG (จอง)',
      FGAvailable: 'FG (สามารถใช้ได้)',
      FOC: 'FOC',
      FOCReserve: 'FOC (จอง)',
      FOCAvailable: 'FOC (สามารถใช้ได้)',
      Reserve: 'จอง',
      Avaliable: 'สามารถใช้ได้',
      CenterIn: 'ถังกลาง (ขาเข้า)',
      CenterOut: 'ถังกลาง (ขาออก)',
      Claim: 'ถังเคลม',
      Clear: 'ถังเคลียร์',
      Balance: 'จำนวนทั้งหมด',
      Document: 'เอกสาร',
      TransferGoods: 'โอนสินค้า',
      StockType: 'ประเภทคลัง',
      Type: 'ประเภท',
      Quantity: 'จำนวน',
      TransferIn: 'ย้ายเข้า',
      TransferOut: 'ย้ายออก',
      Adjusted: 'จัดการ',
      Adjust: 'จัดการ',
      Increase: 'เพิ่ม',
      Decrease: 'ลด',
      Transfer: 'ย้าย',
      From: 'จาก',
      To: 'ไปยัง',
      Before: 'ก่อนปรับ',
      After: 'หลังปรับ',
      Remarks: 'หมายเหตุ',
      Name: 'ชื่อสินค้า',
      Remain: 'คงเหลือ',
      Used: 'ใช้',
      QuantityForAdd: 'ต้องใช้ทั้งหมด',
      AdjustStock: 'จัดการสินค้า',
      AdjustSetStock: 'จัดการชุดสินค้า',
      Purchase: 'ซื้อเข้า',
      Sell: 'ขายออก',
      Return: 'รับคืน',
      CancelReserve: 'ยกเลิกจอง',
      AdjustSet: 'จัดการชุดสินค้า',
      CreditNote: 'ลดหนี้',
      DebitNote: 'เพิ่มหนี้',
    },
    Brand: {
      CancelCreate: 'ยกเลิกการสร้าง',
      Create: 'สร้าง',
      Edit: 'แก้ไข',
      Confirmsave: 'ยืนยันการบันทึก',
      Confirmedit: 'ยืนยันการแก้ไข',
      Confirmcreate: 'ยืนยันการสร้าง',
      Save: 'บันทึก',
      CancelEditTitle: 'ยกเลิกการแก้ไขการจัดการแบรนด์',
      CancelEdit: 'คุณแน่ใจไหมที่จะยกเลิกการแก้ไขการจัดการแบรนด์',
      CancelAddTitle: 'ยกเลิกการเพิ่มการจัดการแบรนด์',
      CancelAdd: 'คุณแน่ใจไหมที่จะยกเลิกการเพิ่มการจัดการแบรนด์',
      ConfirmEditTitle: 'ยืนยันการแก้ไขการจัดการแบรนด์',
      ConfirmEdit: 'คุณแน่ใจไหมที่จะแก้ไขการจัดการแบรนด์',
      ConfirmAddTitle: 'ยืนยันการเพิ่มการจัดการแบรนด์',
      ConfirmAdd: 'คุณแน่ใจไหมที่จะเพิ่มการจัดการแบรนด์',
      Active: 'คุณแน่ใจไหมที่จะ',
      DeleteTitle: 'ยืนยันการลบ',
      Delete: 'คุณแน่ใจไหมที่จะลบ',
    },
    Promation: {
      CancelEditTitle: 'ยกเลิกการแก้ไขการจัดการโค้ดโปรโมชั่น',
      CancelEdit: 'คุณแน่ใจไหมที่จะยกเลิกการแก้ไขการจัดการโค้ดโปรโมชั่น',
      CancelAddTitle: 'ยกเลิกการเพิ่มการจัดการโค้ดโปรโมชั่น',
      CancelAdd: 'คุณแน่ใจไหมที่จะยกเลิกการเพิ่มการจัดการโค้ดโปรโมชั่น',
      ConfirmEditTitle: 'ยืนยันการแก้ไขการจัดการโค้ดโปรโมชั่น',
      ConfirmEdit: 'คุณแน่ใจไหมที่จะแก้ไขการจัดการโค้ดโปรโมชั่น',
      ConfirmAddTitle: 'ยืนยันการเพิ่มการจัดการโค้ดโปรโมชั่น',
      ConfirmAdd: 'คุณแน่ใจไหมที่จะเพิ่มการจัดการโค้ดโปรโมชั่น',
    },
    DocTable: {
      No: 'ลำดับ',
      Item: 'สินค้า/บริการ',
      Detail: 'รายละเอียด',
      Quantity: 'จำนวน',
      Price: 'ราคา',
      Wht: 'หัก ณ ที่จ่าย',
      StockType: 'ประเภทคลังสินค้า',
      Select: 'เลือกสินค้า',
      Discount: 'ส่วนลด',
      PreTaxAmount: 'ยอดก่อนภาษี',
      PreNewAmount: 'มูลค่าที่ถูกต้องก่อนภาษี',
      Button: {
        AddItem: 'เพิ่มรายการ',
        AddDes: 'เพิ่มคำอธิบาย',
        Reset: 'คืนค่า',
      },
    },
    ViewTable: {
      No: '#',
      Code: 'เลขที่เอกสาร',
      CustomerName: 'ชื่อลูกค้า/คู่ค้า',
      Status: 'สถานะ',
      DateFrom: 'วันที่',
      DateTo: 'ถึงวันที่',
      GrandTotal: 'จำนวนเงินที่ต้องชำระ',
      Actions: 'จัดการ',
      NoItemPreview: 'ไม่มีรายการข้อมูล',
      Name: 'ชื่อ',
      UpdateDate: 'วันที่อัปเดต',
      CreateBy: 'ถูกสร้างโดย',
      Role: 'ระดับผู้ใช้งาน',
      CreateDate: 'วันที่สร้าง',
      By: 'โดย',
      Date: 'วันที่',
      UserName: 'ชื่อผู้ใช้งาน',
      Info: 'ข้อมูล',
      InventoryStatus: 'สถานะสินค้า',
      CodeName: 'ชื่อโปรโมชั่น',
      RedeemCode: 'โค้ดโปรโมชั่น',
      StartDate: 'วันที่เริ่ม',
      EndDate: 'วันที่สิ้นสุด',
      Amount: 'จำนวน',
      ReviewBy: 'รีวิวโดย',
      ItemName: 'ชื่อสินค้า',
      Message: 'ข้อความ',
      ReviewDate: 'วันที่รีวิว',
    },
    DocFooter: {
      Description: 'หมายเหตุ',
      Validate: '*บันทึกได้ไม่เกิน 9 บรรทัด',
      TotalDiscount: 'ส่วนลดรวม',
      AmountVatExemp: 'ยอดรวมรายการก่อนภาษี',
      Vat: 'ภาษีมูลค่าเพิ่ม',
      Baht: 'บาท',
      NetAmount: 'ยอดรวมสุทธิ',
      GrandTotal: 'จำนวนเงินที่ต้องชำระ',
      GrandTotalRepaid: 'จำนวนเงินที่ต้องชำระคืน',
      AmountOfReferred: 'มูลค่าตามเอกสารเดิม',
      NewAmount: 'มูลค่าที่ถูกต้อง',
      Not: 'ไม่มี',
      DeliveryPrice: 'ค่าจัดส่ง',
      SelectDiscountFormat: 'เลือกรูปแบบส่วนลด',
    },
    ActionHistory: {
      No: 'เลขที่',
      UsageActivity: 'ประวัติการใช้งาน',
      Created: 'สร้าง',
      Paid: 'ชำระ',
      Attach: ' ใบ',
      Viewed: 'เรียกดู',
      At: 'เมื่อ',
      Update: 'อัปเดต',
      Accept: 'อนุมัติ',
      Void: 'ยกเลิก',
      Cancel: 'ยกเลิก',
      DocumentLog: 'ประวัติเอกสาร',
      IssueTaxInvoice: 'ออกใบกำกับภาษี',
      GotReceipt: 'รับใบเสร็จ',
      VoidGotReceipt: 'ยกเลิกรับใบเสร็จ',
      TaxInvoice: 'เลขใบกำกับภาษี',
      RegisterTaxInvoice: 'รับใบกำกับภาษี',
      VoidTaxInvoice: 'ยกเลิกใบกำกับภาษี',
    },
    ContentDocument: {
      No: 'เลขที่',
      Ref: 'อ้างอิง',
      Print: 'พิมพ์',
      PrintDemo: 'พิมพ์ (ตัวอย่าง)',
      Menu: 'เมนู',
      Edit: 'แก้ไข',
      CustomerInfo: 'ข้อมูลลูกค้า',
      Customer: 'ชื่อลูกค้า',
      Address: 'ที่อยู่',
      IssueDate: 'วันที่ออก',
      DueDate: 'วันที่ใช้ได้ถึง',
      ValidUntil: 'Valid Until',
      PricingAndTaxSetting: 'ข้อมูลราคาและภาษี',
      CreditNoteInfo: 'ข้อมูลการลดหนี้',
      DebitNoteInfo: 'ข้อมูลการเพิ่มหนี้',
      PricingType: 'ประเภทราคา',
      Currency: 'ราคานี้เป็นค่าเงิน',
      ProductService: 'สินค้า/บริการ',
      Description: 'คำอธิบายรายการ',
      Quantity: 'จำนวน',
      Unit: 'หน่วย',
      BeforePrice: 'ราคาเดิม/หน่วย',
      PriceQ: 'ราคา/หน่วย',
      CostQ: 'ต้นทุน/หน่วย',
      DiscQ: 'ส่วนลด/หน่วย',
      CostAmount: 'ต้นทุนรวม',
      PreVatAmount: 'มูลค่าก่อนภาษี',
      DocumentSummary: 'เอกสารที่ออก',
      CurrentDocument: 'เอกสารปัจจุบัน',
      Discount: 'มูลค่าส่วนลดรวม',
      VatItemValue: 'มูลค่ารายการก่อนภาษี',
      VatAmount: 'ภาษีมูลค่าเพิ่มรวม',
      NetTotal: 'มูลค่ารวม',
      Baht: 'บาท',
      RemarkForCustomer: 'หมายเหตุสำหรับลูกค้า',
      HeadOffice: 'สำนักงานใหญ่',
      Branch: 'สาขา',
      Exclude: 'แยกภาษี',
      Include: 'รวมภาษี',
      WithholdingTaxAmount: 'จำนวนเงินที่ถูกหัก ณ ที่จ่าย',
      NetAmountToPay: 'จำนวนเงินที่จะต้องชำระ',
      Difference: 'ผลต่าง',
      TotalAmountOfReferredTaxInvoices: 'ราคามูลค่าตามใบกำกับภาษีเดิม',
      NewAmount: 'มูลค่าที่ถูกต้อง',
      CancelDocument: 'ยกเลิกเอกสาร',
      VoidDocument: 'ยกเลิกเอกสาร',
      CreateTaxInvoice: 'สร้างใบกำกับภาษี',
      VoidGotReceipt: 'ยกเลิกรับใบเสร็จ',
      Amount: 'มูลค่ารวม',
      Remain: 'คงเหลือ',
      Item: 'รายการ',
      ItemForItemSet: 'รายการไอเทมสำหรับชุดไอเทม',
      ItemSetValue: 'มูลค่าชุดไอเทม',
      ValueForItemSet: 'มูลค่าสำหรับชุดไอเทม',
      ValueForItemSetCost: 'มูลค่าต้นทุนสำหรับชุดไอเทม',
      TotalCostPrice: 'มูลค่าต้นทุนรวม',
      TotalProductPrice: 'มูลค่ารายการสินค้ารวม',
      InventoryStatus: 'สถานะการโอนสินค้า',
      TransferCenterInAll: 'โอนเข้าคลังสินค้า',
      SetInventory: 'ตัดจำนวนสินค้า',
      EditRemarks: 'แก้ไขหมายเหตุ',
      PrintShipping: 'พิมพ์ใบส่งพัสดุ',
      TotalBeforePrice: 'มูลค่าราคาเดิมรวม',
      ValueForItemSetBeforePrice: 'มูลค่าราคาเดิมสำหรับชุดไอเทม',
      PaymentMethod: 'ประเภทการชำระเงิน',
      TrackingNumber: 'หมายเลขพัสดุ',
      TransportationChannel: 'ช่องทางขนส่ง'

    },

    SaleOrder: {
      ConfirmOrder: 'ยืนยันใบสั่งขายของคุณ',
      CreateIVFormSO: 'สร้างใบแจ้งหนี้จากใบสั่งขาย',
      Approve: 'อนุมัติ',
      toApprove: 'อนุมัติ',
      this: 'นี้',
      Approvespace: 'อนุมัติ',
      ApproveSale: 'การอนุมัติเพื่อออกใบแจ้งหนี้',
      DetailSale: 'คุณยืนยันที่จะอนุมัติเพื่อออกใบแจ้งหนี้',
    },
    CreditDebit: {
      Create: 'สร้าง',
      EnterYour: 'เลือกข้อมูล',
      SelectDocument: 'เลือกเอกสาร',
      Business: 'บริษัท',
      DocumentNo: 'เลขที่เอกสาร',
      PleaseSelectBusiness: 'โปรดเลือกข้อมูลบริษัท',
      PleaseSelectDocumentNo: 'โปรดเลือกเลขที่เอกสาร',
      SelectBusiness: 'เลือกข้อมูลบริษัท',
      SelectDocumentNo: 'เลือกข้อมูลเลขที่เอกสาร',
      BusinessNotFound: 'ไม่พบข้อมูลบริษัท',
      DocumentNotFound: 'ไม่พบข้อมูลเลขที่เอกสาร',
      DebitNoteInfo: 'ข้อมูลการเพิ่มหนี้',
      CreditNoteInfo: 'ข้อมูลการลดหนี้',
      EnterDebitNoteInfo: 'กรอกข้อมูลการเพิ่มหนี้',
      EnterCreditNoteInfo: 'กรอกข้อมูลการลดหนี้',
      ReasonForIssuingCreditNote: 'กรุณาระบุสาเหตุของการลดหนี้',
      ReasonForIssuingDebitNote: 'กรุณาระบุสาเหตุของการเพิ่มหนี้',
      DescriptionOfReason: 'รายละเอียดอย่างย่อ',
    },
    Payment: {
      Baht: 'บาท',
      ReceivePaymentRecord: 'รับชำระเงิน',
      PaymentRecord: 'ชำระเงิน',
      TheTotalAmountOfReceivableDebt: 'มูลค่าลูกหนี้ที่สามารถชำระได้ทั้งสิ้น',
      PaymentRecieveMethod: 'รับเงินโดย',
      PaymentMethod: 'รับเงินโดย',
      PaymentReceiveAmount: 'จำนวนเงินที่รับชำระ',
      PaymentAmount: 'จำนวนเงินที่ชำระ',
      Remark: 'หมายเหตุ',
      MoneyPaymentRecieve: 'รับชำระด้วยเงินรวม',
      MoneyPayment: 'ชำระด้วยเงินรวม',
      Paid: 'มูลค่าที่ชำระแล้ว',
      TaxWithheld: 'ถูกหัก ณ​ ที่จ่าย',
      TotalPaymentRecieve: 'รับชำระรวมทั้งสิ้น',
      TotalPayment: 'ชำระรวมทั้งสิ้น',
      RemainingAmount: 'ต้องชำระเงินอีก',
      Selectmethod: 'กรุณาเลือกช่องทางการชำระเงิน',
      dueDate: 'วันครบกำหนด',
      getPaidOn: 'ชำระเมื่อ',
      getFullReceivePaid: 'รับชำระครบเมื่อวันที่',
      getFullPaid: 'ชำระครบเมื่อวันที่',
      PaymentDate: 'วันที่ชำระเงิน',
      FullReceivePaid: 'รับชำระเต็มจำนวนแล้ว',
      FullPaid: 'ชำระเต็มจำนวนแล้ว',
      paymentMethod: 'ช่องทางชำระเงิน',
      payWithDoc: 'ตัดชำระกับเอกสาร',
      stillPay: 'ยังชำระไม่ครบจำนวน',
      thisPrice: 'ราคานี้เป็นค่าเงิน',
      NoCPay: 'ชำระเงินครั้งที่',
      reCPay: 'รับชำระเงินครั้งที่',
      cutOPay: 'ตัดชำระกับเอกสารครั้งที่',
      Cash: 'เงินสด',
      cash: 'เงินสด',
      Transfer: 'โอนเงิน',
      CreditDebit: 'บัตรเครดิต/เดบิต',
      Cheque: 'เช็ค',
      Other: 'อื่นๆ',
      Modal: {
        AccountNo: 'บัญชีเลขที่ : ',
        CreateBankAccount: 'สร้างบัญชีธนาคาร',
        EditBankAccount: 'แก้ไขบัญชีธนาคาร',
        BankAccount: {
          Label: 'บัญชีรับเงิน',
          Placeholder: 'เลือกบัญชีรับเงิน',
          AddBankAccount: 'เพิ่มบัญชีธนาคาร',
          Error: 'กรุณาเลือกบัญชีรับเงิน',
          CancelCreateTitle: 'ยกเลิกการสร้างบัญชีธนาคาร',
          CancelCreate: 'คุณแน่ใจหรือไม่ที่ต้องการยกเลิกการสร้างบัญชีธนาคาร?',
          ConfirmCreateTitle: 'ยืนยันการสร้างบัญชีธนาคาร',
          ConfirmCreate: 'คุณแน่ใจหรือไม่ที่ต้องการสร้างบัญชีธนาคาร?',
          CancelEditTitle: 'ยกเลิกการแก้ไขบัญชีธนาคาร',
          CancelEdit: 'คุณแน่ใจหรือไม่ที่ต้องการยกเลิกการแก้ไขบัญชีธนาคาร?',
          ConfirmEditTitle: 'ยืนยันการแก้ไขบัญชีธนาคาร',
          ConfirmEdit: 'คุณแน่ใจหรือไม่ที่ต้องการแก้ไขบัญชีธนาคาร?',
          Bank: {
            Label: 'ธนาคาร',
            Placeholder: 'เลือกธนาคาร',
            Error: 'กรุณาเลือกธนาคาร',
          },
          AccountNumber: {
            Label: 'เลขที่บัญชี',
            Placeholder: 'กรอกเลขที่บัญชี',
            Error: 'กรุณากรอกเลขที่บัญชี',
          },
          BankBranch: {
            Label: 'สาขาธนาคาร',
            Placeholder: 'กรอกสาขาธนาคาร',
            Error: 'กรุณากรอกสาขาธนาคาร',
          },
          AccountName: {
            Label: 'ชื่อบัญชี',
            Placeholder: 'กรอกชื่อบัญชี',
            Error: 'กรุณากรอกชื่อบัญชี',
          },
          BankAccountType: {
            Label: 'ประเภทบัญชี',
            Saving: 'ออมทรัพย์',
            FixedDeposit: 'กระแสรายวัน',
            Current: 'ฝากประจำ',
          },
        },
      },
    },
    DocumentPaper: {
      Print: 'พิมพ์',
      FixedPage: 'ปรับหน้า',
      HideObject: 'แสดง/ซ่อน',
      PageNumber: 'หมายเลขหน้า',
      Manuscript: 'สถานะ',
      Watermark: 'ลายน้ำ',
      TableColor: 'สีตาราง',
      Discount: 'ส่วนลด',
      WithheldTax: 'ภาษีหัก ณ ที่จ่าย',
      ClearAll: 'เคลียร์',
      BGColor: 'สีพื้นหลัง',
      IsOriginal: 'เอกสารต้นฉบับ',
      ShippingFee: 'ค่าจัดส่ง',
    },
    FormName: {
      QuotationForm: 'ฟอร์มใบเสนอราคา',
      QuotationDetail: 'รายละเอียดใบเสนอราคา',
      SaleOrderForm: 'ฟอร์มใบสั่งขาย',
      SaleOrderDetail: 'รายละเอียดใบสั่งขาย',
      InvoiceForm: 'ฟอร์มใบแจ้งหนี้',
      InvoiceDetail: 'รายละเอียดใบแจ้งหนี้',
      TaxInvoiceForm: 'ฟอร์มใบกำกับภาษี',
      TaxInvoiceDetail: 'รายละเอียดใบกำกับภาษี',
      ReceiptDetail: 'รายละเอียดใบเสร็จรับเงิน',
      DebitNoteForm: 'ฟอร์มใบเพิ่มหนี้',
      DebitNoteDetail: 'รายละเอียดใบเพิ่มหนี้',
      CreditNoteForm: 'ฟอร์มใบลดหนี้',
      CreditNoteDetail: 'รายละเอียดใบลดหนี้',

      PurchaseRequestForm: 'ฟอร์มใบขอสั่งซื้อ',
      PurchaseRequestDetail: 'รายละเอียดใบขอสั่งซื้อ',

      PurchaseOrder: 'ฟอร์มใบสั่งซื้อ',
      PurchaseOrderDetail: 'รายละเอียดใบสั่งซื้อ',

      GoodsReceiveForm: 'ฟอร์มใบรับสินค้า',
      GoodsReceiveDetail: 'รายละเอียดใบรับสินค้า',

      ReviewDetail: 'รายละเอียดรีวิว',
    },
    Quotation: {
      ConfirmOrder: 'ยืนยันใบเสนอราคาของคุณ',
      CancelCreateTitle: 'ยกเลิกการสร้างใบเสนอราคา',
      CancelCreate: 'คุณแน่ใจไหมที่จะยกเลิกการสร้างใบเสนอราคานี้',
      CancelEditTitle: 'ยกเลิกการแก้ไขใบเสนอราคา',
      CancelEdit: 'คุณแน่ใจไหมที่จะยกเลิกการแก้ไขใบเสนอราคานี้',
      SaveDraftTitle: 'บันทึกร่างใบเสนอราคา',
      SaveDraft: 'คุณแน่ใจไหมที่จะบันทึกร่างใบเสนอราคานี้',
      EditTitle: 'แก้ไขใบเสนอราคา',
      Edit: 'คุณแน่ใจไหมที่จะแก้ไขใบเสนอราคานี้',
      CreateTitle: 'สร้างใบเสนอราคา',
      Create: 'คุณแน่ใจไหมที่จะสร้างใบเสนอราคานี้',
      ApproveSale: 'การอนุมัติเพื่อออกใบสั่งขาย',
      DetailSale: 'คุณยืนยันที่จะอนุมัติเพื่อออกใบสั่งขาย ?',
    },
    ManageItem: {
      CancelCreateTitle: 'ยกเลิกการสร้างสินค้า',
      CancelCreate: 'คุณแน่ใจไหมที่จะยกเลิกการสร้างสินค้านี้',
      CancelEditTitle: 'ยกเลิกการแก้ไขสินค้า',
      CancelEdit: 'คุณแน่ใจไหมที่จะยกเลิกการแก้ไขสินค้านี้',
      ConfirmCreateTitle: 'ยืนยันการสร้างสินค้า',
      ConfirmCreate: 'คุณแน่ใจไหมที่จะสร้างสินค้านี้',
      ConfirmEditTitle: 'ยืนยันการแก้ไขสินค้า',
      ConfirmEdit: 'คุณแน่ใจไหมที่จะแก้ไขสินค้านี้',
    },
    ManageItemSet: {
      CancelCreateTitle: 'ยกเลิกการสร้างชุดสินค้า',
      CancelCreate: 'คุณแน่ใจไหมที่จะยกเลิกการสร้างชุดสินค้านี้',
      CancelEditTitle: 'ยกเลิกการแก้ไขชุดสินค้า',
      CancelEdit: 'คุณแน่ใจไหมที่จะยกเลิกการแก้ไขชุดสินค้านี้',
      ConfirmCreateTitle: 'ยืนยันการสร้างชุดสินค้า',
      ConfirmCreate: 'คุณแน่ใจไหมที่จะสร้างชุดสินค้านี้',
      ConfirmEditTitle: 'ยืนยันการแก้ไขชุดสินค้า',
      ConfirmEdit: 'คุณแน่ใจไหมที่จะแก้ไขชุดสินค้านี้',
    },
    ManageGroup: {
      CancelCreateTitle: 'ยกเลิกการสร้างกลุ่ม',
      CancelCreate: 'คุณแน่ใจไหมที่จะยกเลิกการสร้างกลุ่มนี้',
      CancelEditTitle: 'ยกเลิกการแก้ไขกลุ่ม',
      CancelEdit: 'คุณแน่ใจไหมที่จะยกเลิกการแก้ไขกลุ่มนี้',
      ConfirmCreateTitle: 'ยืนยันการสร้างกลุ่ม',
      ConfirmCreate: 'คุณแน่ใจไหมที่จะสร้างกลุ่มนี้',
      ConfirmEditTitle: 'ยืนยันการแก้ไขกลุ่ม',
      ConfirmEdit: 'คุณแน่ใจไหมที่จะแก้ไขกลุ่มนี้',
    },
    ManageCategory: {
      CancelCreateTitle: 'ยกเลิกการสร้างหมวดหมู่',
      CancelCreate: 'คุณแน่ใจไหมที่จะยกเลิกการสร้างหมวดหมู่นี้',
      CancelEditTitle: 'ยกเลิกการแก้ไขหมวดหมู่',
      CancelEdit: 'คุณแน่ใจไหมที่จะยกเลิกการแก้ไขหมวดหมู่นี้',
      ConfirmCreateTitle: 'ยืนยันการสร้างหมวดหมู่',
      ConfirmCreate: 'คุณแน่ใจไหมที่จะสร้างหมวดหมู่นี้',
      ConfirmEditTitle: 'ยืนยันการแก้ไขหมวดหมู่',
      ConfirmEdit: 'คุณแน่ใจไหมที่จะแก้ไขหมวดหมู่นี้',
    },
    ManageSubCategory: {
      CancelCreateTitle: 'ยกเลิกการสร้างหมวดหมู่ย่อย',
      CancelCreate: 'คุณแน่ใจไหมที่จะยกเลิกการสร้างหมวดหมู่ย่อยนี้',
      CancelEditTitle: 'ยกเลิกการแก้ไขหมวดหมู่ย่อย',
      CancelEdit: 'คุณแน่ใจไหมที่จะยกเลิกการแก้ไขหมวดหมู่ย่อยนี้',
      ConfirmCreateTitle: 'ยืนยันการสร้างหมวดหมู่ย่อย',
      ConfirmCreate: 'คุณแน่ใจไหมที่จะสร้างหมวดหมู่ย่อยนี้',
      ConfirmEditTitle: 'ยืนยันการแก้ไขหมวดหมู่ย่อย',
      ConfirmEdit: 'คุณแน่ใจไหมที่จะแก้ไขหมวดหมู่ย่อยนี้',
    },
    ManageClass: {
      CancelCreateTitle: 'ยกเลิกการสร้างคลาส',
      CancelCreate: 'คุณแน่ใจไหมที่จะยกเลิกการสร้างคลาสนี้',
      CancelEditTitle: 'ยกเลิกการแก้ไขคลาส',
      CancelEdit: 'คุณแน่ใจไหมที่จะยกเลิกการแก้ไขคลาสนี้',
      ConfirmCreateTitle: 'ยืนยันการสร้างคลาส',
      ConfirmCreate: 'คุณแน่ใจไหมที่จะสร้างคลาสนี้',
      ConfirmEditTitle: 'ยืนยันการแก้ไขคลาส',
      ConfirmEdit: 'คุณแน่ใจไหมที่จะแก้ไขคลาสนี้',
    },
    ManageSubClass: {
      CancelCreateTitle: 'ยกเลิกการสร้างคลาสย่อย',
      CancelCreate: 'คุณแน่ใจไหมที่จะยกเลิกการสร้างคลาสย่อยนี้',
      CancelEditTitle: 'ยกเลิกการแก้ไขคลาสย่อย',
      CancelEdit: 'คุณแน่ใจไหมที่จะยกเลิกการแก้ไขคลาสย่อยนี้',
      ConfirmCreateTitle: 'ยืนยันการสร้างคลาสย่อย',
      ConfirmCreate: 'คุณแน่ใจไหมที่จะสร้างคลาสย่อยนี้',
      ConfirmEditTitle: 'ยืนยันการแก้ไขคลาสย่อย',
      ConfirmEdit: 'คุณแน่ใจไหมที่จะแก้ไขคลาสย่อยนี้',
    },
    ManageRole: {
      CancelCreateTitle: 'ยกเลิกการสร้างระดับผู้ใช้งาน',
      CancelCreate: 'คุณแน่ใจไหมที่จะยกเลิกการสร้างระดับผู้ใช้งานนี้',
      CancelEditTitle: 'ยกเลิกการแก้ไขระดับผู้ใช้งาน',
      CancelEdit: 'คุณแน่ใจไหมที่จะยกเลิกการแก้ไขระดับผู้ใช้งานนี้',
      ConfirmCreateTitle: 'ยืนยันการสร้างระดับผู้ใช้งาน',
      ConfirmCreate: 'คุณแน่ใจไหมที่จะสร้างระดับผู้ใช้งานนี้',
      ConfirmEditTitle: 'ยืนยันการแก้ไขระดับผู้ใช้งาน',
      ConfirmEdit: 'คุณแน่ใจไหมที่จะแก้ไขระดับผู้ใช้งานนี้',
      DeleteTitle: 'ยืนยันการลบระดับผู้ใช้งาน',
      Delete: 'คุณแน่ใจไหมที่จะลบระดับผู้ใช้งานนี้',
      RoleName: 'ชื่อระดับผู้ใช้งาน',
      Active: 'คุณแน่ใจไหมที่จะ',
    },
    ManageUser: {
      CancelCreateTitle: 'ยกเลิกการสร้างผู้ใช้งาน',
      CancelCreate: 'คุณแน่ใจไหมที่จะยกเลิกการสร้างผู้ใช้งานนี้',
      CancelEditTitle: 'ยกเลิกการแก้ไขผู้ใช้งาน',
      CancelEdit: 'คุณแน่ใจไหมที่จะยกเลิกการแก้ไขผู้ใช้งานนี้',
      ConfirmCreateTitle: 'ยืนยันการสร้างผู้ใช้งาน',
      ConfirmCreate: 'คุณแน่ใจไหมที่จะสร้างผู้ใช้งานนี้',
      ConfirmEditTitle: 'ยืนยันการแก้ไขผู้ใช้งาน',
      ConfirmEdit: 'คุณแน่ใจไหมที่จะแก้ไขผู้ใช้งานนี้',
      DeleteTitle: 'ยืนยันการลบผู้ใช้งาน',
      Delete: 'คุณแน่ใจไหมที่จะลบผู้ใช้งานนี้',
      ResetTitle: 'ยืนยันการรีเซ็ตรหัสผ่าน',
      Reset: 'คุณแน่ใจไหมที่จะรีเซ็ตรหัสผ่านผู้ใช้งานนี้',
      Active: 'คุณแน่ใจไหมที่จะ',
    },
    CreditNote: {
      ConfirmCreateTitle: 'ยืนยันการสร้างใบลดหนี้',
      ConfirmCreate: 'คุณแน่ใจไหมที่จะสร้างใบลดหนี้นี้',
    },
    DebitNote: {
      ConfirmCreateTitle: 'ยืนยันการสร้างใบเพิ่มหนี้',
      ConfirmCreate: 'คุณแน่ใจไหมที่จะสร้างใบเพิ่มหนี้นี้',
    },
    GoodsReceive: {
      GetReceipt: 'รับใบเสร็จ',
      YouCanGetTheReceipt: 'คุณสามารถรับใบเสร็จได้',
      VoidGetReceipt: 'ยกเลิกรับใบเสร็จ',
    },
    Files: {
      NoFileChosen: 'ยังไม่ได้เลือกไฟล์',
      ChooseFile: 'เลือกไฟล์',
    },
    Informative: {
      Description: 'คำอธิบาย',
      ActiveStatus: 'เปิดใช้งาน',
      Home: {
        BannerImage: 'รูปภาพแบนเนอร์',
        BannerImageDescription: 'ความละเอียด 1920px*730px',
        BannerVideo: 'วิดีโอแบนเนอร์',
        BannerVideoDescription: 'ความละเอียด 1920px*730px',
        UseBannerAsVideo: 'ใช้แบนเนอร์เป็นวิดีโอ',
        WhoWeAreQuote: {
          Label: 'สโลแกนว่าเราคือใคร',
          Description: 'สโลแกน',
        },
        WhoWeAreDescription: {
          Label: 'คำอธิบายว่าเราคือใคร',
          Description: 'คำอธิบาย',
        },
        WhoWeAreImage1: {
          Label: 'รูปภาพว่าเราคือใคร 1',
          Description: 'ความละเอียด: 260px*260px',
        },
        WhoWeAreImage2: {
          Label: 'รูปภาพว่าเราคือใคร 2',
          Description: 'ความละเอียด: 440px*640px',
        },
        WhoWeAreImage3: {
          Label: 'รูปภาพว่าเราคือใคร 3',
          Description: 'ความละเอียด: 480px*300px',
        },
        NewProductSection: 'คำอธิบายส่วนสินค้าใหม่',
        NewProductBG: 'ภาพพื้นหลังสินค้าใหม่',
        NewProductBGDescription: 'ความละเอียด: 940px*750px',
        NewProductWordingButton: 'ข้อความปุ่มสินค้าใหม่',
        WordingOnButton: 'ข้อความบนปุ่ม',
        NewProductURL: 'URL สินค้าใหม่',
        NewProductURLDescription: 'URL สำหรับ redirect',
        OurTechnologyQuote: 'สโลแกนเทคโนโลยีของเรา',
        OurTechnologyVideo: 'วีดีโอเทคโนโลยีของเรา',
        OurTechnologyVideoDescription: 'ความละเอียด: 1280px*600px',
        MemberPromotionImage1: 'รูปภาพโปรโมชันสำหรับสมาชิก 1',
        MemberPromotionImage1Description: 'ความละเอียด: 660px*447px',
        MemberPromotionImage2: 'รูปภาพโปรโมชันสำหรับสมาชิก 2',
        MemberPromotionImage2Description: 'ความละเอียด: 660px*447px',
      },
      About: {
        BannerTitle: 'ชื่อแบนเนอร์',
        MainWordingOnBanner: 'ข้อความหลักบนแบนเนอร์',
        BannerSubTitle: 'ชื่อแบนเนอร์รอง',
        SubWordingOnBanner: 'ข้อความรองบนแบนเนอร์',
        BannerImage: 'รูปภาพแบนเนอร์',
        BannerImageDescription:
          'แบนเนอร์รูปภาพพื้นหลัง, ความละเอียด 1920px*450px',
        QuoteTitle1: 'ชื่อสโลแกน (Quote) 1',
        QuoteDescription: 'คำอธิบายสโลแกน (Quote)',
        QuoteImage1: 'รูปภาพสโลแกน (Quote) 1',
        QuoteImageDescription: 'ความละเอียด 1280px*600px',
        AboutDescription1: 'รายละเอียดเกี่ยวกับ (About) 1',
        DescriptionOnLeftSide: 'คำอธิบายทางด้านซ้าย',
        AboutDescription2: 'รายละเอียดเกี่ยวกับ (About) 2',
        DescriptionOnRightSide: 'คำอธิบายทางด้านขวา',
        AboutImage1: 'รูปภาพเกี่ยวกับ (About) 1',
        AboutImage1Description: 'รูปภาพทางด้านซ้าย, ความละเอียด 220px*220px',
        AboutImage2: 'รูปภาพเกี่ยวกับ (About) 2',
        AboutImage2Description: 'รูปภาพตรงกลาง, ความละเอียด 400px*600px',
        AboutImage3: 'รูปภาพเกี่ยวกับ (About) 3',
        AboutImage3Description: 'รูปภาพทางด้านขวา, ความละเอียด 510px*300px',
        BannerTitle2: 'ชื่อแบนเนอร์ 2',
        BannerImage2: 'รูปภาพแบนเนอร์ 2',
        QuoteTitle2: 'ชื่อสโลแกน (Quote) 2',
        QuoteVideo: 'วีดีโอสโลแกน (Quote)',
      },
      Contact: {
        BannerTitle: 'ชื่อแบนเนอร์',
        BannerTitleDescription: 'ข้อความตรงกลางบนแบนเนอร์',
        BannerImage: 'รูปภาพแบนเนอร์',
        BannerImageDescription: 'ความละเอียด 1920px*450px',
        VisitUs: 'เยี่ยมชม',
        VisitUsDescription: 'คำอธิบายที่ตั้งสำนักงาน',
        CallUs: 'ติดต่อเรา',
        CallUsDescription: 'คำอธิบายของที่ตั้งร้านค้า',
        EmailUs: 'อีเมลของเรา',
        EmailUsDescription: 'คำอธิบายของการติดต่อเราโดยตรง',
        ContactFormImage1: 'รูปภาพฟอร์มติดต่อ 1',
        ContactFormImage1Description: 'ความละเอียด 510px*587px',
        ContactFormImage2: 'รูปภาพฟอร์มติดต่อ 2',
        ContactFormImage2Description: 'ความละเอียด 330px*387px',
      },
      NewsEvent: {
        NewsBanner: {
          Label: 'แบนเนอร์ข่าวสารและอีเวนต์',
          Description: 'ภาพพื้นหลังแบนเนอร์',
        },
        NewsTitle: {
          Label: 'ชื่อข่าวสารและอีเวนต์',
          Description: 'ชื่อแบนเนอร์',
        },
        ConsumerReviewDate: {
          Label: 'วันที่รีวิวจากผู้ใช้',
          Description: 'Date of Consumer Review',
        },
        ConsumerReviewTitle: {
          Label: 'หัวข้อรีวิวจากผู้ใช้',
          Description: 'Title of Consumer Review',
        },
        ConsumerReviewImage1: {
          Label: 'รูปภาพรีวิวจากผู้ใช้ 1',
          Description: '1st Image of Consumer Review',
        },
        Detail: {
          Label: 'รายละเอียด',
          Description: 'รายละเอียดการรีวิวจากผู้ใช้',
        },
        ConsumerReviewImage2: {
          Label: 'รูปภาพรีวิวจากผู้ใช้ 2',
          Description: '2nd Image of Consumer Review',
        },
        ReviewerName: {
          Label: 'ชื่อผู้รีวิว',
          Description: 'Name of Reviewer',
        },
        URLButton: {
          Label: 'URL ปุ่ม',
          Description: 'URL สำหรับ redirect',
        },
        SocialMedia: {
          Label: 'โซเชียลมีเดีย',
          Description: 'เลือกโซเชียลมีเดีย',
        },
        CompanyVideo: {
          ContentDate: {
            Label: 'วันที่คอนเทนต์',
            Description: 'วันที่วีดีโอของบริษัท',
          },
          VideoTitle: {
            Label: 'ชื่อวีดีโอ',
            Description: 'ชื่อวีดีโอของบริษัท',
          },
          VideoCoverImage: {
            Label: 'รูปภาพปกวีดีโอ',
            Description: 'รูปภาพปกวีดีโอของบริษัท',
          },
          Subtitle: {
            Label: 'รายละเอียด',
            Description: 'รายละเอียดวีดีโอของบริษัท',
          },
          RepoveLive: {
            Label: 'Repove Live',
            Description: 'เลือกเพื่อถ่ายทอดสด',
          },
          LiveDate: {
            Label: 'วันที่ถ่ายทอดสด',
            Description: 'วันที่ของการถ่ายทอดสด',
          },
          MediaType: {
            Label: 'ประเภทสื่อ',
            Description: 'วีดีโอหรือการถ่ายทอดสด',
          },
        },
      },
      SignIn: {
        BannerImage: {
          1: 'รูปภาพแบนเนอร์ 1',
          2: 'รูปภาพแบนเนอร์ 2',
        },
        BannerMobileImage: {
          1: 'รูปภาพแบนเนอร์ (จอมือถือ) 1',
          2: 'รูปภาพแบนเนอร์ (จอมือถือ) 2',
        },
        Description: 'ความละเอียด 648px*785px',
      },
      Product: {
        BannerImage: {
          1: 'รูปภาพแบนเนอร์ 1',
          2: 'รูปภาพแบนเนอร์ 2',
        },
        BannerTitle: {
          Label: 'ชื่อแบนเนอร์',
          Description: 'ข้อความบนรูปภาพแบนเนอร์ 2',
        },
        Description: 'ความละเอียด 1920px*450px',
      },
      PrivacyPolicy: {
        Date: {
          Label: 'วันที่',
          Description: 'วันที่นโยบายความเป็นส่วนตัว',
        },
        Title: {
          Label: 'หัวข้อ',
          Description: 'หัวข้อนโยบายความเป็นส่วนตัว',
        },
        Description: {
          Label: 'คำอธิบาย',
          Description: 'คำอธิบายนโยบายความเป็นส่วนตัว',
        },
      },
      CookiePolicy: {
        Date: {
          Label: 'วันที่',
          Description: 'วันที่ข้อตกลงการใช้คุกกี้',
        },
        Title: {
          Label: 'หัวข้อ',
          Description: 'หัวข้อข้อตกลงการใช้คุกกี้',
        },
        Description: {
          Label: 'คำอธิบาย',
          Description: 'คำอธิบายข้อตกลงการใช้คุกกี้',
        },
      },
      TermsCondition: {
        Date: {
          Label: 'วันที่',
          Description: 'วันที่เงื่อนไขและข้อตกลง',
        },
        Title: {
          Label: 'หัวข้อ',
          Description: 'หัวข้อเงื่อนไขและข้อตกลง',
        },
        Description: {
          Label: 'คำอธิบาย',
          Description: 'คำอธิบายเงื่อนไขและข้อตกลง',
        },
      },
      SellerManagementRegulation: {
        Date: {
          Label: 'วันที่',
          Description: 'วันที่ Seller Management Regulation',
        },
        Title: {
          Label: 'หัวข้อ',
          Description: 'หัวข้อ Seller Management Regulation',
        },
        Description: {
          Label: 'คำอธิบาย',
          Description: 'คำอธิบาย Seller Management Regulation',
        },
      },
    },
    CmsSection: {
      Product: {
        HeadtoToeGentleBath:'เฮดทูโทเจนเทิลบาธ ',
        GentlyNourishingLotion:'เจนท์ลี่นูริชชิ่งโลชั่น',
        ProductBottlePicture: {
          Label: 'รูปขวดสินค้า',
          Description: 'ความละเอียด 407px*498x',
        },
        ImageSlidePreview: {
          Label: 'สไลด์ preview สินค้า',
          Description: 'ความละเอียด 398px*657px',
        },
        ProductNameTH:{
          Label: 'ชื่อสินค้าภาษาไทย',
        },
        ProductNameEN:{
          Label: 'ชื่อสินค้าภาษาอังกฤษ',
        },
        ProductDescription1:{
          Label:'คำอธิบายสินค้า 1',
          Description:'คำอธิบายสินค้าใต้ชื่อสินค้า 1',
        },
        ProductDescription2:{
          Label:'คำอธิบายสินค้า2',
          Description:'คำอธิบายสินค้าใต้ชื่อสินค้า 2',
        },
        ProductSizeImage1: {
          Label: 'รูปขนาดสินค้า 1',
          Description: 'ความละเอียด 50px*85px'
        },
        ProductSizeDetail1: {
          Label: 'รายละเอียดขนาดสินค้า 1'
        },
        ProductSizeImage2: {
          Label: 'รูปขนาดสินค้า 2',
          Description: 'ความละเอียด 50px*85px'
        },
        ProductSizeDetail2: {
          Label: 'รายละเอียดขนาดสินค้า 2'
        },
        ProductSizeImage3: {
          Label: 'รูปขนาดสินค้า 3',
          Description: 'ความละเอียด 50px*85px'
        },
        ProductSizeDetail3: {
          Label: 'รายละเอียดขนาดสินค้า 3'
        },
        ShopeeLink: {
          Label: 'ลิงค์ Shopee'
        },
        LazadaLink: {
          Label: 'ลิงค์ Lazada'
        },
        BrandName: {
          Label: 'ชื่อแบรนด์'
        },
        ProductName: {
          Label: 'ชื่อสินค้า'
        },
        BottleImagePc: {
          Label: 'ตัวอย่างรูปขวดสินค้าและพื้นหลัง (Desktop Size)',
          Description: 'ความละเอียด 1400px*760px'
        },
        BottleImageMb: {
          Label: 'ตัวอย่างรูปขวดสินค้าและพื้นหลัง (Mobile Size)',
          Description: 'ความละเอียด 490px*330px'
        },
        WaterDropImage: {
          Label: 'ภาพพื้นหลังหยดน้ำ',
          Description: 'ความละเอียด 638px*466px'
        },
        IconImage1: {
          Label: 'รูป icon 1',
          Description: 'ความละเอียด 150px*150px'
        },
        IconText1: {
          Label: 'ข้อความใต้รูปไอคอน 1'
        },
        IconImage2: {
          Label: 'รูป icon 2',
          Description: 'ความละเอียด 150px*150px'
        },
        IconText2: {
          Label: 'ข้อความใต้รูปไอคอน 2'
        },
        IconImage3: {
          Label: 'รูป icon 3',
          Description: 'ความละเอียด 150px*150px'
        },
        IconText3: {
          Label: 'ข้อความใต้รูปไอคอน 3'
        },
        IconImage4: {
          Label: 'รูป icon 4',
          Description: 'ความละเอียด 150px*150px'
        },
        IconText4: {
          Label: 'ข้อความใต้รูปไอคอน 4'
        },
        IconImage5: {
          Label: 'รูป icon 5',
          Description: 'ความละเอียด 150px*150px'
        },
        IconText5: {
          Label: 'ข้อความใต้รูปไอคอน 5'
        },
        IconImage6: {
          Label: 'รูป icon 6',
          Description: 'ความละเอียด 150px*150px'
        },
        IconText6: {
          Label: 'ข้อความใต้รูปไอคอน 6'
        },
        BannerProductPc: {
          Label: 'แบนเนอร์สินค้า (Desktop Size)',
          Description: 'ความละเอียด 1920px*1080px'
        },
        BannerProductMb: {
          Label: 'แบนเนอร์สินค้า (Mobile Size)',
          Description: 'ความละเอียด 828*630px;'
        },
        ProductPresenImg: {
          Label: 'ภาพประกอบการนำเสนอสินค้า',
          Description: 'ความละเอียด 687px*672px'
        },
        BottleImage2: {
          Label: 'ตัวอย่างรูปขวดสินค้า',
          Description: 'ความละเอียด 387px*630px'
        },
        TopicExtract: {
          Label: 'หัวข้อสารสกัด'
        },
        TopicExtractDetail: {
          Label: 'รายละเอียดหัวข้อสารสกัด'
        },
        ProductSafetyTopics: {
          Label: 'หัวข้อความปลอดภัยของสินค้า'
        },
        ProductSafetyTopicsDetail: {
          Label: 'รายละเอียดความปลอดภัยของสินค้า'
        },
        IngredientsImage: {
          Label: 'รูปวัตถุดิบ',
          Description: 'ความละเอียด 265px*272px'
        },
        ExtractsName: {
          Label: 'ชื่อสารสกัด'
        },
        ExtractsNameDetail: {
          Label: 'คำอธิบายสารสกัด'
        },
        CompoundImg : {
          Label: 'ภาพสารประกอบ',
          Description: 'ความละเอียด 208px*215px'
        }
      },
    },
  },
};
