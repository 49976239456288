import { Component, OnInit } from '@angular/core';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import { Compound1Service } from './compound1.service';

@Component({
  selector: 'app-compound1',
  templateUrl: './compound1.component.html',
  styleUrls: ['./compound1.component.scss']
})
export class Compound1Component implements OnInit {

  config!: ICMSConfig;
  keyName: string;

  constructor(private _Compound1Service : Compound1Service ) {
    this.config = this._Compound1Service.config;
    this.keyName = this._Compound1Service.keyName
   }

  ngOnInit(): void {
  }

}
