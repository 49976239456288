import { FormType } from 'app/main/config/CMSInterface';
import { Validators } from '@angular/forms';

export default {
  productBottlePicture: {
    type: FormType.SingleImage,
    validator: [Validators.required],
    label: 'Product Bottle Picture',
    description: 'Resolution',
    labelTranslate: 'CmsSection.Product.ProductBottlePicture.Label',
    descriptionTranslate: 'CmsSection.Product.ProductBottlePicture.Description',
  },
  ImageSlidePreview: {
    type: FormType.MultipleImage,
    validator: [Validators.required],
    label: 'Slide Multiple Product',
    description: 'Resolution 116px*105px',
    labelTranslate: 'CmsSection.Product.ImageSlidePreview.Label',
    descriptionTranslate: 'CmsSection.Product.ImageSlidePreview.Description',
    limit: 5,
    minimum: 3,
  },
  nameTH: {
    type: FormType.Text,
    validator: [Validators.required],
    label: 'Product Name (TH)',
    labelTranslate: 'CmsSection.Product.ProductNameTH.Label'
  },
  nameEN: {
    type: FormType.Text,
    validator: [Validators.required],
    label: 'Product Name (EN)',
    labelTranslate: 'CmsSection.Product.ProductNameEN.Label'
  },
  description: {
    type: FormType.Textarea,
    validator: [Validators.required],
    label: 'Product description 1',
    description: 'Product description under product name 1',
    labelTranslate: 'CmsSection.Product.ProductDescription1.Label',
    descriptionTranslate: 'CmsSection.Product.ProductDescription1.Description'
  },
  productSizeImage1: {
    type: FormType.SingleImage,
    validator: [Validators.required],
    label: 'Product Size Image 1',
    description: 'Resolution 50px*85px',
    labelTranslate: 'CmsSection.Product.ProductSizeImage1.Label',
    descriptionTranslate: 'CmsSection.Product.ProductSizeImage1.Description'
  },
  productSize1: {
    type: FormType.Text,
    validator: [Validators.required],
    label: 'Product Size Detail 1',
    labelTranslate: 'CmsSection.Product.ProductSizeDetail1.Label'
  },
  productSizeImage2: {
    type: FormType.SingleImage,
    validator: [Validators.required],
    label: 'Product Size Image 2',
    description: 'Resolution 50px*85px',
    labelTranslate: 'CmsSection.Product.ProductSizeImage2.Label',
    descriptionTranslate: 'CmsSection.Product.ProductSizeImage2.Description'
  },
  productSize2: {
    type: FormType.Text,
    validator: [Validators.required],
    label: 'Product Size Detail 2',
    labelTranslate: 'CmsSection.Product.ProductSizeDetail2.Label'
  },
  productSizeImage3: {
    type: FormType.SingleImage,
    validator: [Validators.required],
    label: 'Product Size Image 3',
    description: 'Resolution 50px*85px',
    labelTranslate: 'CmsSection.Product.ProductSizeImage3.Label',
    descriptionTranslate: 'CmsSection.Product.ProductSizeImage3.Description'
  },
  productSize3: {
    type: FormType.Text,
    validator: [Validators.required],
    label: 'Product Size Detail 3',
    labelTranslate: 'CmsSection.Product.ProductSizeDetail3.Label'
  },
  linkBtnUrl1: {
    type: FormType.Text,
    validator: [Validators.required],
    label: 'Shopee Link',
    labelTranslate: 'CmsSection.Product.ShopeeLink.Label'
  },
  linkBtnUrl2: {
    type: FormType.Text,
    validator: [Validators.required],
    label: 'Lazada Link',
    labelTranslate: 'CmsSection.Product.LazadaLink.Label'
  },
  dividerSection1: {
    type: FormType.Divider
  },
  //section 2 ===================================
  title1: {
    type: FormType.Text,
    validator: [Validators.required],
    label: 'Brand Name',
    labelTranslate: 'CmsSection.Product.BrandName.Label'
  },
  subTitle1: {
    type: FormType.Text,
    validator: [Validators.required],
    label: 'Product Name',
    labelTranslate: 'CmsSection.Product.ProductName.Label'
  },
  descrtiption1: {
    type: FormType.Textarea,
    validator: [Validators.required],
    label: 'Product description 2',
    description: 'Product description under product name 2',
    labelTranslate: 'CmsSection.Product.ProductDescription2.Label',
    descriptionTranslate: 'CmsSection.Product.ProductDescription2.Description'
  },
  bottleImagePc: {
    type: FormType.SingleImage,
    validator: [Validators.required],
    label: 'Sample Image of Product Bottle and Background (Desktop Size)',
    description: 'Resolution 1400px*760px',
    labelTranslate: 'CmsSection.Product.BottleImagePc.Label',
    descriptionTranslate: 'CmsSection.Product.BottleImagePc.Description'
  },
  bottleImageMb: {
    type: FormType.SingleImage,
    validator: [Validators.required],
    label: 'Sample Image of Product Bottle and Background (Mobile Size)',
    description: 'Resolution 490px*330px',
    labelTranslate: 'CmsSection.Product.BottleImageMb.Label',
    descriptionTranslate: 'CmsSection.Product.BottleImageMb.Description'
  },
  waterDropImage: {
    type: FormType.SingleImage,
    validator: [Validators.required],
    label: 'Water Drop Background Image',
    description: 'Resolution 638px*466px',
    labelTranslate: 'CmsSection.Product.WaterDropImage.Label',
    descriptionTranslate: 'CmsSection.Product.WaterDropImage.Description'
  },
  dividerSection2: {
    type: FormType.Divider
  },
  // section 3 ===================================
  iconImage1: {
    type: FormType.SingleImage,
    validator: [Validators.required],
    label: 'Icon Image 1',
    description: 'Resolution 150px*150px',
    labelTranslate: 'CmsSection.Product.IconImage1.Label',
    descriptionTranslate: 'CmsSection.Product.IconImage1.Description'
  },
  caption1: {
    type: FormType.Text,
    validator: [Validators.required],
    label: 'Text under Icon 1',
    labelTranslate: 'CmsSection.Product.IconText1.Label'
  },
  iconImage2: {
    type: FormType.SingleImage,
    validator: [Validators.required],
    label: 'Icon Image 2',
    description: 'Resolution 150px*150px',
    labelTranslate: 'CmsSection.Product.IconImage2.Label',
    descriptionTranslate: 'CmsSection.Product.IconImage2.Description'
  },
  caption2: {
    type: FormType.Text,
    validator: [Validators.required],
    label: 'Text under Icon 2',
    labelTranslate: 'CmsSection.Product.IconText2.Label'
  },
  iconImage3: {
    type: FormType.SingleImage,
    validator: [Validators.required],
    label: 'Icon Image 3',
    description: 'Resolution 150px*150px',
    labelTranslate: 'CmsSection.Product.IconImage3.Label',
    descriptionTranslate: 'CmsSection.Product.IconImage3.Description'
  },
  caption3: {
    type: FormType.Text,
    validator: [Validators.required],
    label: 'Text under Icon 3',
    labelTranslate: 'CmsSection.Product.IconText3.Label'
  },
  iconImage4: {
    type: FormType.SingleImage,
    validator: [Validators.required],
    label: 'Icon Image 4',
    description: 'Resolution 150px*150px',
    labelTranslate: 'CmsSection.Product.IconImage4.Label',
    descriptionTranslate: 'CmsSection.Product.IconImage4.Description'
  },
  caption4: {
    type: FormType.Text,
    validator: [Validators.required],
    label: 'Text under Icon 4',
    labelTranslate: 'CmsSection.Product.IconText4.Label'
  },
  iconImage5: {
    type: FormType.SingleImage,
    validator: [Validators.required],
    label: 'Icon Image 5',
    description: 'Resolution 150px*150px',
    labelTranslate: 'CmsSection.Product.IconImage5.Label',
    descriptionTranslate: 'CmsSection.Product.IconImage5.Description'
  },
  caption5: {
    type: FormType.Text,
    validator: [Validators.required],
    label: 'Text under Icon 5',
    labelTranslate: 'CmsSection.Product.IconText5.Label'
  },
  iconImage6: {
    type: FormType.SingleImage,
    validator: [Validators.required],
    label: 'Icon Image 6',
    description: 'Resolution 150px*150px',
    labelTranslate: 'CmsSection.Product.IconImage6.Label',
    descriptionTranslate: 'CmsSection.Product.IconImage6.Description'
  },
  caption6: {
    type: FormType.Text,
    validator: [Validators.required],
    label: 'Text under Icon 6',
    labelTranslate: 'CmsSection.Product.IconText6.Label'
  },
  dividerSection3: {
    type: FormType.Divider
  },
  // section 4 ===================================
  BannerProductPc: {
    type: FormType.SingleImage,
    validator: [Validators.required],
    label: 'Banner Product (Desktop Size)',
    description: 'Resolution 1920px*1080px',
    labelTranslate: 'CmsSection.Product.BannerProductPc.Label',
    descriptionTranslate: 'CmsSection.Product.BannerProductPc.Description'
  },
  BannerProductMb: {
    type: FormType.SingleImage,
    validator: [Validators.required],
    label: 'Banner Product (Mobile Size)',
    description: 'Resolution 828*630px;',
    labelTranslate: 'CmsSection.Product.BannerProductMb.Label',
    descriptionTranslate: 'CmsSection.Product.BannerProductMb.Description'
  },
  dividerSection4: {
    type: FormType.Divider
  },
  //section 5 ===================================
  productPresenImg: {
    type: FormType.SingleImage,
    validator: [Validators.required],
    label: 'Product Presentation Image',
    description: 'Resolution 687px*672px',
    labelTranslate: 'CmsSection.Product.ProductPresenImg.Label',
    descriptionTranslate: 'CmsSection.Product.ProductPresenImg.Description'
  },
  bottleImage2: {
    type: FormType.SingleImage,
    validator: [Validators.required],
    label: 'Sample Image of Product Bottle',
    description: 'Resolution 387px*630px',
    labelTranslate: 'CmsSection.Product.BottleImage2.Label',
    descriptionTranslate: 'CmsSection.Product.BottleImage2.Description'
  },
  title2: {
    type: FormType.RichText,
    validator: [Validators.required],
    label: 'Topic of Extract',
    labelTranslate: 'CmsSection.Product.TopicExtract.Label'
  },
  descrtiption2: {
    type: FormType.Text,
    validator: [Validators.required],
    label: 'Details of Extract Topic',
    labelTranslate: 'CmsSection.Product.TopicExtractDetail.Label'
  },
  dividerSection5: {
    type: FormType.Divider
  },
  //section 6 ===================================

  //section 7 ===================================
  title3: {
    type: FormType.Text,
    validator: [Validators.required],
    label: 'Product Safety Topics',
    labelTranslate: 'CmsSection.Product.ProductSafetyTopics.Label'
  },
  descrtiption3: {
    type: FormType.Text,
    validator: [Validators.required],
    label: 'Product Safety Topics Detail',
    labelTranslate: 'CmsSection.Product.ProductSafetyTopicsDetail.Label'
  },
  compoundImg: {
    type: FormType.MultipleImage,
    validator: [Validators.required],
    label: 'Compound Image',
    description: 'Resolution 207px*214px',
    labelTranslate: 'CmsSection.Product.CompoundImg.Label',
    descriptionTranslate: 'CmsSection.Product.CompoundImg.Description',
    limitDisabled: true,
  }
  // End

}