import { Component, OnInit, OnDestroy } from '@angular/core';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import { BannerSwiperService } from '../banner-swiper.service';

@Component({
  selector: 'app-banner-swiper-formpage',
  templateUrl: './banner-swiper-formpage.component.html',
  styleUrls: ['./banner-swiper-formpage.component.scss']
})
export class BannerSwiperFormpageComponent implements OnInit {

  config: ICMSConfig

  constructor(private _BannerSwiper: BannerSwiperService) { 
    this.config = this._BannerSwiper.config;
  }

  ngOnInit(): void {
  }

}
