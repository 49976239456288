import { Component, OnInit } from '@angular/core';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import { ProductIngredients1Service } from './product-ingredients1.service';

@Component({
  selector: 'app-product-ingredients1',
  templateUrl: './product-ingredients1.component.html',
  styleUrls: ['./product-ingredients1.component.scss']
})
export class ProductIngredients1Component implements OnInit {

  config!: ICMSConfig;
  keyName: string;

  constructor(private _productIngredients1Service : ProductIngredients1Service ) {
    this.config = this._productIngredients1Service.config;
    this.keyName = this._productIngredients1Service.keyName
   }

  ngOnInit(): void {
  }

}
