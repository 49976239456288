import { Component, OnInit } from '@angular/core';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import Home from 'app/main/model/Home';
import formConfig from '../formsConfig/productItemFormConfig';

@Component({
  selector: 'app-product-item1',
  templateUrl: './product-item1.component.html',
  styleUrls: ['./product-item1.component.scss']
})
export class ProductItem1Component implements OnInit {

  config: ICMSConfig = {
    apiPath: 'productitemone',
    pathUrl: '/cms/product/gentle-bath/detail',
    tableName: 'Content',
    model: new Home(),
    isList: false,
    componentName: 'General.Product',
    formConfig: formConfig,
  }

  constructor() { }

  ngOnInit(): void {
  }

}
