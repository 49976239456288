import { Component, OnInit } from '@angular/core';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import { ProductIngredients2Service } from '../product-ingredients2.service';

@Component({
  selector: 'app-product-ingredients2-formpage',
  templateUrl: './product-ingredients2-formpage.component.html',
  styleUrls: ['./product-ingredients2-formpage.component.scss']
})
export class ProductIngredients2FormpageComponent implements OnInit {
  config: ICMSConfig

  constructor(private _ProductIngredients2Service: ProductIngredients2Service) {
    this.config = this._ProductIngredients2Service.config;
   }

  ngOnInit(): void {
  }

}
