import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ApiService } from 'app/main/service/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { ImageUploadComponent } from '../../image-upload/image-upload.component';

import { MainConfig } from 'app/main/config/MainConfig';
import { ModalComponent } from '../../modal/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentsService } from '../../components.service';
import {
  FormType,
  IRadioForm,
  IformConfig,
} from 'app/main/config/CMSInterface';
import { FilesApiService } from 'app/main/service/files-api.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CustomDropzoneComponent } from '../../custom-dropzone/custom-dropzone.component';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-form-template',
  templateUrl: './form-template.component.html',
  styleUrls: ['./form-template.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormTemplateComponent implements OnInit, OnDestroy, AfterViewInit {
  private _unsubscribeAll: Subject<any> = new Subject();
  itemID: string;

  @ViewChildren('imageUploadComponent')
  imageUploadComponent: QueryList<ImageUploadComponent>;

  @ViewChildren('customDropzoneComponent')
  customDropzoneComponent: QueryList<CustomDropzoneComponent>;

  @ViewChild('customDropzoneComponent')
  invalidCustomDropzoneComponent: CustomDropzoneComponent;

  @ViewChildren('videoUploadComponent')
  videoUploadComponent: QueryList<ElementRef>;

  countMedia: number;
  minimumFiles: number;
  alertFiles: boolean;
  isLoading: boolean;
  loadingVideo: boolean;
  loadingFile: boolean;
  formGroup: FormGroup;
  formType = FormType;
  isSubmit: Boolean = false;
  invalidAlert: Boolean = false;
  invalidMutiplefile: boolean;
  invalidMutiplefileMinimum: boolean;
  showMessage: boolean = true;

  mediaData: any[] = [];
  files: File[] = [];
  images: Blob[] = [];

  @BlockUI() blockUI: NgBlockUI;
  @Input() itemObj!: any;
  @Input() apiPath: string;
  @Input() componentName: string;
  @Input() tableName: string = 'content';
  @Input() pathUrl: string;
  @Input() config!: { [key: string]: IformConfig };
  @Input() isListMode: boolean = false;
  @Input() isEditing: boolean = false;
  @Input() isLoadingData: boolean;
  @Input() isUseMultipleLang: boolean = false;
  isDataLoading: boolean = false;

  public summernoteConfig;

  handleList: any[] = [];

  optionList: any[] = [];
  datepickerList: FlatpickrOptions[] = [];
  radioList: IRadioForm[] = [];

  videoFileSize: number = environment.limitFileSize.maxVideoSizeInBytes;
  maxFileSize: number = environment.limitFileSize.maxFileSizeInBytes;
  imageFileSize: number = environment.limitFileSize.maxImageSizeInBytes;

  singleFileList: any[] = [];
  multipleFilesList: any[] = [];
  previewFilesList: any[] = [];

  @ViewChild('componentTemplate', { static: false })
  componentTemplate: ElementRef;

  selectedFileName: string;
  selectedVideoName: string = 'Video : input';

  selectedLang: string = environment.langDefault;
  langList = environment.langContent;

  constructor(
    private sanitizer: DomSanitizer,
    private _route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _fileService: FilesApiService,
    private _mainConfig: MainConfig,
    private _modalService: NgbModal,
    private _router: Router,
    private _apiService: ApiService,
    private _translateService: TranslateService,
    private _componentsService: ComponentsService,
    private _globalFuncService: GlobalFuncService,
    private renderer: Renderer2,
    private el: ElementRef,
    private cdr: ChangeDetectorRef
  ) {
    this.SetLoadingState();
    this.optionList = [];
    this.datepickerList = [];
    this.radioList = [];
    this.singleFileList = [];
    this.multipleFilesList = [];
    this.isEditing = false;
    this.summernoteConfig = this._mainConfig.summernoteNewsConfig;
    this.blockUI.start();
  }

  onFilesUploaded(files: File[], key): void {
    this.multipleFilesList[key] = files;
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }

  SetLoadedState(): void {
    this.isLoading = false;
  }

  callSelectVideo(key) {
    const vdoInput = this.videoUploadComponent.find(
      (element: ElementRef) => element.nativeElement.id === key
    );

    vdoInput.nativeElement.click();
  }

  ngOnInit() {

    this.formGroup = this._formBuilder.group({});
    this.createForm();

  }

  ngAfterViewInit(): void {
    if (!this.isEditing) {
      this.blockUI.stop();
    }
  }

  ngOnDestroy(): void {
    this.blockUI.stop();
  }

  createForm() {
    this.formGroup = this.createFormGroup(this.config);
    this.isLoading = false;
  }

  private createFormGroup(obj: IformConfig): FormGroup {
    const group = this._formBuilder.group({});
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const fieldConfig = obj[key];
        if (fieldConfig.type === FormType.IsActive) {
          this.itemObj.isActive = false;
          continue;
        }
        if (fieldConfig.type === FormType.Divider) {
          continue;
        }
        if (fieldConfig.type === FormType.Select) {
          this.setupSelectField(key, group, fieldConfig);
        } else if (fieldConfig.type === FormType.Datepicker) {
          this.setupDatepickerField(key, group, fieldConfig);
        } else if (fieldConfig.type === FormType.Radio) {
          this.setupRadioField(key, fieldConfig);
        } else if (fieldConfig.type === FormType.Switch) {
          group.addControl(key, this._formBuilder.control(false, []));
        } else if (
          fieldConfig.type === FormType.MultipleImage ||
          fieldConfig.type === FormType.MultipleFiles
        ) {
          this.setupMultipleFileField(key, fieldConfig);
        } else if (
          fieldConfig.type === FormType.SingleFile ||
          fieldConfig.type === FormType.SingleImage ||
          fieldConfig.type === FormType.Video
        ) {
          this.setupSingleFileField(key, fieldConfig);
        } else if (fieldConfig.type === FormType.StaticValue) {
          group.addControl(key, this.createFormControl(fieldConfig, fieldConfig.staticValue));
        } else {
          if (fieldConfig.isParent) {
            group.addControl(key, this.createFormGroup(fieldConfig.child || fieldConfig));
          } else if (fieldConfig.isMultipleLang) {
            this.setupMultipleLangField(key, group, fieldConfig);
          } else {
            group.addControl(key, this.createFormControl(fieldConfig));
          }
        }
      }
    }
    return group;
  }

  private setupSelectField(key: string, group: FormGroup, fieldConfig: any) {
    group.addControl(key, this._formBuilder.control('', fieldConfig.validator));
    if (fieldConfig.isUseMultipleAPI) {
      fieldConfig.selectDataMultipleAPI.forEach((element) => {
        this._apiService
          .GetAllData(element, { isActive: true, isDelete: false })
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((res) => {
            this.optionList[key].push(res.data.resultData);
          });
      });
    } else {
      const selectDataAPI = fieldConfig.selectDataAPI;
      this._apiService
        .GetAllData(selectDataAPI, { isActive: true, isDelete: false })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {
          this.optionList[key] = res.data.resultData;
        });
    }
  }

  private setupDatepickerField(key: string, group: FormGroup, fieldConfig: any) {
    const tempDate = new Date();
    const tempOption: FlatpickrOptions = {
      dateFormat: 'd M Y',
      altInput: false,
      enableTime: fieldConfig.useTime,
      defaultDate: tempDate.toISOString(),
    };
    this.datepickerList[key] = tempOption;
    group.addControl(key, this._formBuilder.control('', fieldConfig.validator));
  }

  private setupRadioField(key: string, fieldConfig: any) {
    this.radioList[key] = fieldConfig.radioList;
  }

  private setupMultipleFileField(key: string, fieldConfig: any) {
    this.multipleFilesList[key] = [];
  }

  private setupSingleFileField(key: string, fieldConfig: any) {
    if (fieldConfig.type === FormType.Video) {
      this.singleFileList[key] = {
        file: null,
        filename: null,
        coverImageURL: null,
      };
    } else {
      this.singleFileList[key] = [];
    }
  }

  private setupMultipleLangField(key: string, group: FormGroup, fieldConfig: any) {
    this.isUseMultipleLang = true;
    const langControls: any = {};
    for (const lang of this.langList) {
      langControls[lang] = this._formBuilder.control('', fieldConfig.validator);
    }
    group.addControl(key, this._formBuilder.group(langControls));
  }

  private createFormControl(fieldConfig: any, value: any = ''): FormControl {
    return this._formBuilder.control(value, fieldConfig.validator);
  }

  selectedLanguage(lang: string) {
    this.selectedLang = lang;
  }

  getFieldType(key: string): string {
    return this._globalFuncService.getPropertyByPath(this.config, key).type;
  }

  getFieldLabel(key: string): string {
    let tempObj = this._globalFuncService.getPropertyByPath(this.config, key);
    if (tempObj.labelTranslate) {
      return this._translateService.instant(tempObj.labelTranslate);
    } else {
      return tempObj.label;
    }
  }

  getFieldDescription(key: string): string {
    let tempObj = this._globalFuncService.getPropertyByPath(this.config, key);
    if (tempObj.descriptionTranslate) {
      return this._translateService.instant(tempObj.descriptionTranslate);
    } else {
      return tempObj.description;
    }
  }

  getIsRequiredField(key: string): boolean {
    return (
      this._globalFuncService.getPropertyByPath(this.config, key).validator !=
      null
    );
  }

  getSelectLabelColumn(key): string {
    return this.config[key].selectDataLabelColumn
      ? this.config[key].selectDataLabelColumn
      : 'label';
  }

  getSelectValueColumn(key): string {
    return this.config[key].selectDataValueColumn
      ? this.config[key].selectDataValueColumn
      : 'value';
  }

  getFieldLimit(key: number): number {
    let limit = this.config[key].limit;
    let chackLimitDisabled = this.config[key].limitDisabled ?? false;
  
    if (chackLimitDisabled === true) {
      return null; // ถ้า chackLimitDisabled เป็น true ให้ return null เลย
    }
  
    return limit ?? 5; // ถ้า limit ไม่ได้กำหนดค่า ให้ return 5
  }

  getFieldMinimum(key: number): number {
    if ((this.config[key].minimum > this.config[key].limit) && this.showMessage) {
      console.error('The minimum is greater than the maximum limit.');
      this.showMessage = false;
    }
    this.minimumFiles = (!(this.config[key].minimum > this.config[key].limit)) ? this.config[key].minimum : null;
    return this.minimumFiles;
  }

  getTypeFilesFilter(key: string): number {
    return this.config[key].fileTypeFilter;
  }

  getFieldValue(key, field?): any {
    return this._globalFuncService.getPropertyByPath(this.itemObj, key);
  }

  getFieldMaxLength(key): number | null {
    let tempObj = this._globalFuncService.getPropertyByPath(this.itemObj, key);
    return tempObj.maxTextLength ? tempObj.maxTextLength : null;
  }

  getDefaultSelectLabel(key: string): string {
    let tempObj = this._globalFuncService.getPropertyByPath(this.config, key);
    if (!isNullOrUndefined(tempObj.defaultSelectLabelTranslate)) {
      return this._translateService.instant(
        tempObj.defaultSelectLabelTranslate
      );
    } else if (!isNullOrUndefined(tempObj.defaultSelectLabel)) {
      return tempObj.defaultSelectLabel;
    } else {
      return 'All';
    }
  }

  getPreviewImageUrl(key: string): any {
    return this.singleFileList[key].coverImageURL;
  }

  getVideoName(key: string): any {
    return this.singleFileList[key].filename;
  }

  setFormValue(data: any) {
    if (!data) {
      // this.initEmptyRow()
    }
    this.mediaData = data.mediaFiles;
    this.setFormGroupValue(this.config, data);
  }

  initEmptyRow(): Promise<void> {
    return new Promise((resolve, rejects) => {
      this._apiService
        .UpdateWithOutId(this.apiPath, this.formGroup.value)
        .pipe(take(1))
        .subscribe((res) => {
          window.location.reload();
        });
    });
  }



  returnZero() {
    return 0;
  }

  private setFormGroupValue(config: any, itemObj: any, parentKey?: string) {
    this.isEditing = true;
    const keys = Object.keys(config);

    keys.forEach((key) => {
      const fieldType = config[key].type;

      switch (fieldType) {
        case FormType.SingleFile:
          this.setSingleFileField(itemObj, key);
          break;

        case FormType.SingleImage:
          this.setSingleImageField(itemObj, key);
          break;

        case FormType.Video:
          this.setVideoField(itemObj, key);
          break;

        case FormType.Switch:
          this.setSwitchField(itemObj, key);
          break;

        case FormType.Datepicker:
          this.setDatepickerField(itemObj, key);
          break;

        case FormType.MultipleFiles:
        case FormType.MultipleImage:
          this.setMultipleFilesField(itemObj, key);
          break;

        case FormType.Radio:
          break;
      }

      if (parentKey) {
        key = `${parentKey}.${key}`;
      }

      if (config.hasOwnProperty(key) && typeof config[key] === 'object') {
        this.setNestedFields(config, itemObj, key);
      }
    });

    if (keys.length === Object.keys(config).length) {
      this.blockUI.stop();
    }
  }

  private setSingleFileField(itemObj: any, key: string) {
    if (itemObj.mediaFiles[key]) {
      itemObj.mediaFiles[key].forEach((el, ind) => {
        this.getFile(itemObj.mediaFiles[key][ind].id, 'File');
      });
    }
  }

  private setSingleImageField(itemObj: any, key: string) {
    if (itemObj.mediaFiles[key]) {
      const targetElement = this.imageUploadComponent.find(
        (component: ImageUploadComponent) =>
          component.elementRef.nativeElement.id === key
      );
      targetElement.startLoading();
      const Image = itemObj.mediaFiles[key][0].id;

      this._fileService.GetFile(Image).subscribe((res) => {
        targetElement.setCoverImage(res, Image);
      });
    }
  }

  private setVideoField(itemObj: any, key: string) {
    if (itemObj.mediaFiles[key]) {
      const video = itemObj.mediaFiles[key][0].id;

      this._fileService.GetFile(video).subscribe(async (res) => {
        const file = new File([res], video, {
          type: res.type,
        });

        this.singleFileList[key] = {
          file: file,
          filename: video,
          coverImageURL: await this.generatePreviewCover(file),
        };
      });
    }
  }

  private setSwitchField(itemObj: any, key: string) {
    const tempValue = this._globalFuncService.getPropertyByPath(itemObj, key);
    this.setFormControl(this.formGroup, key, !isNullOrUndefined(tempValue) ? tempValue : false);
  }

  private setDatepickerField(itemObj: any, key: string) {
    const tempDate = new Date(itemObj[key]);
    this.datepickerList[key].defaultDate = tempDate.toISOString();
  }

  private setMultipleFilesField(itemObj: any, key: string) {
    if (itemObj.mediaFiles[key]) {
      const targetElement = this.customDropzoneComponent.find(
        (component: CustomDropzoneComponent) =>
          component.elementRef.nativeElement.id === key
      );
      targetElement.startLoading();

      const files = itemObj.mediaFiles[key];
      let tempFilesList: { file: any; ordinal: number }[] = [];
      this.multipleFilesList[key] = [];

      const filePromises: Promise<void>[] = files.map(
        (element: { id: string; ordinal: number }, index) => {
          return new Promise<void>((resolve) => {
            this._fileService.GetFile(element.id).subscribe((res) => {
              const type = res.type.split('/')[1];
              const name = element + '.' + type;
              const file = new File([res], name, { type: res.type });
              tempFilesList.push({
                file: file,
                ordinal: element.ordinal,
              });

              resolve();
            });
          });
        }
      );

      Promise.all(filePromises).then(() => {
        tempFilesList.sort((a, b) => a.ordinal - b.ordinal);

        tempFilesList.forEach((element) => {
          this.multipleFilesList[key].push(element.file);
        });
        if (this.multipleFilesList[key].length === files.length) {
          targetElement.ngOnChangesConfig(this.multipleFilesList[key]);
        }
      });
    }
  }

  private setNestedFields(config: any, itemObj: any, key: string) {
    if (config[key].isParent && config[key].child) {
      this.setFormGroupValue(
        this._globalFuncService.getPropertyByPath(config, key).child,
        this._globalFuncService.getPropertyByPath(itemObj, key),
        key
      );
    } else if (config[key].isMultipleLang) {
      let tempObj = this._globalFuncService.getPropertyByPath(itemObj, key);
      try {
        if (!tempObj) {
          tempObj = {};
        } else {
          tempObj = JSON.parse(tempObj);
        }
      } catch (error) {
        tempObj = {};
      }
      for (const lang of this.langList) {
        let tempLangKey = `${key}.${lang}`;
        this.setFormControl(this.formGroup, tempLangKey, tempObj[lang]);
      }
    } else {
      this.setFormControl(
        this.formGroup,
        key,
        this._globalFuncService.getPropertyByPath(itemObj, key)
      );
    }
  }

  private setFormControl(formGroup: FormGroup, key: string, value: any) {
    formGroup.get(key)?.patchValue(value);
  }

  openConfirmModal(): void {
    this.isSubmit = true;
    let isConfirm = true;

    let title = this._translateService.instant('Modal.ConfirmCreate');
    let detail = this._translateService.instant('Modal.AreYouSureToCreate');

    if (this.formGroup.status === 'INVALID' || !this.checkFilesValidate()) {
      this.invalidAlert = true;
      if (this.formGroup.status === 'INVALID') {
        this.scrollToFirstInvalidControl();
      }
    } else {
      this.openModal(title, detail, isConfirm);
    }
  }

  private scrollToFirstInvalidControl(el?) {
    this._globalFuncService.scrollToFirstInvalidControl('generative-form', el);
  }

  openCancelModal(): void {
    let isConfirm = false;
    let title = this._translateService.instant('Modal.CancelManagePage');
    let detail = this._translateService.instant('Modal.PressSubmitToCancel');
    this.openModal(title, detail, isConfirm);
  }

  setRadioValue(controlName: string, value: any): void {
    this.formGroup.controls[controlName].setValue(value);
  }

  updateRadioValue(controlName: string, value: any): void {
    this.formGroup.controls[controlName].setValue(value);
  }

  openModal(title: string, detail: string, IsConfirm: boolean): void {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.isConfirm = IsConfirm;
    modalRef.componentInstance.detail = detail;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      if (IsConfirm) {
        this.Submit();
      } else {
        window.location.reload();
        this._router.navigate([`${this.pathUrl}`]);
      }
    });
  }

  prepareFinalData(
    config: any,
    data: any,
    tempForm: FormGroup,
    parentKey?: string
  ) {
    for (let key in config) {
      if (parentKey) {
        key = `${parentKey}.${key}`;
      }
      Object.keys(config).forEach((key) => {
        if (config[key].type == FormType.Datepicker) {
          let dateValue = this.formGroup.get(key)?.value;
          let tempDate = new Date(dateValue[0]);
          tempForm.addControl(
            key,
            this._formBuilder.control(tempDate.toISOString())
          );
        }

        if (config[key].type == FormType.Switch) {
          let tempBool = this.formGroup.get(key)?.value;

          if (!tempBool || isNullOrUndefined(tempBool)) {
            tempBool = false;
          }
          tempForm.addControl(key, this._formBuilder.control(tempBool));
        }
      });
      if (config.hasOwnProperty(key) && typeof config[key] === 'object') {
        if (
          config[key].type === FormType.SingleImage ||
          config[key].type === FormType.SingleFile ||
          config[key].type === FormType.MultipleFiles ||
          config[key].type === FormType.MultipleImage ||
          config[key].type === FormType.Datepicker ||
          config[key].type === FormType.Video ||
          config[key].type === FormType.IsActive ||
          config[key].type === FormType.Divider
        ) {
          continue;
        }
        if (config[key].isParent && config[key].child) {
          this.prepareFinalData(
            this._globalFuncService.getPropertyByPath(config, key).child,
            this.formGroup.get(key)?.value,
            tempForm
          );
        } else if (config[key].isMultipleLang) {
          tempForm.addControl(key, this._formBuilder.control(''));
          const multiLangObj = JSON.stringify(this.formGroup.get(key)?.value);
          tempForm.get(key)?.patchValue(multiLangObj);
        } else {
          tempForm.addControl(
            key,
            this._formBuilder.control(this.formGroup.get(key)?.value)
          );
        }
      }
    }
  }

  handleIsNotFileChange(isNotFile: boolean, key: string) {
    this.invalidMutiplefile = isNotFile;

    if (this.customDropzoneComponent) {
      const targetElement = this.customDropzoneComponent.find(
        (component: CustomDropzoneComponent) =>
          component.elementRef.nativeElement.id === key
      );

      if (targetElement) {
        targetElement.handleBox = this.invalidMutiplefile;
        this.cdr.detectChanges();
      }
    }
  }
  handleIsFileMinimumCheck(isFileMinimum: boolean, key: string) {
    this.invalidMutiplefileMinimum = isFileMinimum;

    if (this.customDropzoneComponent) {
      const targetElement = this.customDropzoneComponent.find(
        (component: CustomDropzoneComponent) =>
          component.elementRef.nativeElement.id === key
      );

      if (targetElement) {
        targetElement.handleBox = this.invalidMutiplefileMinimum;
        this.cdr.detectChanges();
      }
    }
  }

  checkFilesValidate(): boolean {

    var self = this;
    let isInvalid = false;
    let isScrollToFirstInvalidFile = false;

    Object.keys(self.config).forEach((key) => {
      if (self.config[key].validator) {
        if (self.config[key].type === FormType.SingleImage) {
          let targetElement = this.imageUploadComponent.find(
            (component: ImageUploadComponent) =>
              component.elementRef.nativeElement.id === key
          );

          if (!targetElement.getFile()) {
            targetElement.handleImage();
            if (!isScrollToFirstInvalidFile) {
              this.scrollToFirstInvalidControl(
                targetElement.elementRef.nativeElement as Element
              );
              isScrollToFirstInvalidFile = true;
            }
            isInvalid = true;
          }
        } else if (
          self.config[key].type === FormType.SingleFile ||
          self.config[key].type === FormType.Video
        ) {
          if (
            !self.singleFileList[key] ||
            self.singleFileList[key].length < 1
          ) {
            if (self.config[key].type === FormType.Video) {
              if (!isScrollToFirstInvalidFile) {
                let targetElement = this.videoUploadComponent.find(
                  (component: ElementRef) => component.nativeElement.id === key
                );
                this.scrollToFirstInvalidControl(
                  targetElement.nativeElement as Element
                );
                isScrollToFirstInvalidFile = true;
              }
            }
            isInvalid = true;
          }
        }

        if (
          self.config[key].type === FormType.MultipleFiles ||
          self.config[key].type === FormType.MultipleImage
        ) {
          if (
            !this.multipleFilesList[key] ||
            this.multipleFilesList[key].length < 1 ||
            this.multipleFilesList[key].length < this.minimumFiles
          ) {
            isInvalid = true;
            this.handleIsNotFileChange(isInvalid, key);
            this.handleIsFileMinimumCheck(isInvalid, key);
          }
        }
      }
    });

    return !isInvalid;
  }

  Submit() {
    this.blockUI.start();
    var self = this;
    console.log(this.itemObj.isActive)

    if (isNullOrUndefined(this.itemObj.isActive)) {
      this.itemObj.isActive = false;
    }

    let tempForm = new FormGroup({});
    this.prepareFinalData(this.config, this.formGroup.value, tempForm);
    if (this.isEditing) {
      this._route.paramMap.subscribe((val) => {
        let docID = this._route.snapshot.paramMap.get('id');
        if (docID) {
          self._apiService
            .UpdateDataById(
              this.apiPath,
              this.itemObj.id.toString(),
              tempForm.value
            )
            .subscribe(
              (res) => {
                self._apiService
                  .SetIsActiveData(
                    this.apiPath,
                    this.itemObj.id.toString(),
                    this.itemObj.isActive
                  )
                  .subscribe((r) => {
                    const uploadPromises = [];
                    for (const key in self.singleFileList) {
                      let fileData;
                      const value = self.singleFileList[key];
                      if (value) {
                        if (this.config[key].type === FormType.Video) {
                          const targetElement = this.videoUploadComponent.find(
                            (component: ElementRef) =>
                              component.nativeElement.id === key
                          );

                          if (this.singleFileList[key]) {
                            fileData = this.singleFileList[key].file;
                          } else {
                            fileData = targetElement.nativeElement.files[0];
                          }
                        } else {
                          fileData = value.file;
                        }

                        if (fileData) {
                          uploadPromises.push(
                            this.uploadFile({
                              isEdit: self.isEditing,
                              idResId: this.itemObj.id.toString(),
                              tablename: self.tableName,
                              collectionName: key,
                              ordinal: 0,
                              fileData: fileData,
                            })
                          );
                        }
                      }
                    }

                    for (const key in self.multipleFilesList) {
                      if (self.multipleFilesList[key].length >= 1) {
                        const value = self.multipleFilesList[key];
                        uploadPromises.push(
                          self.updateFiles(
                            self.tableName,
                            self.itemObj.id.toString(),
                            key,
                            0,
                            value
                          )
                        );
                      }
                    }

                    this.imageUploadComponent.forEach(
                      (component: ImageUploadComponent) => {
                        const nativeElement: HTMLElement =
                          component.elementRef.nativeElement;
                        const elementID = nativeElement.id;
                        uploadPromises.push(
                          component.uploadImage({
                            isEdit: true,
                            idResId: this.itemObj.id.toString(),
                            tablename: this.tableName,
                            collectionName: elementID,
                            ordinal: 0,
                          })
                        );
                      }
                    );

                    Promise.all(uploadPromises)
                      .then(() => {
                        self.loadingFile = false;
                        self._componentsService.SuccessSwal();
                        self._router.navigate([`${this.pathUrl}`]);
                      })
                      .catch((error) => {
                        self.loadingFile = false;
                        self._componentsService.ErrorSwal(error);
                        self._router.navigate([`${this.pathUrl}`]);
                      });
                  });
              },
              (err) => {
                self._componentsService.ErrorSwal();
              }
            );
        } else {
          self._apiService
            .UpdateWithOutId(this.apiPath, tempForm.value)
            .subscribe(
              (res) => {
                self._fileService
                // .DeleteFile('content', res.data.resultData[0])
                // .subscribe((r) => {
                const uploadPromises = [];
                for (const key in self.singleFileList) {
                  let fileData;
                  const value = self.singleFileList[key];
                  if (value) {
                    if (this.config[key].type === FormType.Video) {
                      const targetElement = this.videoUploadComponent.find(
                        (component: ElementRef) =>
                          component.nativeElement.id === key
                      );

                      if (this.singleFileList[key]) {
                        fileData = this.singleFileList[key].file;
                      } else {
                        fileData = targetElement.nativeElement.files[0];
                      }
                    } else {
                      fileData = value.file;
                    }
                    if (fileData) {
                      uploadPromises.push(
                        this.uploadFile({
                          isEdit: self.isEditing,
                          idResId: res.data.resultData[0],
                          tablename: self.tableName,
                          collectionName: key,
                          ordinal: 0,
                          fileData: fileData,
                        })
                      );
                    }
                  }
                }

                for (const key in self.multipleFilesList) {
                  if (self.multipleFilesList[key].length >= 1) {
                    const value = self.multipleFilesList[key];
                    uploadPromises.push(
                      self.updateFiles(
                        self.tableName,
                        self.itemObj.id.toString(),
                        key,
                        0,
                        value
                      )
                    );
                  }
                }

                this.imageUploadComponent.forEach(
                  (component: ImageUploadComponent) => {
                    const nativeElement: HTMLElement =
                      component.elementRef.nativeElement;
                    const elementID = nativeElement.id;
                    uploadPromises.push(
                      component.uploadImage({
                        isEdit: true,
                        idResId: res.data.resultData[0],
                        tablename: this.tableName,
                        collectionName: elementID,
                        ordinal: 0,
                      })
                    );
                  }
                );

                Promise.all(uploadPromises)
                  .then(() => {
                    self.loadingFile = false;
                    self._componentsService.SuccessSwal();
                    self._router.navigate([`${this.pathUrl}`]);
                  })
                  .catch((error) => {
                    self.loadingFile = false;
                    self._componentsService.ErrorSwal(error);
                    self._router.navigate([`${this.pathUrl}`]);
                  });
                // });
              },
              (err) => {
                self._componentsService.ErrorSwal();
              }
            );
        }
      });
    } else {
      self._apiService.AddData(this.apiPath, tempForm.value).subscribe(
        (res) => {
          self._apiService
            .SetIsActiveData(
              this.apiPath,
              res.data.resultData[0],
              this.itemObj.isActive
            )
            .subscribe((res) => {
              const uploadPromises = [];
              for (const key in self.singleFileList) {
                let fileData;
                const value = self.singleFileList[key];
                if (value) {
                  if (this.config[key].type === FormType.Video) {

                  } else {
                    fileData = value.file;
                    if (fileData) {
                      uploadPromises.push(
                        this.uploadFile({
                          isEdit: self.isEditing,
                          idResId: res.data.resultData[0],
                          tablename: self.tableName,
                          collectionName: key,
                          ordinal: 0,
                          fileData: fileData,
                        })
                      );
                    }
                  }
                }
              }



              for (const key in self.multipleFilesList) {
                if (self.multipleFilesList[key].length >= 1) {
                  const value = self.multipleFilesList[key];
                  uploadPromises.push(
                    self.updateFiles(
                      self.tableName,
                      res.data.resultData[0],
                      key,
                      0,
                      value
                    )
                  );
                }
              }

              this.imageUploadComponent.forEach(
                (component: ImageUploadComponent) => {
                  const nativeElement: HTMLElement =
                    component.elementRef.nativeElement;
                  const elementID = nativeElement.id;
                  uploadPromises.push(
                    component.uploadImage({
                      isEdit: true,
                      idResId: res.data.resultData[0],
                      tablename: this.tableName,
                      collectionName: elementID,
                      ordinal: 0,
                    })
                  );
                }
              );

              Promise.all(uploadPromises)
                .then(() => {
                  self.loadingFile = false;
                  self._componentsService.SuccessSwal();
                  self._router.navigate([`${this.pathUrl}`]);
                })
                .catch((error) => {
                  self.loadingFile = false;
                  self._componentsService.ErrorSwal(error);
                  self._router.navigate([`${this.pathUrl}`]);
                });
            });
        },
        (err) => {
          self._componentsService.ErrorSwal();
        }
      );
    }
  }

  onSelectImage(event) {
    var self = this;

    for (let i = 0; i < event.addedFiles.length; i++) {
      if (event.addedFiles.length <= this.countMedia) {
        if (event.addedFiles.length + this.images.length > this.countMedia) {
          this.alertFiles = true;
        }
        const file = event.addedFiles[i];
        const reader = new FileReader();
        reader.onload = () => {
          const img = new Image();
          img.onload = () => {
            self.images.push(file);
          };
          img.src = URL.createObjectURL(file);
        };
        reader.readAsDataURL(file);
      } else {
        this.alertFiles = true;
      }
    }
  }

  onRemoveAtteched(event, arr) {
    arr.splice(arr.indexOf(event), 1);
    if ((arr.length + this.images.length) / 2 <= this.countMedia) {
      this.alertFiles = false;
    }
  }

  generatePreviewCover(file: File): Promise<SafeUrl> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        const video = document.createElement('video');
        video.src = event.target.result;

        video.onloadedmetadata = () => {
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;

          const context = canvas.getContext('2d');

          video.onseeked = () => {
            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            const previewCoverDataURL = canvas.toDataURL('image/png');

            const tempCoverURL =
              this.sanitizer.bypassSecurityTrustUrl(previewCoverDataURL);

            resolve(tempCoverURL);
          };

          video.currentTime = 1;
        };
      };
      reader.readAsDataURL(file);
    });
  }

  async prepareVideo(event, key) {
    var self = this;
    if (event.target.files.length > 0) {
      const filename = event.target.files[0].name;
      const file = event.target.files[0];
      if (file && file.size <= 30 * 1024 * 1024) {
        let obj: { [k: string]: any } = {};
        obj.file = file;
        obj.coverImageURL = await self.generatePreviewCover(file);
        obj.filename = filename;
        self.singleFileList[key] = obj;
        this.handleList[key].push(true);
      } else {
        console.error('File size exceeds the maximum limit of 30MB.');
      }
    }
  }

  prepareSingleFile(event, key) {
    var self = this;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const filename = event.target.files[0].name;
      if (file && file.size <= this.maxFileSize) {
        let obj: { [k: string]: any } = {};
        obj.file = file;
        obj.coverImageURL = null;
        obj.filename = filename;
        self.singleFileList[key] = obj;
        console.log(self.singleFileList[key])
      } else {
        console.error('File size exceeds the maximum limit of 30MB.');
        this._componentsService.ErrorSwal('Invalid File Size')
      }
    }
  }

  handleInputChanged(value: string, controlName: string) {
    this.handleList[controlName][0] = true;
    const element = this.el.nativeElement.querySelector(`#${controlName}`);
    if (element) {
      this.renderer.addClass(element, 'invalid_border');
    }
  }

  bytesToSizes(size: number): string {
    return this._globalFuncService.bytesToSize(size);
  }

  getFile(id, key) {
    this._fileService.GetFile(id).subscribe(
      (res) => {
        if (key === 'File') {
          this.selectedFileName = id + '.' + res.type.split('/')[1];
          const file = new File([res], this.selectedFileName, {
            type: res.type,
          });
        } else if (key === 'Video') {
          this.selectedVideoName = id + '.' + res.type.split('/')[1];
          const file = new File([res], this.selectedVideoName, {
            type: res.type,
          });
          this.generatePreviewCover(file);
        }
      },
      (error) => { }
    );
  }

  uploadFile(params: {
    isEdit: boolean;
    idResId: any;
    tablename: string;
    collectionName: string;
    ordinal: number;
    fileData: any;
  }) {
    var self = this;
    const file = params.fileData;

    const formData = new FormData();
    formData.append('file', file);
    if (params.isEdit) {
      return new Promise<void>((resolve, reject) => {
        this._fileService
          .UpdateFile(
            params.tablename,
            params.idResId,
            params.collectionName,
            params.ordinal,
            formData
          )
          .subscribe(
            () => {
              resolve();
            },
            (error) => {
              console.error('Error uploading File:', error);
              reject(error);
            }
          );
      });
    } else {
      return new Promise<void>((resolve, reject) => {
        this._fileService
          .UpdateFile(
            params.tablename,
            params.idResId,
            params.collectionName,
            params.ordinal,
            formData
          )
          .subscribe(
            () => {
              console.log('File uploaded successfully!');
              resolve();
            },
            (error) => {
              console.error('Error uploading File:', error);
              reject(error);
            }
          );
      });
    }
  }

  onSelectAttachedFile(event, file?, fileId?) {
    var invalidFiles = false;
    if (event) {
      for (let i = 0; i < event.addedFiles.length; i++) {
        const file = event.addedFiles[i];
        const reader = new FileReader();
        reader.onload = () => {
          file.src = reader.result;
          if (this.multipleFilesList.length < 5) {
            this.multipleFilesList.push(file);
          } else {
            this._componentsService.ErrorSwal(
              'Error',
              'The maximum number of files is 5'
            );
          }
        };
        reader.onerror = (error) => {
          console.error('File reading error:', error);
        };

        reader.readAsDataURL(file);
      }
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        file.src = reader.result;
        if (this.multipleFilesList.length < 5) {
          this._fileService.GetFileDetail(fileId).subscribe((res) => {
            let fileName = res.data.resultData[0].name;
            let fileExtension = res.data.resultData[0].extension;
            let fullName = fileName + '.' + fileExtension;
            const convertedFile = new File([file], fullName);
            this.multipleFilesList.push(convertedFile);
          });
        } else {
          this._componentsService.ErrorSwal(
            'Error',
            'The maximum number of files is 5'
          );
        }
      };
      reader.onerror = (error) => {
        console.error('File reading error:', error);
      };

      reader.readAsDataURL(file);
    }
  }

  updateFiles(
    tablename: string,
    contentId: string,
    collectionName: string,
    ordinal: number,
    fileList: any
  ) {
    let fileArr = new FormData();
    fileList.forEach((file: Blob) => {
      fileArr.append('files', file);
    });

    return new Promise<void>((resolve, reject) => {
      this._fileService
        .UpdateFile(tablename, contentId, collectionName, ordinal, fileArr)
        .subscribe(
          () => {
            console.log('Edit File successfully!');
            resolve();
          },
          (error) => {
            console.error('Error Edit File:', error);
            reject(error);
          }
        );
    });
  }

  onRemoveAttechedFile(event, arr) {
    arr.splice(arr.indexOf(event), 1);
    if (arr.length > this.multipleFilesList.length) {
      this.alertFiles = false;
    }
  }
}
