export interface ICMSConfig {
  componentName: string;
  apiPath: string;
  pathUrl: string;
  tableName: string;
  model: any;
  isList: boolean;
  formConfig?: { [key: string]: IformConfig };
}

export interface IformConfig {
  type?: any;
  validator?: any[];
  label?: any;
  labelTranslate?: any;
  description?: any;
  descriptionTranslate?: any;
  selectDataList?: any[];
  selectDataAPI?: any;
  selectDataMultipleAPI?: any[];
  selectDataLabelColumn?: any;
  selectDataValueColumn?: any;
  defaultSelectLabel?: string;
  defaultSelectLabelTranslate?: string;
  useTime?: boolean;
  maxTextLength?: number | null;
  dateFormat?: string;
  radioList?: IRadioForm[];
  files?: IFilesForm;
  limit?: number;
  limitDisabled?: boolean;
  minimum?: number;
  customField?: any;
  isMultipleLang?: boolean;
  isUseMultipleAPI?: boolean;
  isParent?: boolean;
  fileTypeFilter?: number;
  child?: any;
  staticValue?: any;
}

export interface IRadioForm {
  label?: string;
  translate?: string;
  value?: any;
}

export interface IFilesForm {
  name?: string;
  tablename: string;
  files?: ICustomFile[];
}

export interface ICustomFile {
  fileID: string;
  blob: any;
}

export enum DocType {
  Banner = 'banner',
  News = 'news',
  Service = 'service',
}

export enum FormType {
  Text = 'text',
  Textarea = 'textarea',
  Datepicker = 'datepicker',
  Select = 'select',
  Switch = 'switch',
  RichText = 'richtext',
  Radio = 'radio',
  Number = 'number',
  SingleImage = 'singleimage',
  SingleFile = 'singlefile',
  Video = 'video',
  MultipleImage = 'multipleimage',
  MultipleFiles = 'multiplefiles',
  Email = 'email',
  IsActive = 'isActive',
  StaticValue = 'staticValue',
  Divider = 'divider',
  // SectionLine = 'section-line',
}
