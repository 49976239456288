import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreCommonModule } from '@core/common.module';
import { ComponentsModule } from 'app/main/components/components.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { TranslateModule } from '@ngx-translate/core';
import { CmsFormpageModule } from './cms-formpage/cms-formpage.module';
import { CmsListModule } from './cms-list/cms-list.module';
import { HomeModule } from './home/home.module';
import { ContactModule } from './contact/contact.module';
import { PdpaModule } from './pdpa/pdpa.module';
import { ProductModule } from './product/product.module';
import { HomepageModule } from './homepage/homepage.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CmsFormpageModule,
    CmsListModule,
    CoreCommonModule,
    ComponentsModule,
    TranslateModule,
    HomeModule,
    ContactModule,
    PdpaModule,
    ProductModule,
    HomepageModule
  ],
  exports: [],
})
export class CmsModule {}
