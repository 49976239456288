<div *ngIf="isLoading" class="mt-5 d-flex justify-content-center">
  <app-loading></app-loading>
</div>

<div class="lang-switcher-sidebar nav-vertical" *ngIf="isUseMultipleLang">
  <ul ngbNav class="nav nav-pills flex-column text-nowrap mb-0">
    <li ngbNavItem *ngFor="let lang of langList">
      <a
        ngbNavLink
        (click)="selectedLanguage(lang)"
        [class.active]="selectedLang === lang"
        >{{ lang | uppercase }}</a
      >
    </li>
  </ul>
</div>

<div class="auto-form">
  <div class="auto-form-header" *ngIf="isUseMultipleLang">
    <div class="d-flex justify-content-end">
      <ul ngbNav class="nav nav-pills">
        <li ngbNavItem *ngFor="let lang of langList">
          <a
            ngbNavLink
            (click)="selectedLanguage(lang)"
            [class.active]="selectedLang === lang"
            >{{ lang | uppercase }}</a
          >
        </li>
      </ul>
    </div>
  </div>
  <hr />

  <form *ngIf="!isLoading" [formGroup]="formGroup" id="generative-form">
    <ng-container
      *ngTemplateOutlet="
        createFormFields;
        context: { formGroup: formGroup, config: config }
      "
    ></ng-container>

    <div class="auto-form-footer">
      <a class="btn btn-secondary" (click)="openCancelModal(itemObj)">
        {{ "Form.Cancel" | translate }}
      </a>

      <u
        class="text-warning d-none"
        [ngClass]="{
          'd-block': invalidAlert
        }"
      >
        <span
          class="d-none"
          [ngClass]="{
            'd-block': formGroup.invalid
          }"
        >
          {{ "Alert.Invalid" | translate }}
        </span>
      </u>

      <div class="d-flex justify-content-between align-items-center">
        <a class="btn btn-primary" (click)="openConfirmModal(itemObj)">
          {{ "Form.Submit" | translate }}
        </a>
      </div>
    </div>
  </form>
</div>

<ng-template #createFormFields let-config="config" let-formG="formGroup">
  <div [formGroup]="formG">
    <div *ngFor="let field of config | keyvalue : returnZero">
      <div *ngIf="config[field.key].type != 'staticValue'">
        <ng-container *ngIf="config[field.key].isParent; else isChildField">
          <div formGroupName="{{ field.key }}">
            <p>{{ field.key }}</p>
            <div
              *ngFor="
                let childField of config[field.key].child
                  | keyvalue : returnZero
              "
            >
              <ng-container *ngIf="childField.value.isParent">
                <div formGroupName="{{ childField.key }}">
                  <ng-container
                    *ngTemplateOutlet="
                      createFormFields;
                      context: {
                        formGroup: formG,
                        config: childField.value.child
                      }
                    "
                  ></ng-container>
                </div>
              </ng-container>
              <ng-container *ngIf="childField.value.isMultipleLang">
                <div formGroupName="{{ childField.key }}">
                  <div *ngFor="let lang of langList">
                    <div [class.d-none]="selectedLang != lang">
                      <ng-container
                        *ngTemplateOutlet="
                          inputSwitcher;
                          context: {
                            form: formGroup,
                            formGroupName: childField.key,
                            config: childField,
                            pathConfig: childField.key,
                            controlName: lang,
                            fullPathControlName:
                              field.key + '.' + childField.key + '.' + lang,
                            fType: childField.value.type,
                            isMultiLang: true
                          }
                        "
                      ></ng-container>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-template
                *ngIf="!childField.value.isMultipleLang"
                #childsingleLangField
              >
                <ng-container
                  *ngTemplateOutlet="
                    inputSwitcher;
                    context: {
                      form: formGroup,
                      config: childField,
                      controlName: childField.key,
                      pathConfig: childField.key,
                      fullPathControlName: field.key + '.' + childField.key,
                      fType: childField.value.type,
                      isMultiLang: false
                    }
                  "
                ></ng-container>
              </ng-template>
            </div>
          </div>
        </ng-container>
        <ng-template #isChildField>
          <ng-container
            *ngIf="config[field.key].isMultipleLang; else singleLangField"
          >
            <div formGroupName="{{ field.key }}">
              <div *ngFor="let lang of langList">
                <div [class.d-none]="selectedLang != lang">
                  <ng-container
                    *ngTemplateOutlet="
                      inputSwitcher;
                      context: {
                        form: formGroup,
                        formGroupName: field.key,
                        config: field,
                        controlName: lang,
                        pathConfig: field.key,
                        fullPathControlName: field.key + '.' + lang,
                        fType: field.value.type,
                        isMultiLang: true
                      }
                    "
                  ></ng-container>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #singleLangField>
            <ng-container
              *ngTemplateOutlet="
                inputSwitcher;
                context: {
                  form: formGroup,
                  config: field,
                  controlName: field.key,
                  pathConfig: field.key,
                  fullPathControlName: field.key,
                  fType: field.value.type,
                  isMultiLang: false
                }
              "
            ></ng-container>
          </ng-template>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  #inputSwitcher
  let-form="form"
  let-formGroupName="formGroupName"
  let-config="config"
  let-controlName="controlName"
  let-fullPathControlName="fullPathControlName"
  let-fType="fType"
  let-pathConfig="pathConfig"
  let-isMultiLang="isMultiLang"
>
  <div class="form-group row" [formGroup]="form">
    <ng-container *ngIf="fType == 'divider'">
      <div class="col-sm-12">
        <hr class="auto-form-divider">
      </div>
    </ng-container>

    <ng-container *ngIf="fType != 'divider'">
      <div class="col-sm-3 col-form-label">
        <p class="text-bold" style="margin-bottom: 5px">
          {{
            getFieldLabel(pathConfig) ? getFieldLabel(pathConfig) : controlName
          }}
          <span *ngIf="getIsRequiredField(pathConfig)" class="text-danger"
            >*</span
          >
        </p>
        <p>{{ getFieldDescription(pathConfig) }}</p>
        <div *ngIf="isMultiLang" class="badge badge-primary">
          {{ controlName | uppercase }}
        </div>
      </div>
      <div class="col-sm-9">
        <div style="position: relative">
          <input
            *ngIf="fType == 'text'"
            type="text"
            [placeholder]="
              isDataLoading ? '' : ('autoForm.typeAnyKey' | translate)
            "
            class="form-control-custom"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && form.get(fullPathControlName).invalid) ||
                (form.get(fullPathControlName).invalid &&
                  (form.get(fullPathControlName).dirty ||
                    form.get(fullPathControlName).touched))
            }"
            [formControl]="form.get(fullPathControlName)"
          />
  
          <input
            *ngIf="fType == 'email'"
            type="email"
            class="form-control"
            [formControl]="form.get(fullPathControlName)"
          />
  
          <input
            *ngIf="fType == 'number'"
            type="number"
            class="form-control"
            [formControl]="form.get(fullPathControlName)"
          />
  
          <textarea
            *ngIf="fType == 'textarea'"
            rows="7"
            class="form-control-custom"
            [placeholder]="
              isDataLoading ? '' : ('autoForm.typeAnyKey' | translate)
            "
            [ngClass]="{
              'is-invalid error':
                (isSubmit && form.get(fullPathControlName).invalid) ||
                (form.get(fullPathControlName).invalid &&
                  (form.get(fullPathControlName).dirty ||
                    form.get(fullPathControlName).touched))
            }"
            [formControl]="form.get(fullPathControlName)"
            (click)="showLog($event)"
          ></textarea>
  
          <div
            *ngIf="fType == 'richtext'"
            [ngClass]="{
              invalid_border: isSubmit && form.get(fullPathControlName).invalid
            }"
          >
            <textarea
              class="form-control"
              [ngxSummernote]="summernoteConfig"
              [formControl]="form.get(fullPathControlName)"
            ></textarea>
          </div>
  
          <div *ngIf="fType == 'datepicker'">
            <ng2-flatpickr
              [setDate]="datepickerList[pathConfig].defaultDate"
              [config]="datepickerList[pathConfig]"
              [formControl]="form.get(fullPathControlName)"
            >
            </ng2-flatpickr>
          </div>
  
          <div *ngIf="fType == 'staticValue'"></div>
  
          <div *ngIf="fType == 'singleimage'">
            <app-image-upload
              #imageUploadComponent
              [id]="controlName"
              (inputChanged)="handleInputChanged($event, controlName)"
            ></app-image-upload>
          </div>
  
          <div *ngIf="fType == 'multiplefiles' || fType == 'multipleimage'">
            <app-custom-dropzone
              #customDropzoneComponent
              [id]="controlName"
              [editing]="isEditing"
              [typeFiles]="getTypeFilesFilter(controlName)"
              [countMedia]="getFieldLimit(controlName)"
              [minimumFiles]="getFieldMinimum(controlName)"
              [uniqueName]="controlName"
              [media]="multipleFilesList[controlName]"
              (filesUploaded)="onFilesUploaded($event, controlName)"
              (isNotFileChange)="handleIsNotFileChange($event)"
              (isFileMinimumCheck)="handleIsFileMinimumCheck($event)"
            ></app-custom-dropzone>
            <label [for]="controlName"
              >Choose only .jpg, .jpeg, .png, .doc, .docx, .xlsx, .pdf</label
            >
          </div>
  
          <div class="input-group" *ngIf="fType == 'select'">
            <select
              class="custom-select"
              [formControlName]="controlName"
              [ngClass]="{
                'is-invalid error':
                  (isSubmit && form.get(controlName).invalid) ||
                  (form.get(controlName).invalid &&
                    (form.get(controlName).dirty ||
                      form.get(controlName).touched))
              }"
            >
              <!-- <option value="">{{ getDefaultSelectLabel(pathConfig) }}</option> -->
              <option
                [value]="option[getSelectValueColumn(pathConfig)]"
                [selected]="
                  option[getSelectValueColumn(pathConfig)] ===
                  getFieldValue(pathConfig)
                "
                *ngFor="let option of optionList[controlName]"
              >
                {{ option[getSelectLabelColumn(pathConfig)] }}
              </option>
            </select>
          </div>
  
          <div
            class="demo-inline-spacing col-form-label"
            *ngIf="fType == 'radio'"
          >
            <div
              class="custom-control custom-radio my-auto"
              *ngFor="let radio of radioList[controlName]; let ind = index"
            >
              <input
                type="radio"
                [id]="ind"
                class="custom-control-input"
                [value]="radio.value"
                [formControlName]="controlName"
                [checked]="getFieldValue(pathConfig) === radio.value"
                (click)="setRadioValue(controlName, radio.value)"
              />
              <label class="custom-control-label" [for]="ind">{{
                radio.translate ? (radio.translate | translate) : radio.label
              }}</label>
            </div>
          </div>
  
          <div
            class="custom-control custom-switch custom-control-inline col-form-label"
            *ngIf="fType == 'isActive'"
          >
            <input
              type="checkbox"
              [id]="controlName"
              class="custom-control-input"
              [(ngModel)]="itemObj.isActive"
              [ngModelOptions]="{ standalone: true }"
              [checked]="itemObj.isActive"
            />
            <label class="custom-control-label" [for]="controlName"></label>
          </div>
  
          <div *ngIf="fType == 'video'">
            <div
              class="custom-video"
              [ngClass]="{
                'is-invalid error':
                  isSubmit &&
                  !singleFileList[controlName].file &&
                  getIsRequiredField(controlName)
              }"
            >
              <button
                class="cover-input w-auto"
                (click)="callSelectVideo(controlName)"
              >
                {{ "Files.ChooseFile" | translate }}
              </button>
              <p
                class="text-truncate"
                style="
                  min-width: 200px;
                  margin-top: 50px;
                  padding: 0 16px;
                  margin-right: 16px;
                "
              >
                {{
                  (getVideoName(controlName)
                    ? "Files.ChooseFile"
                    : "Files.NoFileChosen"
                  ) | translate
                }}
              </p>
              <input
                (change)="prepareVideo($event, controlName)"
                accept="video/mp4,video/x-m4v,video/* "
                type="file"
                [id]="controlName"
                hidden
                #videoUploadComponent
                class="cover-input"
              />
              <label class="cover-file-label">
                <div>
                  <i data-feather="play"></i
                  >{{
                    (getVideoName(controlName)
                      ? "Files.ChooseFile"
                      : "Files.NoFileChosen"
                    ) | translate
                  }}
                </div>
                <div
                  class="inloader d-none"
                  [ngClass]="{
                    'd-block': loadingFile
                  }"
                ></div>
              </label>
              <div class="preview-video-cover">
                <div class="pvc-overlay"></div>
  
                <div
                  class="pvc-image"
                  *ngIf="
                    !getPreviewImageUrl(controlName) && mediaData[controlName]
                  "
                >
                  <div
                    class="d-flex align-items-center h-100 mt-50 justify-content-end mr-3"
                  >
                    <div class="spinner-grow" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
                <div class="pvc-image" *ngIf="getPreviewImageUrl(controlName)">
                  <img
                    [src]="getPreviewImageUrl(controlName)"
                    alt="Preview Cover"
                  />
                </div>
              </div>
            </div>
            <label [for]="controlName" class="d-flex justify-content-between"
              ><span>Choose only .mp4</span
              ><span>{{ bytesToSizes(videoFileSize) }}</span></label
            >
          </div>
  
          <div *ngIf="fType == 'singlefile'">
            <div class="custom-file">
              <input
                (change)="prepareSingleFile($event, controlName)"
                accept="image/jpeg, image/png, image/jpeg, application/msword, application/vnd.ms-excel, application/pdf,"
                type="file"
                class="custom-file-input"
                [id]="controlName"
              />
              <label class="custom-file-label" for="customFile">{{
                getVideoName(controlName) ? "1 File Chosen" : "-"
              }}</label>
            </div>
            <label [for]="controlName"
              >Limit {{ bytesToSizes(maxFileSize) }} Choose only .jpg, .jpeg,
              .png, .doc, .docx, .xlsx, .pdf</label
            >
          </div>
  
          <div
            class="custom-control custom-switch custom-control-inline col-form-label"
            *ngIf="fType == 'switch'"
          >
            <input
              type="checkbox"
              [id]="fullPathControlName"
              class="custom-control-input"
              [(ngModel)]="itemObj[controlName]"
              [ngModelOptions]="{ standalone: true }"
              [formControlName]="controlName"
              [checked]="itemObj[controlName]"
            />
            <label class="custom-control-label" [for]="controlName"></label>
          </div>
  
          <div
            *ngIf="
              fType !== 'singleimage' &&
              fType !== 'singlefile' &&
              fType !== 'multipleimage' &&
              fType !== 'multiplefiles' &&
              fType !== 'video' &&
              fType !== 'isActive'
            "
          >
            <ng-container
              *ngTemplateOutlet="
                generalErrorDisplay;
                context: {
                  form: form,
                  fullPathControlName: fullPathControlName,
                  fType: fType,
                  isMultiLang: isMultiLang
                }
              "
            ></ng-container>
          </div>
  
          <div
            *ngIf="fType == 'text' || fType == 'email'"
            style="top: 40%"
            class="spinner-box"
            [hidden]="!isDataLoading"
          >
            <div class="pulse-container">
              <div class="pulse-bubble pulse-bubble-1"></div>
              <div class="pulse-bubble pulse-bubble-2"></div>
              <div class="pulse-bubble pulse-bubble-3"></div>
            </div>
          </div>
  
          <div
            *ngIf="fType == 'textarea'"
            style="top: 20%"
            class="spinner-box"
            [hidden]="!isDataLoading"
          >
            <div class="pulse-container">
              <div class="pulse-bubble pulse-bubble-1"></div>
              <div class="pulse-bubble pulse-bubble-2"></div>
              <div class="pulse-bubble pulse-bubble-3"></div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>


    
  </div>
</ng-template>

<ng-template
  #generalErrorDisplay
  let-form="form"
  let-fullPathControlName="fullPathControlName"
  let-fType="fType"
  let-isMultiLang="isMultiLang"
>
  <div
    class="invalid-feedback"
    [ngClass]="{
      'd-block':
        (isSubmit && form.get(fullPathControlName).invalid) ||
        (form.get(fullPathControlName).invalid &&
          (form.get(fullPathControlName).dirty ||
            form.get(fullPathControlName).touched))
    }"
    *ngIf="
      (isSubmit && form.get(fullPathControlName).invalid) ||
      (form.get(fullPathControlName).invalid &&
        (form.get(fullPathControlName).dirty ||
          form.get(fullPathControlName).touched))
    "
  >
    <div *ngIf="form.get(fullPathControlName).errors?.required">
      Field is required.
    </div>
    <div *ngIf="form.get(fullPathControlName).errors?.email">
      Invalid email format.
    </div>

    <div *ngIf="isMultiLang && !form.get(fullPathControlName)?.valid">
      All language are required.
    </div>
  </div>
</ng-template>
