import { Component, OnInit } from '@angular/core';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import { NewsSwiperService } from './news-swiper.service';

@Component({
  selector: 'app-news-swiper',
  templateUrl: './news-swiper.component.html',
  styleUrls: ['./news-swiper.component.scss']
})
export class NewsSwiperComponent implements OnInit {

  config!: ICMSConfig;
  keyName: string;

  constructor(private _newsSwiperService: NewsSwiperService) { 
    this.config = this._newsSwiperService.config;
    this.keyName = this._newsSwiperService.keyName
  }

  ngOnInit(): void {
  }

}
