import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import Home from 'app/main/model/Home';

@Injectable({
  providedIn: 'root'
})
export class NewsSwiperService {

  config: ICMSConfig = {
    apiPath: 'homenew',
    pathUrl: '/cms/homepage/news-swiper',
    tableName: 'Content',
    model: new Home(),
    isList: true,
    componentName: 'General.NewsSwiper',
    formConfig: {
      newsImg: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'News Image',
        description: 'Resolution 780px*500',
        labelTranslate: 'BreadCrumbs.CMS.HomePage.NewsImage.Label',
        descriptionTranslate: 'BreadCrumbs.CMS.HomePage.NewsImage.Description'
      },
      title: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'News Image',
        labelTranslate: 'BreadCrumbs.CMS.HomePage.NewsTitle.Label',
      },
      detail: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'News Detail',
        labelTranslate: 'BreadCrumbs.CMS.HomePage.NewsDetail.Label',
      },
      linkBtnUrl: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'News Detail',
        labelTranslate: 'BreadCrumbs.CMS.HomePage.NewsLink.Label',
      },
      isActive: {
        type: FormType.IsActive,
        label: 'Active',
        labelTranslate: 'Status.Active'
      }
    },
  };
  
  componentName: string = this.config.componentName;
  apiPath: string = this.config.apiPath;
  pathUrl: string = this.config.pathUrl;
  keyName: string = 'title';

  constructor() { }
}
