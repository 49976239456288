import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { first, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

import { CoreConfigService } from '@core/services/config.service';
import { AuthenticationService } from 'app/auth/service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
  //  Public
  public coreConfig: any;
  public loginForm: UntypedFormGroup;
  public isLoading = false;
  public isSubmitted = false;
  public returnUrl: string;
  public error = '';
  public passwordTextType: boolean;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   */
  constructor(
    private _coreConfigService: CoreConfigService,
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private _authenticationService: AuthenticationService
  ) {
    this._unsubscribeAll = new Subject();

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        menu: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        animation: 'fadeIn',
        customizer: false,
        enableLocalStorage: false,
      },
    };
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }

  SetLoadedState(): void {
    this.isLoading = false;
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  /**
   * Toggle password
   */
  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  onSubmit() {
    var self = this;
    this.isSubmitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    // Login
    this.SetLoadingState();

    // redirect to home page
    this._authenticationService
      .login(this.f.userName.value, this.f.password.value)
      .subscribe(
        (res) => {
          if (res.success) {
            let token = this._authenticationService.tokenValue;
            if (
              token.accessToken != null &&
              token.refreshToken != null &&
              res.data.isVerify &&
              res.data.success
            ) {
              this._authenticationService
                .getAccountInfo(token)
                .subscribe((res) => {
                  this._router.navigate(['/']).then(() => { });
                });
            } else {
              if (res.data.success && !res.data.isVerify) {
                this._router.navigate(['/verify']).then(() => {
                  this.isLoading = false;
                });
              } else {
                this.error = res.data.message;
                this.isLoading = false;
              }
            }
            // this._router.navigate([this.returnUrl]);
          } else {
            this.error = res.data.message;
            this.isLoading = false;
          }
        },
        (error) => {
          this.error = error;
          console.log(error);
          this.isLoading = false;
        }
      ); 
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.loginForm = this._formBuilder.group({
      userName: ['', [Validators.required]],
      password: ['', Validators.required],
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';

    // Subscribe to config changes
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
