import { Component, OnInit } from '@angular/core';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import Home from 'app/main/model/Home';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-pdpa',
  templateUrl: './pdpa.component.html',
  styleUrls: ['./pdpa.component.scss']
})
export class PdpaComponent implements OnInit {

  config:ICMSConfig = {
    apiPath: 'privacypolicy',
    pathUrl: '/cms/pdpa',
    tableName: 'Content',
    model: new Home(),
    isList: false,
    componentName: 'BreadCrumbs.CMS.PDPA',
    formConfig: {
      pdpaDescription: {
        type: FormType.RichText,
        validator: [Validators.required],
        label: 'Privacy Policy',
        labelTranslate: 'BreadCrumbs.CMS.Pdpa.privacyPolicy',
      },
    },
  }


  constructor() { }

  ngOnInit(): void {
  }

}
