import { CoreMenu } from '@core/types';
import { environment } from 'environments/environment';

const isJoinIV_TX_RE = environment.transaction.isJoinIV_TX_RE;
const isShowMenuList = environment.menuList

export const menu: CoreMenu[] = [
  {
    id: 'general-section',
    type: 'section',
    title: 'General.General',
    icon: 'bar-chart-2',
    children: [
      {
        id: 'home',
        title: 'Home',
        translate: 'General.Home',
        type: 'item',
        icon: 'home',
        url: 'home',
      },
      {
        id: 'settings',
        title: 'Settings',
        translate: 'General.Settings',
        type: 'item',
        icon: 'settings',
        url: 'settings',
      },
    ],
  },

  {
    id: 'content-section',
    type: 'section',
    title: 'CMS',
    icon: 'bar-chart-2',
    hidden: !isShowMenuList.cms,
    role: ['SuperAdmin'],
    children: [
      {
        id: 'content-section',
        title: 'Manage Content',
        translate: 'General.ContentManage',
        hidden: !isShowMenuList.cms,
        icon: 'airplay',
        type: 'collapsible',
        children: [
          {

            id: 'home-management',
            title: 'Home',
            translate: 'General.Home',
            hidden: !isShowMenuList.cms,
            icon: 'home',
            type: 'collapsible',
            children: [
              {
                id: 'home-detail',
                title: 'Home Detail',
                translate: 'BreadCrumbs.CMS.HomePage.DetailMenu',
                hidden: !isShowMenuList.cms,
                type: 'item',
                url: 'cms/homepage/detail',
              },
              // {
              //   id: 'home-banner-swiper',
              //   title: 'Banner Swiper',
              //   translate: 'BreadCrumbs.CMS.HomePage.BannerMenu',
              //   hidden: !isShowMenuList.cms,
              //   type: 'item',
              //   url: 'cms/homepage/banner-swiper',
              // },
              {
                id: 'home-news-swiper',
                title: 'News Swiper',
                translate: 'BreadCrumbs.CMS.HomePage.NewsMenu',
                hidden: !isShowMenuList.cms,
                type: 'item',
                url: 'cms/homepage/news-swiper',
              },
            ]


          },
          {
            id: 'product-section',
            title: 'Product',
            translate: 'General.Product',
            hidden: !isShowMenuList.cms,
            icon: 'box',
            type: 'collapsible',
            children: [
              {
                id: 'HeadtoToeGentleBath',
                title: 'Gentle Bath',
                translate: 'CmsSection.Product.HeadtoToeGentleBath',
                hidden: !isShowMenuList.cms,
                type: 'collapsible',
                children: [
                  {
                    id: 'gentle-bath-detail',
                    title: 'Detail',
                    translate: 'General.Detail',
                    hidden: !isShowMenuList.cms,
                    type: 'item',
                    url: 'cms/product/gentle-bath/detail',
                  },
                  {
                    id: 'gentle-bath-ingredients',
                    title: 'Ingredients And Extracts',
                    translate: 'General.IngredientsAndExtracts',
                    hidden: !isShowMenuList.cms,
                    type: 'item',
                    url: 'cms/product/gentle-bath/ingredients',
                  },
                  // {
                  //   id: 'gentle-bath-compound',
                  //   title: 'Compound',
                  //   translate: 'General.compound',
                  //   hidden: !isShowMenuList.cms,
                  //   type: 'item',
                  //   url: 'cms/product/gentle-bath/compound',
                  // },
                ]
              },
              {
                id: 'GentlyNourishingLotion',
                title: 'Lotion',
                translate: 'CmsSection.Product.GentlyNourishingLotion',
                hidden: !isShowMenuList.cms,
                type: 'collapsible',
                children: [
                  {
                    id: 'lotion-detail',
                    title: 'Detail',
                    translate: 'General.Detail',
                    hidden: !isShowMenuList.cms,
                    type: 'item',
                    url: 'cms/product/lotion/detail',
                  },
                  {
                    id: 'lotion-ingredients',
                    title: 'Ingredients And Extract',
                    translate: 'General.IngredientsAndExtracts',
                    hidden: !isShowMenuList.cms,
                    type: 'item',
                    url: 'cms/product/lotion/ingredients',
                  },
                  // {
                  //   id: 'lotion-compound',
                  //   title: 'Compound',
                  //   translate: 'General.compound',
                  //   hidden: !isShowMenuList.cms,
                  //   type: 'item',
                  //   url: 'cms/product/lotion/compound',
                  // },
                ]
              },
            ]
          },
          {
            id: 'contact-section',
            title: 'Manage Contact',
            translate: 'BreadCrumbs.CMS.ContactPage.ContactMenu',
            hidden: !isShowMenuList.cms,
            icon: 'phone',
            type: 'item',
            url: 'cms/contact'
          },
          {
            id: 'pdpa-section',
            title: 'Manage pdpa',
            translate: 'BreadCrumbs.CMS.Pdpa.PdpaMenu',
            hidden: !isShowMenuList.cms,
            icon: 'file-text',
            type: 'item',
            url: 'cms/pdpa'
          },
        ],
      },

    ],
  },

];
