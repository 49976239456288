import { Component, OnInit, OnDestroy } from '@angular/core';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import { NewsSwiperService } from '../news-swiper.service';

@Component({
  selector: 'app-news-swiper-formpage',
  templateUrl: './news-swiper-formpage.component.html',
  styleUrls: ['./news-swiper-formpage.component.scss']
})
export class NewsSwiperFormpageComponent implements OnInit {

  config: ICMSConfig

  constructor(private _NewsSwiper: NewsSwiperService) { 
    this.config = this._NewsSwiper.config;
  }

  ngOnInit(): void {
  }

}
