import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers';
import { PdpaComponent } from './pdpa.component';
import { CmsFormpageModule } from '../cms-formpage/cms-formpage.module';
import { CmsListModule } from '../cms-list/cms-list.module';



const path: string = 'cms/pdpa';

const routes: Routes = [
  {
    path: path,
    component: PdpaComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-pdpa' },
  },
];

@NgModule({
  declarations: [
    PdpaComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CmsFormpageModule,
    CmsListModule
  ]
})
export class PdpaModule { }
