import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ProductComponent } from './product.component';
import { AuthGuard } from 'app/auth/helpers';
import { CmsListModule } from '../cms-list/cms-list.module';
import { CmsFormpageModule } from '../cms-formpage/cms-formpage.module';


import { ProductItem1Component } from './product-item1/product-item1.component';
import { ProductIngredients1Component } from './product-ingredients1/product-ingredients1.component';
import { ProductIngredients1FormpageComponent } from './product-ingredients1/product-ingredients1-formpage/product-ingredients1-formpage.component';
import { Compound1Component } from './compound1/compound1.component';

import { ProductItem2Component } from './product-item2/product-item2.component';
import { ProductIngredients2Component } from './product-ingredients2/product-ingredients2.component';
import { ProductIngredients2FormpageComponent } from './product-ingredients2/product-ingredients2-formpage/product-ingredients2-formpage.component';
import { Compound2Component } from './compound2/compound2.component';

import { Compound1FormpageComponent } from './compound1/compound1-formpage/compound1-formpage.component';
import { Compound2FormpageComponent } from './compound2/compound2-formpage/compound2-formpage.component';

// import { ProductIngredients1Module } from './product-ingredients1/product-ingredients1.module';
// import { ProductIngredients2Module } from './product-ingredients2/product-ingredients2.module';

const path: string = 'cms/product';
const pathItem1: string = `${path}/gentle-bath`;
const pathItem2: string = `${path}/lotion`;

const routes: Routes = [
    {
        path: path,
        component: ProductComponent,
        canActivate: [AuthGuard],
        data: { animation: 'cms-product' },
    },
    // product-item 1 ========================================
    {
        path: `${pathItem1}/detail`,
        component: ProductItem1Component,
        canActivate: [AuthGuard],
        data: { animation: 'product-item1' },
    },
    {
        path: `${pathItem1}/ingredients`,
        component: ProductIngredients1Component,
        canActivate: [AuthGuard],
        data: { animation: 'product-ingredients1-manage' },
    },
    {
        path: `${pathItem1}/ingredients/create`,
        component: ProductIngredients1FormpageComponent,
        canActivate: [AuthGuard],
        data: { animation: 'product-ingredients1-create' },
    },
    {
        path: `${pathItem1}/ingredients/edit/:id`,
        component: ProductIngredients1FormpageComponent,
        canActivate: [AuthGuard],
        data: { animation: 'product-ingredients1-edit' },
    },
    {
        path: `${pathItem1}/compound`,
        component: Compound1Component,
        canActivate: [AuthGuard],
        data: { animation: 'product-item1-compound' },
    },
    {
        path: `${pathItem1}/compound/create`,
        component: Compound1FormpageComponent,
        canActivate: [AuthGuard],
        data: { animation: 'product-item1-compound-create' },
    },
    {
        path: `${pathItem1}/compound/edit/:id`,
        component: Compound1FormpageComponent,
        canActivate: [AuthGuard],
        data: { animation: 'product-item1-compound-edit' },
    },


    // product-item 2 ========================================
    {
        path: `${pathItem2}/detail`,
        component: ProductItem2Component,
        canActivate: [AuthGuard],
        data: { animation: 'product-item2' },
    },
    {
        path: `${pathItem2}/ingredients`,
        component: ProductIngredients2Component,
        canActivate: [AuthGuard],
        data: { animation: 'product-ingredients1-manage' },
    },
    {
        path: `${pathItem2}/ingredients/create`,
        component: ProductIngredients2FormpageComponent,
        canActivate: [AuthGuard],
        data: { animation: 'product-ingredients1-create' },
    },
    {
        path: `${pathItem2}/ingredients/edit/:id`,
        component: ProductIngredients2FormpageComponent,
        canActivate: [AuthGuard],
        data: { animation: 'product-ingredients1-edit' },
    },
    {
        path: `${pathItem2}/compound`,
        component: Compound2Component,
        canActivate: [AuthGuard],
        data: { animation: 'product-item2-compound' },
    },
    {
        path: `${pathItem2}/compound/create`,
        component: Compound2FormpageComponent,
        canActivate: [AuthGuard],
        data: { animation: 'product-item2-compound-create' },
    },
    {
        path: `${pathItem2}/compound/edit/:id`,
        component: Compound2FormpageComponent,
        canActivate: [AuthGuard],
        data: { animation: 'product-item2-compound-edit' },
    },

];

@NgModule({
    declarations: [
        ProductComponent,
        ProductItem1Component,
        ProductItem2Component,
        ProductIngredients1Component,
        ProductIngredients1FormpageComponent,
        ProductIngredients2Component,
        ProductIngredients2FormpageComponent,
        Compound1Component,
        Compound2Component,
        Compound1FormpageComponent,
        Compound2FormpageComponent,
    ],
    imports: [
        CommonModule,
        CmsListModule,
        CmsFormpageModule,
        RouterModule.forChild(routes),
    ],
})

export class ProductModule { }