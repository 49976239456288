import { Component, OnInit } from '@angular/core';
import { FormType, ICMSConfig } from 'app/main/config/CMSInterface';
import Home from 'app/main/model/Home';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  config: ICMSConfig = {
    apiPath: 'contact',
    pathUrl: '/cms/contact',
    tableName: 'Content',
    model: new Home(),
    isList: false,
    componentName: 'BreadCrumbs.CMS.Contact',
    formConfig: {
      phoneContact: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'Phone Number',
        labelTranslate: 'BreadCrumbs.CMS.ContactPage.PhoneNumber.Label',
      },
      emailContact: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'Email',
        labelTranslate: 'BreadCrumbs.CMS.ContactPage.Email.Label',
      },
      lineContact: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'Line',
        labelTranslate: 'BreadCrumbs.CMS.ContactPage.Line.Label',
      },
      imageForm: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'Image Form',
        description: 'Image Resolution 760 x 526',
        labelTranslate: 'BreadCrumbs.CMS.ContactPage.ImageForm.Label',
        descriptionTranslate: 'BreadCrumbs.CMS.ContactPage.ImageForm.Description',
      },
      dividerSection1: {
        type: FormType.Divider
      },
      contactInfoDescription: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'Line',
        labelTranslate: 'BreadCrumbs.CMS.ContactPage.ContactInfoDescription.Label',
      },
      facebookLink: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'Contact Info Description',
        labelTranslate: 'BreadCrumbs.CMS.ContactPage.facebookLink.Label',
      },
      lineLink: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'Facebook Link (URL)',
        labelTranslate: 'BreadCrumbs.CMS.ContactPage.lineLink.Label',
      },
      shopeeLink: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'Shopee Link (URL)',
        labelTranslate: 'BreadCrumbs.CMS.ContactPage.shopeeLink.Label',
      },
      lazadaLink: {
        type: FormType.Text,
        validator: [Validators.required],
        label: 'Lasada Link (URL)',
        labelTranslate: 'BreadCrumbs.CMS.ContactPage.lazadaLink.Label',
      },
      qrcode: {
        type: FormType.SingleImage,
        validator: [Validators.required],
        label: 'QR Code Image',
        description: 'Resolution 193px*193px',
        labelTranslate: 'BreadCrumbs.CMS.ContactPage.qrcode.Label',
        descriptionTranslate: 'BreadCrumbs.CMS.ContactPage.qrcode.Description',
      },

    },
  }

  constructor() { }

  ngOnInit(): void {
  }

}
