import { Component, OnInit , OnDestroy } from '@angular/core';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import { Compound1Service } from '../compound1.service';

@Component({
  selector: 'app-compound1-formpage',
  templateUrl: './compound1-formpage.component.html',
  styleUrls: ['./compound1-formpage.component.scss']
})
export class Compound1FormpageComponent implements OnInit {

  config: ICMSConfig

  constructor(private _Compound1Service: Compound1Service) {
    this.config = this._Compound1Service.config;
   }

  ngOnInit(): void {
  }

}
